import React, { useState, useEffect } from 'react'
import './Home.css'
import Footer from '../UIElements/Footer'
import HomeHomeNavigation from './HomeHomeNavigation'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import ErrorModal from '../UIElements/ErrorModal'
import brookline from '../images/brookline.png'
import falcons from '../images/falcons.png'
import venom from '../images/venom.png'
import pennHills from '../images/pennHills.jpg'
import riverview from '../images/riverview.jpg'
import southHills from '../images/southHills.png'
import cranberry from '../images/cranberryNew.png'
import murrysville from '../images/MurrysvilleSportzone.png'
import mckeesport from '../images/McKeesport.png'
import teampitt from '../images/teamPittNew.png'
import tournamentSchedule from '../images/2025_schedule.jpg'
import emailjs from 'emailjs-com'
//import YouTube from 'react-youtube'
//var getYouTubeID = require('get-youtube-id')

const Home = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [ids, setIds] = useState([])
	const [videoTitles, setVideoTitles] = useState([])
	const [videoCaptions, setVideoCaptions] = useState([])
	const [loadedVideos, setLoadedVideos] = useState([])
	const [loadedNews, setLoadedNews] = useState([])

	//
	//
	//
	//
	//
	//
	//
	//Get all News
	useEffect(() => {
		const sendRequest2 = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/news'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				console.log(responseData.allNews)

				setLoadedNews(responseData.allNews)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}

		sendRequest2()
	}, [])
	//
	//
	//
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_qeuvtpo',
				'template_62za9ey',
				document.getElementById('emailForm'),
				'GFXJpgnQXBXpF8Y3w'
			)
			.then((res) => {
				console.log('res: ' + JSON.stringify(res))
				alert('Your email is on its way!')
				document.getElementById('emailForm').reset()
			})
			.catch((err) => console.log(err))
	}
	//
	//
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && <LoadingSpinner asOverlay />}

				{!isLoading && (
					<div>
						<div>
							<header className='homeHeader'>
								<HomeHomeNavigation />
								<div className='text-light text-md-right text-center banner headingWriting'>
									<h1 className='display-2 text-white headingText'>
										Pennsylvania Ball Hockey League
									</h1>
									{/* <div className='underline-white mb-4'></div> */}
									<h1
										className='display-5 lead text-white subheadingText'
										style={{ marginBottom: '1rem' }}
									>
										Growing the Game in the Keystone State.
									</h1>
								</div>
							</header>
						</div>
						<div className='p-5' style={{ backgroundColor: 'white' }}>
							{/* <div className='row buttonRow' style={{ marginTop: '2rem' }}>
								<button
									className='linkToCommitmentButton'
									onClick={(e) => showAddNoteModal(e)}
									exact
								>
									PBHL 2025 Player Commitment Form
								</button>
							</div> */}
							<div className='row'>
								<a
									href='https://docs.google.com/forms/d/e/1FAIpQLSdX_pRplBXhjEsibScxOjhfCTL2M1-wLZNm9Z8ZrDYj9F-8KA/viewform'
									className='centeredButtonSmallMargin commitmentFormButton'
									target='_blank'
									style={{
										display: 'block',
										width: 'auto',
										fontWeight: '700',
										backgroundColor: 'red',
										color: 'white',
										borderRadius: '4px',
										textDecoration: 'none',
										boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
										/* border: 'solid',
										borderColor: 'black', */
									}}
								>
									PBHL 2025 Player Commitment Form
								</a>
							</div>
							<div className='row'>
								<div className='col text-center mb-3'>
									<h1 className='displayProperty' style={{ color: '#090085' }}>
										About Us
									</h1>
									<blockquote className='blockquote'>
										<p className='lead text-secondary missionQuote'>
											The mission of the Pennsylvania Ball Hockey League (PBHL)
											is to grow and foster the game of Ball Hockey throughout
											the state of Pennsylvania. The propmotion of fair play,
											integrity, and positive reinforcement will better allow
											young athletes and their orginizations to positively
											impact not only their own interests, but rather that of
											the entire sport. We shall always be guided by and strive
											to live to the motto:{' '}
										</p>
										<strong>
											<italic>
												"We will always do what's best for our athletes and the
												growth of the game, and not that of our private or
												professional interests."
											</italic>
										</strong>
									</blockquote>
									<div className='underline-blue mb-4'></div>
								</div>

								<h2 style={{ color: 'red' }}>Executive Board</h2>
								<div className='col text-center mt-1 mb-3'>
									<h4 className='lead text-secondary'>
										President - Justin Studeny
									</h4>
									<h4 className='lead text-secondary'>
										Vice President - Melissa Russo
									</h4>
									<h4 className='lead text-secondary'>
										Treasurer - Paul Buckwalter
									</h4>
									<h4 className='lead text-secondary'>
										Secretary - Shannon Porter
									</h4>
									<h4 className='lead text-secondary'>
										Head of Officials - Ryan Shannon
									</h4>
								</div>
								{/* <div className='underline-red mb-4'></div> */}
							</div>
						</div>
						<div className='p-5' style={{ backgroundColor: '#e0e0e0' }}>
							<div className='row'>
								<div className='col text-center mb-3'>
									<h1 className='displayProperty' style={{ color: '#090085' }}>
										2025 Tournament Schedule
									</h1>
									<div className='row'>
										<img
											src={tournamentSchedule}
											className='img-thumbnail tournamentSchedule'
										/>
									</div>
									<p className='lead text-secondary'></p>
								</div>
							</div>
						</div>

						<div className='p-5' style={{ backgroundColor: 'white' }}>
							<div className='row'>
								<div className='col text-center mb-3'>
									<h1 className='displayProperty' style={{ color: '#090085' }}>
										Members of PBHL
									</h1>
									<p className='lead text-secondary'>
										Find a PBHL organization near you!
									</p>
								</div>

								<div className='row iconRow'>
									{/* <div className='col-lg-3 col-sm-6'> */}
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a
											// href='https://www.leaguelineup.com/welcome.asp?url=byhl'
											href='https://byhl.net/welcome'
											target='_blank'
										>
											<img
												src={brookline}
												className='img-thumbnail brookline'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Brookline Youth Hockey League
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a
											href='https://www.billsgolfland.com/dek.html'
											target='_blank'
										>
											<img src={falcons} className='img-thumbnail bills' />
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Bill's Dek Hockey
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://venomballhockey.com/' target='_blank'>
											<img src={venom} className='img-thumbnail venom' />
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Venom Ball Hockey
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a
											href='https://greaterpittdekhockey.web.app/'
											target='_blank'
										>
											<img
												src={pennHills}
												className='img-thumbnail pennHills'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Penn Hills Dek Hockey
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
								</div>
								{/* //
							//
							//
							//
							// */}
								<div className='row iconRow'>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a
											href='https://www.riverviewdekhockey.com/'
											target='_blank'
										>
											<img
												src={riverview}
												className='img-thumbnail riverview'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Riverview Raptors
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://southpghballhockey.org/' target='_blank'>
											<img
												src={southHills}
												className='img-thumbnail southHills'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											South Pittsburgh Ball Hockey
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://www.eaglesdekhockey.com/' target='_blank'>
											<img
												src={cranberry}
												className='img-thumbnail cranberry'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Cranberry Screaming Eagles
										</h3>
										{/* <p className='text-muted'>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Eum, nobis! Lorem ipsum dolor sit amet consectetur
										adipisicing elit. Eum, nobis!
									</p> */}
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://murrysvillesportzone.com/' target='_blank'>
											<img
												src={murrysville}
												className='img-thumbnail murrysville'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Murrysville Sportzone
										</h3>
									</div>
								</div>
								{/* //
							//
							//
							//
							//
							//
							// */}
								<div className='row bottomRow'>
									{/* <div className='col-lg-3 col-sm-6 empty'></div> */}
									{/* //
								//
								// */}
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://mckdekhockey.com/' target='_blank'>
											<img
												src={mckeesport}
												className='img-thumbnail mckeesport'
											/>
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											McKeesport Dek Hockey
										</h3>
									</div>
									<div
										className='col-lg-3 col-sm-6'
										style={{ marginBottom: '3rem' }}
									>
										<a href='https://www.teampgh.com/' target='_blank'>
											<img src={teampitt} className='img-thumbnail teampitt' />
										</a>
										<h3
											className='my-3'
											style={{ textAlign: 'center', color: '#090085' }}
										>
											Team Pittsburgh Ball Hockey
										</h3>
									</div>
									{/* //
								//
								// */}
								</div>
							</div>
						</div>
					</div>
				)}
				<div className='p-5' style={{ backgroundColor: '#e0e0e0' }}>
					<div>
						<div className='row'>
							<div className='col text-center mb-3'>
								<h1 className='display-2' style={{ color: '#090085' }}>
									Contact Us
								</h1>
							</div>
						</div>
						<div className='row justify-content-center'>
							<div className='col-lg-6 col-md-8 col-sm-10'>
								<div className='text-center text-secondary'>
									<h2>Got a Question?</h2>
									<p>Stay Connected</p>
								</div>
								<form
									id='emailForm'
									className='text-muted'
									method='post'
									enctype='text/plain'
								>
									<div className='form-group'>
										<label for='name'>Name</label>
										<input
											type='text'
											className='form-control'
											id='fromName'
											name='fromName'
											required
										/>
									</div>
									<div className='form-group'>
										<label for='email'>Email</label>
										<input
											type='email'
											className='form-control'
											id='fromEmail'
											name='fromEmail'
											required
										/>
									</div>
									<div className='form-group'>
										<label for='message'>Message</label>
										<textarea
											name='message'
											className='form-control'
											id='message'
											rows='3'
											required
										></textarea>
									</div>
									<button
										className='btn btn-block'
										type='button submitEmailButton'
										onClick={sendEmail}
										style={{
											width: '100%',
											color: '#090085',
											borderColor: '#090085',
										}}
									>
										Submit Question
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				{/* <div style={{ backgroundColor: '#090085' }}>
				<footer>
					<div>
						<div className='row'>
							<div className='col text-center'>
								<h1
									className='font-weight-light text-capitalize p-3'
									style={{ color: 'white' }}
								>
									Pennsylvania Ball Hockey League
								</h1>
								<h4
									className='font-weight-bold font-italic'
									style={{ color: 'white' }}
								>
									Grow the Game.
								</h4>
								<div className='py-3'>
									<a
										href='https://www.facebook.com/profile.php?id=100077148484696&paipv=0&eav=AfYdN8KAI6ZSGWFMKzXWhNaPIZhtHTLCDNXc3PSHLh0-2rXwzG-wM8vZv27QzUP_IW0'
										target='_blank'
										style={{ color: 'white' }}
									>
										<i className='fab fa-facebook fa-3x mx-3'></i>
									</a>
								</div>
								<p
									className='py-4 m-0'
									style={{ color: 'white', backgroundColor: '#090085' }}
								>
									&copy;Copyright 2023 - Shirey Stats and Standings, LLC
								</p>
							</div>
						</div>
					</div>
				</footer>
			</div> */}
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default Home
