//This will be the dropdown item you see on the schedule page.  You can choose from
//either a league or a team to view a desired schedule
import React, { useState, useEffect } from 'react'
import './AdminSearchByVenue.css'
import { useNavigate } from 'react-router-dom'

const AdminSearchByVenue = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [loadedVenues, setLoadedVenues] = useState()
	const [error, setError] = useState()

	let selectedVenue
	//
	//
	//
	//Call backend to get all the current VENUES for our dropdown.
	//admin-controller.  getVenues
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/venues'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error('error message: ' + responseData.message)
				}

				//console.log('responseData: ' + JSON.stringify(responseData))

				setLoadedVenues(responseData.allVenues)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedVenues])

	let venueArray

	if (loadedVenues && !isLoading) {
		venueArray = []
		loadedVenues.forEach((venue) => {
			venueArray.push(venue.venueName)
		})
	}

	let navigate = useNavigate()

	//For handling a search by VENUE request
	const handleChange = (e) => {
		e.preventDefault()
		const selectedValue = e.target.value
		selectedVenue = loadedVenues[selectedValue]
		const venueId = selectedVenue._id
		navigate(`/admin/${venueId}/schedule`) //  < - - - goes to AdminVenueSchedule
	}

	return (
		<React.Fragment>
			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm instructions searchByVenue'>
					Search by Venue:{' '}
				</div>
				{!isLoading && loadedVenues && (
					<div className='col-sm'>
						<form className='form-control-lg'>
							{/* matt:  wtf is custom-select?   was a className here but i deleted*/}
							<select
								className='my-1 mr-sm-2 scheduleselector'
								onChange={handleChange}
							>
								<option defaultValue>Select Venue....</option>
								{venueArray.map((venue, i) => (
									<option value={i} key={i}>
										{venue}
									</option>
								))}
							</select>
						</form>
					</div>
				)}
				<div className='col-sm'></div>
			</div>
			{/* </div> */}
		</React.Fragment>
	)
}

export default AdminSearchByVenue
