//This is for listing all players in the entire system

import React, { useEffect, useState } from 'react'
import './AdminLeagueList.css'
import AdminNavigation from './AdminNavigation'
import { NavLink } from 'react-router-dom'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'

const AdminCoachCodeOfConductList = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedAllCOCCoaches, setLoadedAllCOCCoaches] = useState()

	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/getAllCOCCoaches'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedAllCOCCoaches(responseData.allCOCCoaches)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])

	const errorHandler = () => {
		setError(null)
	}

	//console.log(loadedAllCOCCoaches)

	//Once we have all loaded players, let's sort them by lastName/firstName
	if (loadedAllCOCCoaches) {
		loadedAllCOCCoaches.sort(function (a, b) {
			return (
				a.lastName.localeCompare(b.lastName) ||
				a.firstName.localeCompare(b.firstName)
			)
		})
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedAllCOCCoaches && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>Coaches Code of Conduct</h1>
					<h3>
						The following coaches have completed the Code of Conduct
						confirmation:
					</h3>
					<table id='leagues'>
						<thead>
							<tr>
								<th>Last Name</th>
								<th>First Name</th>
								<th>Team</th>
								<th>Season</th>
								<th>Date of Completion</th>
							</tr>
						</thead>
						<tbody>
							{loadedAllCOCCoaches.map((coccoach, key) => {
								//const array = player.split(' ')
								let lastName = coccoach.lastName
								let firstName = coccoach.firstName
								let team = coccoach.team
								let season = coccoach.season
								let date = coccoach.date
								return (
									<tr key={key}>
										<td>{lastName}</td>
										<td>{firstName}</td>
										<td>{team}</td>
										<td>{season}</td>
										<td>{date}</td>
										{/* <td>
											<NavLink
												className='navlinks'
												to={`/admin/updatePlayer/${player._id}`}
												exact
											>
												Edit
											</NavLink>
										</td> */}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminCoachCodeOfConductList
