//Player history
//This will go through all the leagues/teams that a player used to play for
//and show all their stats.
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../shared/components/UIElements/Footer'
import './Players.css'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import sloths from '../../shared/components/images/Sloths_Name.png'

const PlayerHistory = () => {
	//const { sendRequest, clearError } = useHttpClient()
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [loadedRosters, setLoadedRosters] = useState()
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [middleInitial, setMiddleInitial] = useState()
	const [photo, setPhoto] = useState()
	const playerId = useParams().playerId
	//const [photo, setPhoto] = useState({
	//	photo: '',
	//})

	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/player/${playerId}/history`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedRosters(responseData.listOfRosters)
				setFirstName(responseData.firstName)
				setLastName(responseData.lastName)
				setMiddleInitial(responseData.middleInitial)
				setPhoto(responseData.photo)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [playerId, firstName, lastName, middleInitial])

	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	//
	//

	return (
		<React.Fragment>
			<StatsAndStandingsHome />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center' style={{ marginTop: '7rem' }}>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedRosters && (
				<div className='Roster' style={{ marginTop: '7rem' }}>
					<div>
						<img src={sloths} className='sloths' />
					</div>
					{photo && (
						<div className='photoDiv'>
							<img
								src={`${process.env.REACT_APP_AWS_BUCKET}/${photo}`}
								//style={{ width: '9%' }}
								className='playerPhoto'
							/>
						</div>
					)}
					<h1>
						{firstName} {middleInitial} {lastName}
					</h1>
					<h3 className='h3Center'>Player History</h3>
					<table id='roster'>
						<thead>
							<tr>
								<th>Team</th>
								<th>Year</th>
								<th>#</th>
								<th>G</th>
								<th>A</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{loadedRosters.map((team, key) => {
								{
									return (
										<tr key={key}>
											<td>{team.teamName}</td>
											<td>{team.year}</td>
											<td>{team.number}</td>
											<td>{team.goals}</td>
											<td>{team.assists}</td>
											<td>{team.goals + team.assists}</td>
										</tr>
									)
								}
							})}
						</tbody>
					</table>
				</div>
			)}
			<div style={{ marginTop: '19rem' }}>
				<Footer />
			</div>
		</React.Fragment>
	)
}

export default PlayerHistory
