//non admin
//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { Link, useParams } from 'react-router-dom'
import Button from '../components/UIElements/Button'
import ErrorModal from '../components/UIElements/ErrorModal'
import Modal from '../components/UIElements/Modal'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'

const TeamRegistrationUpdate = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showSuccessfulRegistrationModal, setShowSuccessfulRegistrationModal] =
		useState(false)
	const [originalHeadCoach, setOriginalHeadCoach] = useState()
	const [headCoach, setHeadCoach] = useState()
	const [originalAssistantCoaches, setOriginalAssistantCoaches] = useState()
	const [assistantCoaches, setAssistantCoaches] = useState()
	const [originalTeam, setOriginalTeam] = useState()
	const [team, setTeam] = useState()
	const [originalYear, setOriginalYear] = useState()
	const [year, setYear] = useState()
	const [originalDate, setOriginalDate] = useState()
	const [date, setDate] = useState()
	const [originalOrg, setOriginalOrg] = useState()
	const [org, setOrg] = useState()
	const [loadedOrgs, setLoadedOrgs] = useState()
	const [orgDropdownCreated, setOrgDropdownCreated] = useState(false)
	const [orgArray, setOrgArray] = useState([])
	//
	//
	const [name1, setName1] = useState()
	const [number1, setNumber1] = useState()
	const [BY1, setBY1] = useState()
	const [playerIsGoalie1, setPlayerIsGoalie1] = useState()
	const [playerIsTaxi1, setPlayerIsTaxi1] = useState()
	const [originalName1, setOriginalName1] = useState()
	const [originalNumber1, setOriginalNumber1] = useState()
	const [originalBY1, setOriginalBY1] = useState()
	//
	const [name2, setName2] = useState()
	const [number2, setNumber2] = useState()
	const [BY2, setBY2] = useState()
	const [playerIsGoalie2, setPlayerIsGoalie2] = useState()
	const [playerIsTaxi2, setPlayerIsTaxi2] = useState()
	const [originalName2, setOriginalName2] = useState()
	const [originalNumber2, setOriginalNumber2] = useState()
	const [originalBY2, setOriginalBY2] = useState()
	//
	const [name3, setName3] = useState()
	const [number3, setNumber3] = useState()
	const [BY3, setBY3] = useState()
	const [playerIsGoalie3, setPlayerIsGoalie3] = useState()
	const [playerIsTaxi3, setPlayerIsTaxi3] = useState()
	const [originalName3, setOriginalName3] = useState()
	const [originalNumber3, setOriginalNumber3] = useState()
	const [originalBY3, setOriginalBY3] = useState()
	//
	const [name4, setName4] = useState()
	const [number4, setNumber4] = useState()
	const [BY4, setBY4] = useState()
	const [playerIsGoalie4, setPlayerIsGoalie4] = useState()
	const [playerIsTaxi4, setPlayerIsTaxi4] = useState()
	const [originalName4, setOriginalName4] = useState()
	const [originalNumber4, setOriginalNumber4] = useState()
	const [originalBY4, setOriginalBY4] = useState()
	//
	const [name5, setName5] = useState()
	const [number5, setNumber5] = useState()
	const [BY5, setBY5] = useState()
	const [playerIsGoalie5, setPlayerIsGoalie5] = useState()
	const [playerIsTaxi5, setPlayerIsTaxi5] = useState()
	const [originalName5, setOriginalName5] = useState()
	const [originalNumber5, setOriginalNumber5] = useState()
	const [originalBY5, setOriginalBY5] = useState()
	//
	const [name6, setName6] = useState()
	const [number6, setNumber6] = useState()
	const [BY6, setBY6] = useState()
	const [playerIsGoalie6, setPlayerIsGoalie6] = useState()
	const [playerIsTaxi6, setPlayerIsTaxi6] = useState()
	const [originalName6, setOriginalName6] = useState()
	const [originalNumber6, setOriginalNumber6] = useState()
	const [originalBY6, setOriginalBY6] = useState()
	//
	const [name7, setName7] = useState()
	const [number7, setNumber7] = useState()
	const [BY7, setBY7] = useState()
	const [playerIsGoalie7, setPlayerIsGoalie7] = useState()
	const [playerIsTaxi7, setPlayerIsTaxi7] = useState()
	const [originalName7, setOriginalName7] = useState()
	const [originalNumber7, setOriginalNumber7] = useState()
	const [originalBY7, setOriginalBY7] = useState()
	//
	const [name8, setName8] = useState()
	const [number8, setNumber8] = useState()
	const [BY8, setBY8] = useState()
	const [playerIsGoalie8, setPlayerIsGoalie8] = useState()
	const [playerIsTaxi8, setPlayerIsTaxi8] = useState()
	const [originalName8, setOriginalName8] = useState()
	const [originalNumber8, setOriginalNumber8] = useState()
	const [originalBY8, setOriginalBY8] = useState()
	//
	const [name9, setName9] = useState()
	const [number9, setNumber9] = useState()
	const [BY9, setBY9] = useState()
	const [playerIsGoalie9, setPlayerIsGoalie9] = useState()
	const [playerIsTaxi9, setPlayerIsTaxi9] = useState()
	const [originalName9, setOriginalName9] = useState()
	const [originalNumber9, setOriginalNumber9] = useState()
	const [originalBY9, setOriginalBY9] = useState()
	//
	const [name10, setName10] = useState()
	const [number10, setNumber10] = useState()
	const [BY10, setBY10] = useState()
	const [playerIsGoalie10, setPlayerIsGoalie10] = useState()
	const [playerIsTaxi10, setPlayerIsTaxi10] = useState()
	const [originalName10, setOriginalName10] = useState()
	const [originalNumber10, setOriginalNumber10] = useState()
	const [originalBY10, setOriginalBY10] = useState()
	//
	const [name11, setName11] = useState()
	const [number11, setNumber11] = useState()
	const [BY11, setBY11] = useState()
	const [playerIsGoalie11, setPlayerIsGoalie11] = useState()
	const [playerIsTaxi11, setPlayerIsTaxi11] = useState()
	const [originalName11, setOriginalName11] = useState()
	const [originalNumber11, setOriginalNumber11] = useState()
	const [originalBY11, setOriginalBY11] = useState()
	//
	const [name12, setName12] = useState()
	const [number12, setNumber12] = useState()
	const [BY12, setBY12] = useState()
	const [playerIsGoalie12, setPlayerIsGoalie12] = useState()
	const [playerIsTaxi12, setPlayerIsTaxi12] = useState()
	const [originalName12, setOriginalName12] = useState()
	const [originalNumber12, setOriginalNumber12] = useState()
	const [originalBY12, setOriginalBY12] = useState()
	//
	const [name13, setName13] = useState()
	const [number13, setNumber13] = useState()
	const [BY13, setBY13] = useState()
	const [playerIsGoalie13, setPlayerIsGoalie13] = useState()
	const [playerIsTaxi13, setPlayerIsTaxi13] = useState()
	const [originalName13, setOriginalName13] = useState()
	const [originalNumber13, setOriginalNumber13] = useState()
	const [originalBY13, setOriginalBY13] = useState()
	//
	const [name14, setName14] = useState()
	const [number14, setNumber14] = useState()
	const [BY14, setBY14] = useState()
	const [playerIsGoalie14, setPlayerIsGoalie14] = useState()
	const [playerIsTaxi14, setPlayerIsTaxi14] = useState()
	const [originalName14, setOriginalName14] = useState()
	const [originalNumber14, setOriginalNumber14] = useState()
	const [originalBY14, setOriginalBY14] = useState()
	//
	const [name15, setName15] = useState()
	const [number15, setNumber15] = useState()
	const [BY15, setBY15] = useState()
	const [playerIsGoalie15, setPlayerIsGoalie15] = useState()
	const [playerIsTaxi15, setPlayerIsTaxi15] = useState()
	const [originalName15, setOriginalName15] = useState()
	const [originalNumber15, setOriginalNumber15] = useState()
	const [originalBY15, setOriginalBY15] = useState()
	//
	const [name16, setName16] = useState()
	const [number16, setNumber16] = useState()
	const [BY16, setBY16] = useState()
	const [playerIsGoalie16, setPlayerIsGoalie16] = useState()
	const [playerIsTaxi16, setPlayerIsTaxi16] = useState()
	const [originalName16, setOriginalName16] = useState()
	const [originalNumber16, setOriginalNumber16] = useState()
	const [originalBY16, setOriginalBY16] = useState()
	//
	const [name17, setName17] = useState()
	const [number17, setNumber17] = useState()
	const [BY17, setBY17] = useState()
	const [playerIsGoalie17, setPlayerIsGoalie17] = useState()
	const [playerIsTaxi17, setPlayerIsTaxi17] = useState()
	const [originalName17, setOriginalName17] = useState()
	const [originalNumber17, setOriginalNumber17] = useState()
	const [originalBY17, setOriginalBY17] = useState()
	//
	const [name18, setName18] = useState()
	const [number18, setNumber18] = useState()
	const [BY18, setBY18] = useState()
	const [playerIsGoalie18, setPlayerIsGoalie18] = useState()
	const [playerIsTaxi18, setPlayerIsTaxi18] = useState()
	const [originalName18, setOriginalName18] = useState()
	const [originalNumber18, setOriginalNumber18] = useState()
	const [originalBY18, setOriginalBY18] = useState()
	//
	const [name19, setName19] = useState()
	const [number19, setNumber19] = useState()
	const [BY19, setBY19] = useState()
	const [playerIsGoalie19, setPlayerIsGoalie19] = useState()
	const [playerIsTaxi19, setPlayerIsTaxi19] = useState()
	const [originalName19, setOriginalName19] = useState()
	const [originalNumber19, setOriginalNumber19] = useState()
	const [originalBY19, setOriginalBY19] = useState()
	//
	const [name20, setName20] = useState()
	const [number20, setNumber20] = useState()
	const [BY20, setBY20] = useState()
	const [playerIsGoalie20, setPlayerIsGoalie20] = useState()
	const [playerIsTaxi20, setPlayerIsTaxi20] = useState()
	const [originalName20, setOriginalName20] = useState()
	const [originalNumber20, setOriginalNumber20] = useState()
	const [originalBY20, setOriginalBY20] = useState()
	//
	const [name21, setName21] = useState()
	const [number21, setNumber21] = useState()
	const [BY21, setBY21] = useState()
	const [playerIsGoalie21, setPlayerIsGoalie21] = useState()
	const [playerIsTaxi21, setPlayerIsTaxi21] = useState()
	const [originalName21, setOriginalName21] = useState()
	const [originalNumber21, setOriginalNumber21] = useState()
	const [originalBY21, setOriginalBY21] = useState()
	//
	const [name22, setName22] = useState()
	const [number22, setNumber22] = useState()
	const [BY22, setBY22] = useState()
	const [playerIsGoalie22, setPlayerIsGoalie22] = useState()
	const [playerIsTaxi22, setPlayerIsTaxi22] = useState()
	const [originalName22, setOriginalName22] = useState()
	const [originalNumber22, setOriginalNumber22] = useState()
	const [originalBY22, setOriginalBY22] = useState()
	//
	const [name23, setName23] = useState()
	const [number23, setNumber23] = useState()
	const [BY23, setBY23] = useState()
	const [playerIsGoalie23, setPlayerIsGoalie23] = useState()
	const [playerIsTaxi23, setPlayerIsTaxi23] = useState()
	const [originalName23, setOriginalName23] = useState()
	const [originalNumber23, setOriginalNumber23] = useState()
	const [originalBY23, setOriginalBY23] = useState()
	//
	const [name24, setName24] = useState()
	const [number24, setNumber24] = useState()
	const [BY24, setBY24] = useState()
	const [playerIsGoalie24, setPlayerIsGoalie24] = useState()
	const [playerIsTaxi24, setPlayerIsTaxi24] = useState()
	const [originalName24, setOriginalName24] = useState()
	const [originalNumber24, setOriginalNumber24] = useState()
	const [originalBY24, setOriginalBY24] = useState()
	//
	const [name25, setName25] = useState()
	const [number25, setNumber25] = useState()
	const [BY25, setBY25] = useState()
	const [playerIsGoalie25, setPlayerIsGoalie25] = useState()
	const [playerIsTaxi25, setPlayerIsTaxi25] = useState()
	const [originalName25, setOriginalName25] = useState()
	const [originalNumber25, setOriginalNumber25] = useState()
	const [originalBY25, setOriginalBY25] = useState()
	//
	const [name26, setName26] = useState()
	const [number26, setNumber26] = useState()
	const [BY26, setBY26] = useState()
	const [playerIsGoalie26, setPlayerIsGoalie26] = useState()
	const [playerIsTaxi26, setPlayerIsTaxi26] = useState()
	const [originalName26, setOriginalName26] = useState()
	const [originalNumber26, setOriginalNumber26] = useState()
	const [originalBY26, setOriginalBY26] = useState()
	//
	const [name27, setName27] = useState()
	const [number27, setNumber27] = useState()
	const [BY27, setBY27] = useState()
	const [playerIsGoalie27, setPlayerIsGoalie27] = useState()
	const [playerIsTaxi27, setPlayerIsTaxi27] = useState()
	const [originalName27, setOriginalName27] = useState()
	const [originalNumber27, setOriginalNumber27] = useState()
	const [originalBY27, setOriginalBY27] = useState()
	//
	const [name28, setName28] = useState()
	const [number28, setNumber28] = useState()
	const [BY28, setBY28] = useState()
	const [playerIsGoalie28, setPlayerIsGoalie28] = useState()
	const [playerIsTaxi28, setPlayerIsTaxi28] = useState()
	const [originalName28, setOriginalName28] = useState()
	const [originalNumber28, setOriginalNumber28] = useState()
	const [originalBY28, setOriginalBY28] = useState()
	//
	const [name29, setName29] = useState()
	const [number29, setNumber29] = useState()
	const [BY29, setBY29] = useState()
	const [playerIsGoalie29, setPlayerIsGoalie29] = useState()
	const [playerIsTaxi29, setPlayerIsTaxi29] = useState()
	const [originalName29, setOriginalName29] = useState()
	const [originalNumber29, setOriginalNumber29] = useState()
	const [originalBY29, setOriginalBY29] = useState()
	//
	const [name30, setName30] = useState()
	const [number30, setNumber30] = useState()
	const [BY30, setBY30] = useState()
	const [playerIsGoalie30, setPlayerIsGoalie30] = useState()
	const [playerIsTaxi30, setPlayerIsTaxi30] = useState()
	const [originalName30, setOriginalName30] = useState()
	const [originalNumber30, setOriginalNumber30] = useState()
	const [originalBY30, setOriginalBY30] = useState()
	//
	//
	const [playerIsActive1, setPlayerIsActive1] = useState()
	const [playerIsActive2, setPlayerIsActive2] = useState()
	const [playerIsActive3, setPlayerIsActive3] = useState()
	const [playerIsActive4, setPlayerIsActive4] = useState()
	const [playerIsActive5, setPlayerIsActive5] = useState()
	const [playerIsActive6, setPlayerIsActive6] = useState()
	const [playerIsActive7, setPlayerIsActive7] = useState()
	const [playerIsActive8, setPlayerIsActive8] = useState()
	const [playerIsActive9, setPlayerIsActive9] = useState()
	const [playerIsActive10, setPlayerIsActive10] = useState()
	const [playerIsActive11, setPlayerIsActive11] = useState()
	const [playerIsActive12, setPlayerIsActive12] = useState()
	const [playerIsActive13, setPlayerIsActive13] = useState()
	const [playerIsActive14, setPlayerIsActive14] = useState()
	const [playerIsActive15, setPlayerIsActive15] = useState()
	const [playerIsActive16, setPlayerIsActive16] = useState()
	const [playerIsActive17, setPlayerIsActive17] = useState()
	const [playerIsActive18, setPlayerIsActive18] = useState()
	const [playerIsActive19, setPlayerIsActive19] = useState()
	const [playerIsActive20, setPlayerIsActive20] = useState()
	const [playerIsActive21, setPlayerIsActive21] = useState()
	const [playerIsActive22, setPlayerIsActive22] = useState()
	const [playerIsActive23, setPlayerIsActive23] = useState()
	const [playerIsActive24, setPlayerIsActive24] = useState()
	const [playerIsActive25, setPlayerIsActive25] = useState()
	const [playerIsActive26, setPlayerIsActive26] = useState()
	const [playerIsActive27, setPlayerIsActive27] = useState()
	const [playerIsActive28, setPlayerIsActive28] = useState()
	const [playerIsActive29, setPlayerIsActive29] = useState()
	const [playerIsActive30, setPlayerIsActive30] = useState()
	//
	//
	const coachId = useParams().coachId
	const inputTeamName = useParams().team
	const inputYear = useParams().year
	//
	//
	//First thing we want to do here is go into the database and get this team information
	//admin-controller       getRegisteredTeamData
	//
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/admin/${coachId}/${inputTeamName}/${inputYear}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				console.log('responseData: ' + JSON.stringify(responseData))

				setOriginalHeadCoach(responseData.headCoach)
				setOriginalAssistantCoaches(responseData.assistantCoaches)
				setOriginalTeam(responseData.team)
				setOriginalOrg(responseData.org)
				setOriginalYear(responseData.year)
				setOriginalDate(responseData.date)
				//
				setOriginalName1(responseData.name1)
				setOriginalNumber1(responseData.number1)
				setOriginalBY1(responseData.BY1)
				setPlayerIsGoalie1(responseData.playerIsGoalie1)
				setPlayerIsTaxi1(responseData.playerIsTaxi1)
				//
				setOriginalName2(responseData.name2)
				setOriginalNumber2(responseData.number2)
				setOriginalBY2(responseData.BY2)
				setPlayerIsGoalie2(responseData.playerIsGoalie2)
				setPlayerIsTaxi2(responseData.playerIsTaxi2)
				//
				setOriginalName3(responseData.name3)
				setOriginalNumber3(responseData.number3)
				setOriginalBY3(responseData.BY3)
				setPlayerIsGoalie3(responseData.playerIsGoalie3)
				setPlayerIsTaxi3(responseData.playerIsTaxi3)
				//
				setOriginalName4(responseData.name4)
				setOriginalNumber4(responseData.number4)
				setOriginalBY4(responseData.BY4)
				setPlayerIsGoalie4(responseData.playerIsGoalie4)
				setPlayerIsTaxi4(responseData.playerIsTaxi4)
				//
				setOriginalName5(responseData.name5)
				setOriginalNumber5(responseData.number5)
				setOriginalBY5(responseData.BY5)
				setPlayerIsGoalie5(responseData.playerIsGoalie5)
				setPlayerIsTaxi5(responseData.playerIsTaxi5)
				//
				setOriginalName6(responseData.name6)
				setOriginalNumber6(responseData.number6)
				setOriginalBY6(responseData.BY6)
				setPlayerIsGoalie6(responseData.playerIsGoalie6)
				setPlayerIsTaxi6(responseData.playerIsTaxi6)
				//
				setOriginalName7(responseData.name7)
				setOriginalNumber7(responseData.number7)
				setOriginalBY7(responseData.BY7)
				setPlayerIsGoalie7(responseData.playerIsGoalie7)
				setPlayerIsTaxi7(responseData.playerIsTaxi7)
				//
				setOriginalName8(responseData.name8)
				setOriginalNumber8(responseData.number8)
				setOriginalBY8(responseData.BY8)
				setPlayerIsGoalie8(responseData.playerIsGoalie8)
				setPlayerIsTaxi8(responseData.playerIsTaxi8)
				//
				setOriginalName9(responseData.name9)
				setOriginalNumber9(responseData.number9)
				setOriginalBY9(responseData.BY9)
				setPlayerIsGoalie9(responseData.playerIsGoalie9)
				setPlayerIsTaxi9(responseData.playerIsTaxi9)
				//
				setOriginalName10(responseData.name10)
				setOriginalNumber10(responseData.number10)
				setOriginalBY10(responseData.BY10)
				setPlayerIsGoalie10(responseData.playerIsGoalie10)
				setPlayerIsTaxi10(responseData.playerIsTaxi10)
				//
				setOriginalName11(responseData.name11)
				setOriginalNumber11(responseData.number11)
				setOriginalBY11(responseData.BY11)
				setPlayerIsGoalie11(responseData.playerIsGoalie11)
				setPlayerIsTaxi11(responseData.playerIsTaxi11)
				//
				setOriginalName12(responseData.name12)
				setOriginalNumber12(responseData.number12)
				setOriginalBY12(responseData.BY12)
				setPlayerIsGoalie12(responseData.playerIsGoalie12)
				setPlayerIsTaxi12(responseData.playerIsTaxi12)
				//
				setOriginalName13(responseData.name13)
				setOriginalNumber13(responseData.number13)
				setOriginalBY13(responseData.BY13)
				setPlayerIsGoalie13(responseData.playerIsGoalie13)
				setPlayerIsTaxi13(responseData.playerIsTaxi13)
				//
				setOriginalName14(responseData.name14)
				setOriginalNumber14(responseData.number14)
				setOriginalBY14(responseData.BY14)
				setPlayerIsGoalie14(responseData.playerIsGoalie14)
				setPlayerIsTaxi14(responseData.playerIsTaxi14)
				//
				setOriginalName15(responseData.name15)
				setOriginalNumber15(responseData.number15)
				setOriginalBY15(responseData.BY15)
				setPlayerIsGoalie15(responseData.playerIsGoalie15)
				setPlayerIsTaxi15(responseData.playerIsTaxi15)
				//
				setOriginalName16(responseData.name16)
				setOriginalNumber16(responseData.number16)
				setOriginalBY16(responseData.BY16)
				setPlayerIsGoalie16(responseData.playerIsGoalie16)
				setPlayerIsTaxi16(responseData.playerIsTaxi16)
				//
				setOriginalName17(responseData.name17)
				setOriginalNumber17(responseData.number17)
				setOriginalBY17(responseData.BY17)
				setPlayerIsGoalie17(responseData.playerIsGoalie17)
				setPlayerIsTaxi17(responseData.playerIsTaxi17)
				//
				setOriginalName18(responseData.name18)
				setOriginalNumber18(responseData.number18)
				setOriginalBY18(responseData.BY18)
				setPlayerIsGoalie18(responseData.playerIsGoalie18)
				setPlayerIsTaxi18(responseData.playerIsTaxi18)
				//
				setOriginalName19(responseData.name19)
				setOriginalNumber19(responseData.number19)
				setOriginalBY19(responseData.BY19)
				setPlayerIsGoalie19(responseData.playerIsGoalie19)
				setPlayerIsTaxi19(responseData.playerIsTaxi19)
				//
				setOriginalName20(responseData.name20)
				setOriginalNumber20(responseData.number20)
				setOriginalBY20(responseData.BY20)
				setPlayerIsGoalie20(responseData.playerIsGoalie20)
				setPlayerIsTaxi20(responseData.playerIsTaxi20)
				//
				setOriginalName21(responseData.name21)
				setOriginalNumber21(responseData.number21)
				setOriginalBY21(responseData.BY21)
				setPlayerIsGoalie21(responseData.playerIsGoalie21)
				setPlayerIsTaxi21(responseData.playerIsTaxi21)
				//
				setOriginalName22(responseData.name22)
				setOriginalNumber22(responseData.number22)
				setOriginalBY22(responseData.BY22)
				setPlayerIsGoalie22(responseData.playerIsGoalie22)
				setPlayerIsTaxi22(responseData.playerIsTaxi22)
				//
				setOriginalName23(responseData.name23)
				setOriginalNumber23(responseData.number23)
				setOriginalBY23(responseData.BY23)
				setPlayerIsGoalie23(responseData.playerIsGoalie23)
				setPlayerIsTaxi23(responseData.playerIsTaxi23)
				//
				setOriginalName24(responseData.name24)
				setOriginalNumber24(responseData.number24)
				setOriginalBY24(responseData.BY24)
				setPlayerIsGoalie24(responseData.playerIsGoalie24)
				setPlayerIsTaxi24(responseData.playerIsTaxi24)
				//
				setOriginalName25(responseData.name25)
				setOriginalNumber25(responseData.number25)
				setOriginalBY25(responseData.BY25)
				setPlayerIsGoalie25(responseData.playerIsGoalie25)
				setPlayerIsTaxi25(responseData.playerIsTaxi25)
				//
				setOriginalName26(responseData.name26)
				setOriginalNumber26(responseData.number26)
				setOriginalBY26(responseData.BY26)
				setPlayerIsGoalie26(responseData.playerIsGoalie26)
				setPlayerIsTaxi26(responseData.playerIsTaxi26)
				//
				setOriginalName27(responseData.name27)
				setOriginalNumber27(responseData.number27)
				setOriginalBY27(responseData.BY27)
				setPlayerIsGoalie27(responseData.playerIsGoalie27)
				setPlayerIsTaxi27(responseData.playerIsTaxi27)
				//
				setOriginalName28(responseData.name28)
				setOriginalNumber28(responseData.number28)
				setOriginalBY28(responseData.BY28)
				setPlayerIsGoalie28(responseData.playerIsGoalie28)
				setPlayerIsTaxi28(responseData.playerIsTaxi28)
				//
				setOriginalName29(responseData.name29)
				setOriginalNumber29(responseData.number29)
				setOriginalBY29(responseData.BY29)
				setPlayerIsGoalie29(responseData.playerIsGoalie29)
				setPlayerIsTaxi29(responseData.playerIsTaxi29)
				//
				setOriginalName30(responseData.name30)
				setOriginalNumber30(responseData.number30)
				setOriginalBY30(responseData.BY30)
				setPlayerIsGoalie30(responseData.playerIsGoalie30)
				setPlayerIsTaxi30(responseData.playerIsTaxi30)
				//
				//
				setPlayerIsActive1(responseData.playerIsActive1)
				setPlayerIsActive2(responseData.playerIsActive2)
				setPlayerIsActive3(responseData.playerIsActive3)
				setPlayerIsActive4(responseData.playerIsActive4)
				setPlayerIsActive5(responseData.playerIsActive5)
				setPlayerIsActive6(responseData.playerIsActive6)
				setPlayerIsActive7(responseData.playerIsActive7)
				setPlayerIsActive8(responseData.playerIsActive8)
				setPlayerIsActive9(responseData.playerIsActive9)
				setPlayerIsActive10(responseData.playerIsActive10)
				setPlayerIsActive11(responseData.playerIsActive11)
				setPlayerIsActive12(responseData.playerIsActive12)
				setPlayerIsActive13(responseData.playerIsActive13)
				setPlayerIsActive14(responseData.playerIsActive14)
				setPlayerIsActive15(responseData.playerIsActive15)
				setPlayerIsActive16(responseData.playerIsActive16)
				setPlayerIsActive17(responseData.playerIsActive17)
				setPlayerIsActive18(responseData.playerIsActive18)
				setPlayerIsActive19(responseData.playerIsActive19)
				setPlayerIsActive20(responseData.playerIsActive20)
				setPlayerIsActive21(responseData.playerIsActive21)
				setPlayerIsActive22(responseData.playerIsActive22)
				setPlayerIsActive23(responseData.playerIsActive23)
				setPlayerIsActive24(responseData.playerIsActive24)
				setPlayerIsActive25(responseData.playerIsActive25)
				setPlayerIsActive26(responseData.playerIsActive26)
				setPlayerIsActive27(responseData.playerIsActive27)
				setPlayerIsActive28(responseData.playerIsActive28)
				setPlayerIsActive29(responseData.playerIsActive29)
				setPlayerIsActive30(responseData.playerIsActive30)
				//
				//
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//
	//
	//Next, lets call the backend to get all the organizations for our dropdown
	//     allOrgs
	useEffect(() => {
		console.log('useEffect')
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/allOrgs'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				//console.log('responseData: ' + JSON.stringify(responseData))
				setLoadedOrgs(responseData.allOrgs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//This creates an array from the loadedOrgs.  It will be our 'Organization' dropdown:
	if (loadedOrgs && !isLoading && !orgDropdownCreated) {
		const innerOrgArray = []
		loadedOrgs.forEach((org) => {
			const orgDisplay = org.orgName
			innerOrgArray.push(orgDisplay)
		})
		//NEW  This was added for leagues that have divisions
		let uniqueArray = []
		const duplicateFilter = innerOrgArray.filter((element) => {
			if (!uniqueArray.includes(element)) {
				uniqueArray.push(element)

				return true
			}
			return false
		})
		//end NEW
		setOrgArray(duplicateFilter)
		setOrgDropdownCreated(true)
	}
	//
	//
	//
	//
	//
	//
	let selectedOrg
	//
	const getHeadCoach = (event) => {
		setHeadCoach(event.target.value)
	}
	const getAssistantCoaches = (event) => {
		setAssistantCoaches(event.target.value)
	}
	const getTeam = (event) => {
		setTeam(event.target.value)
	}
	const getOrg = (event) => {
		const selectedValue = event.target.value
		selectedOrg = loadedOrgs[selectedValue].orgName
		console.log('selectedOrg: ' + selectedOrg)
		setOrg(selectedOrg)
	}
	const getYear = (event) => {
		setYear(event.target.value)
	}
	const getDate = (event) => {
		setDate(event.target.value)
	}
	//*************************************************** */
	//  Player 1
	//*************************************************** */
	const getName1 = (event) => {
		setName1(event.target.value)
	}
	const getNumber1 = (event) => {
		setNumber1(event.target.value)
	}
	const getBY1 = (event) => {
		setBY1(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox1 = (e) => {
		if (playerIsGoalie1) {
			setPlayerIsGoalie1(false)
		}
		if (!playerIsGoalie1) {
			setPlayerIsGoalie1(true)
		}
	}
	const togglePlayerIsTaxiCheckbox1 = (e) => {
		if (playerIsTaxi1) {
			setPlayerIsTaxi1(false)
		}
		if (!playerIsTaxi1) {
			setPlayerIsTaxi1(true)
		}
	}
	const togglePlayerIsActiveCheckbox1 = (e) => {
		if (playerIsActive1) {
			setPlayerIsActive1(false)
		}
		if (!playerIsActive1) {
			setPlayerIsActive1(true)
		}
	}
	//*************************************************** */
	//  Player 2
	//*************************************************** */
	const getName2 = (event) => {
		console.log('inside getName2 ' + event.target.value)
		setName2(event.target.value)
	}
	const getNumber2 = (event) => {
		setNumber2(event.target.value)
	}
	const getBY2 = (event) => {
		setBY2(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox2 = (e) => {
		if (playerIsGoalie2) {
			setPlayerIsGoalie2(false)
		}
		if (!playerIsGoalie2) {
			setPlayerIsGoalie2(true)
		}
	}
	const togglePlayerIsTaxiCheckbox2 = (e) => {
		if (playerIsTaxi2) {
			setPlayerIsTaxi2(false)
		}
		if (!playerIsTaxi2) {
			setPlayerIsTaxi2(true)
		}
	}
	const togglePlayerIsActiveCheckbox2 = (e) => {
		if (playerIsActive2) {
			setPlayerIsActive2(false)
		}
		if (!playerIsActive2) {
			setPlayerIsActive2(true)
		}
	}
	//*************************************************** */
	//  Player 3
	//*************************************************** */
	const getName3 = (event) => {
		setName3(event.target.value)
	}
	const getNumber3 = (event) => {
		setNumber3(event.target.value)
	}
	const getBY3 = (event) => {
		setBY3(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox3 = (e) => {
		if (playerIsGoalie3) {
			setPlayerIsGoalie3(false)
		}
		if (!playerIsGoalie3) {
			setPlayerIsGoalie3(true)
		}
	}
	const togglePlayerIsTaxiCheckbox3 = (e) => {
		if (playerIsTaxi3) {
			setPlayerIsTaxi3(false)
		}
		if (!playerIsTaxi3) {
			setPlayerIsTaxi3(true)
		}
	}
	const togglePlayerIsActiveCheckbox3 = (e) => {
		if (playerIsActive3) {
			setPlayerIsActive3(false)
		}
		if (!playerIsActive3) {
			setPlayerIsActive3(true)
		}
	}
	//*************************************************** */
	//  Player 4
	//*************************************************** */
	const getName4 = (event) => {
		setName4(event.target.value)
	}
	const getNumber4 = (event) => {
		setNumber4(event.target.value)
	}
	const getBY4 = (event) => {
		setBY4(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox4 = (e) => {
		if (playerIsGoalie4) {
			setPlayerIsGoalie4(false)
		}
		if (!playerIsGoalie4) {
			setPlayerIsGoalie4(true)
		}
	}
	const togglePlayerIsTaxiCheckbox4 = (e) => {
		if (playerIsTaxi4) {
			setPlayerIsTaxi4(false)
		}
		if (!playerIsTaxi4) {
			setPlayerIsTaxi4(true)
		}
	}
	const togglePlayerIsActiveCheckbox4 = (e) => {
		if (playerIsActive4) {
			setPlayerIsActive4(false)
		}
		if (!playerIsActive4) {
			setPlayerIsActive4(true)
		}
	}
	//*************************************************** */
	//  Player 5
	//*************************************************** */
	const getName5 = (event) => {
		setName5(event.target.value)
	}
	const getNumber5 = (event) => {
		setNumber5(event.target.value)
	}
	const getBY5 = (event) => {
		setBY5(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox5 = (e) => {
		if (playerIsGoalie5) {
			setPlayerIsGoalie5(false)
		}
		if (!playerIsGoalie5) {
			setPlayerIsGoalie5(true)
		}
	}
	const togglePlayerIsTaxiCheckbox5 = (e) => {
		if (playerIsTaxi5) {
			setPlayerIsTaxi5(false)
		}
		if (!playerIsTaxi5) {
			setPlayerIsTaxi5(true)
		}
	}
	const togglePlayerIsActiveCheckbox5 = (e) => {
		if (playerIsActive5) {
			setPlayerIsActive5(false)
		}
		if (!playerIsActive5) {
			setPlayerIsActive5(true)
		}
	}
	//*************************************************** */
	//  Player 6
	//*************************************************** */
	const getName6 = (event) => {
		setName6(event.target.value)
	}
	const getNumber6 = (event) => {
		setNumber6(event.target.value)
	}
	const getBY6 = (event) => {
		setBY6(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox6 = (e) => {
		if (playerIsGoalie6) {
			setPlayerIsGoalie6(false)
		}
		if (!playerIsGoalie6) {
			setPlayerIsGoalie6(true)
		}
	}
	const togglePlayerIsTaxiCheckbox6 = (e) => {
		if (playerIsTaxi6) {
			setPlayerIsTaxi6(false)
		}
		if (!playerIsTaxi6) {
			setPlayerIsTaxi6(true)
		}
	}
	const togglePlayerIsActiveCheckbox6 = (e) => {
		if (playerIsActive6) {
			setPlayerIsActive6(false)
		}
		if (!playerIsActive6) {
			setPlayerIsActive6(true)
		}
	}
	//*************************************************** */
	//  Player 7
	//*************************************************** */
	const getName7 = (event) => {
		setName7(event.target.value)
	}
	const getNumber7 = (event) => {
		setNumber7(event.target.value)
	}
	const getBY7 = (event) => {
		setBY7(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox7 = (e) => {
		if (playerIsGoalie7) {
			setPlayerIsGoalie7(false)
		}
		if (!playerIsGoalie7) {
			setPlayerIsGoalie7(true)
		}
	}
	const togglePlayerIsTaxiCheckbox7 = (e) => {
		if (playerIsTaxi7) {
			setPlayerIsTaxi7(false)
		}
		if (!playerIsTaxi7) {
			setPlayerIsTaxi7(true)
		}
	}
	const togglePlayerIsActiveCheckbox7 = (e) => {
		if (playerIsActive7) {
			setPlayerIsActive7(false)
		}
		if (!playerIsActive7) {
			setPlayerIsActive7(true)
		}
	}
	//*************************************************** */
	//  Player 8
	//*************************************************** */
	const getName8 = (event) => {
		setName8(event.target.value)
	}
	const getNumber8 = (event) => {
		setNumber8(event.target.value)
	}
	const getBY8 = (event) => {
		setBY8(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox8 = (e) => {
		if (playerIsGoalie8) {
			setPlayerIsGoalie8(false)
		}
		if (!playerIsGoalie8) {
			setPlayerIsGoalie8(true)
		}
	}
	const togglePlayerIsTaxiCheckbox8 = (e) => {
		if (playerIsTaxi8) {
			setPlayerIsTaxi8(false)
		}
		if (!playerIsTaxi8) {
			setPlayerIsTaxi8(true)
		}
	}
	const togglePlayerIsActiveCheckbox8 = (e) => {
		if (playerIsActive8) {
			setPlayerIsActive8(false)
		}
		if (!playerIsActive8) {
			setPlayerIsActive8(true)
		}
	}
	//*************************************************** */
	//  Player 9
	//*************************************************** */
	const getName9 = (event) => {
		setName9(event.target.value)
	}
	const getNumber9 = (event) => {
		setNumber9(event.target.value)
	}
	const getBY9 = (event) => {
		setBY9(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox9 = (e) => {
		if (playerIsGoalie9) {
			setPlayerIsGoalie9(false)
		}
		if (!playerIsGoalie9) {
			setPlayerIsGoalie9(true)
		}
	}
	const togglePlayerIsTaxiCheckbox9 = (e) => {
		if (playerIsTaxi9) {
			setPlayerIsTaxi9(false)
		}
		if (!playerIsTaxi9) {
			setPlayerIsTaxi9(true)
		}
	}
	const togglePlayerIsActiveCheckbox9 = (e) => {
		if (playerIsActive9) {
			setPlayerIsActive9(false)
		}
		if (!playerIsActive9) {
			setPlayerIsActive9(true)
		}
	}
	//*************************************************** */
	//  Player 10
	//*************************************************** */
	const getName10 = (event) => {
		setName10(event.target.value)
	}
	const getNumber10 = (event) => {
		setNumber10(event.target.value)
	}
	const getBY10 = (event) => {
		setBY10(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox10 = (e) => {
		if (playerIsGoalie10) {
			setPlayerIsGoalie10(false)
		}
		if (!playerIsGoalie10) {
			setPlayerIsGoalie10(true)
		}
	}
	const togglePlayerIsTaxiCheckbox10 = (e) => {
		if (playerIsTaxi10) {
			setPlayerIsTaxi10(false)
		}
		if (!playerIsTaxi10) {
			setPlayerIsTaxi10(true)
		}
	}
	const togglePlayerIsActiveCheckbox10 = (e) => {
		if (playerIsActive10) {
			setPlayerIsActive10(false)
		}
		if (!playerIsActive10) {
			setPlayerIsActive10(true)
		}
	}
	//*************************************************** */
	//  Player 11
	//*************************************************** */
	const getName11 = (event) => {
		setName11(event.target.value)
	}
	const getNumber11 = (event) => {
		setNumber11(event.target.value)
	}
	const getBY11 = (event) => {
		setBY11(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox11 = (e) => {
		if (playerIsGoalie11) {
			setPlayerIsGoalie11(false)
		}
		if (!playerIsGoalie11) {
			setPlayerIsGoalie11(true)
		}
	}
	const togglePlayerIsTaxiCheckbox11 = (e) => {
		if (playerIsTaxi11) {
			setPlayerIsTaxi11(false)
		}
		if (!playerIsTaxi11) {
			setPlayerIsTaxi11(true)
		}
	}
	const togglePlayerIsActiveCheckbox11 = (e) => {
		if (playerIsActive11) {
			setPlayerIsActive11(false)
		}
		if (!playerIsActive11) {
			setPlayerIsActive11(true)
		}
	}
	//*************************************************** */
	//  Player 12
	//*************************************************** */
	const getName12 = (event) => {
		setName12(event.target.value)
	}
	const getNumber12 = (event) => {
		setNumber12(event.target.value)
	}
	const getBY12 = (event) => {
		setBY12(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox12 = (e) => {
		if (playerIsGoalie12) {
			setPlayerIsGoalie12(false)
		}
		if (!playerIsGoalie12) {
			setPlayerIsGoalie12(true)
		}
	}
	const togglePlayerIsTaxiCheckbox12 = (e) => {
		if (playerIsTaxi12) {
			setPlayerIsTaxi12(false)
		}
		if (!playerIsTaxi12) {
			setPlayerIsTaxi12(true)
		}
	}
	const togglePlayerIsActiveCheckbox12 = (e) => {
		if (playerIsActive12) {
			setPlayerIsActive12(false)
		}
		if (!playerIsActive12) {
			setPlayerIsActive12(true)
		}
	}
	//*************************************************** */
	//  Player 13
	//*************************************************** */
	const getName13 = (event) => {
		setName13(event.target.value)
	}
	const getNumber13 = (event) => {
		setNumber13(event.target.value)
	}
	const getBY13 = (event) => {
		setBY13(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox13 = (e) => {
		if (playerIsGoalie13) {
			setPlayerIsGoalie13(false)
		}
		if (!playerIsGoalie13) {
			setPlayerIsGoalie13(true)
		}
	}
	const togglePlayerIsTaxiCheckbox13 = (e) => {
		if (playerIsTaxi13) {
			setPlayerIsTaxi3(false)
		}
		if (!playerIsTaxi13) {
			setPlayerIsTaxi3(true)
		}
	}
	const togglePlayerIsActiveCheckbox13 = (e) => {
		if (playerIsActive13) {
			setPlayerIsActive13(false)
		}
		if (!playerIsActive13) {
			setPlayerIsActive13(true)
		}
	}
	//*************************************************** */
	//  Player 4
	//*************************************************** */
	const getName14 = (event) => {
		setName14(event.target.value)
	}
	const getNumber14 = (event) => {
		setNumber14(event.target.value)
	}
	const getBY14 = (event) => {
		setBY14(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox14 = (e) => {
		if (playerIsGoalie14) {
			setPlayerIsGoalie14(false)
		}
		if (!playerIsGoalie14) {
			setPlayerIsGoalie14(true)
		}
	}
	const togglePlayerIsTaxiCheckbox14 = (e) => {
		if (playerIsTaxi14) {
			setPlayerIsTaxi14(false)
		}
		if (!playerIsTaxi14) {
			setPlayerIsTaxi14(true)
		}
	}
	const togglePlayerIsActiveCheckbox14 = (e) => {
		if (playerIsActive14) {
			setPlayerIsActive14(false)
		}
		if (!playerIsActive14) {
			setPlayerIsActive14(true)
		}
	}
	//*************************************************** */
	//  Player 15
	//*************************************************** */
	const getName15 = (event) => {
		setName15(event.target.value)
	}
	const getNumber15 = (event) => {
		setNumber15(event.target.value)
	}
	const getBY15 = (event) => {
		setBY15(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox15 = (e) => {
		if (playerIsGoalie15) {
			setPlayerIsGoalie15(false)
		}
		if (!playerIsGoalie15) {
			setPlayerIsGoalie15(true)
		}
	}
	const togglePlayerIsTaxiCheckbox15 = (e) => {
		if (playerIsTaxi15) {
			setPlayerIsTaxi15(false)
		}
		if (!playerIsTaxi15) {
			setPlayerIsTaxi15(true)
		}
	}
	const togglePlayerIsActiveCheckbox15 = (e) => {
		if (playerIsActive15) {
			setPlayerIsActive15(false)
		}
		if (!playerIsActive15) {
			setPlayerIsActive15(true)
		}
	}
	//*************************************************** */
	//  Player 16
	//*************************************************** */
	const getName16 = (event) => {
		setName16(event.target.value)
	}
	const getNumber16 = (event) => {
		setNumber16(event.target.value)
	}
	const getBY16 = (event) => {
		setBY16(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox16 = (e) => {
		if (playerIsGoalie16) {
			setPlayerIsGoalie16(false)
		}
		if (!playerIsGoalie16) {
			setPlayerIsGoalie16(true)
		}
	}
	const togglePlayerIsTaxiCheckbox16 = (e) => {
		if (playerIsTaxi16) {
			setPlayerIsTaxi16(false)
		}
		if (!playerIsTaxi16) {
			setPlayerIsTaxi16(true)
		}
	}
	const togglePlayerIsActiveCheckbox16 = (e) => {
		if (playerIsActive16) {
			setPlayerIsActive16(false)
		}
		if (!playerIsActive16) {
			setPlayerIsActive16(true)
		}
	}
	//*************************************************** */
	//  Player 17
	//*************************************************** */
	const getName17 = (event) => {
		setName17(event.target.value)
	}
	const getNumber17 = (event) => {
		setNumber17(event.target.value)
	}
	const getBY17 = (event) => {
		setBY17(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox17 = (e) => {
		if (playerIsGoalie17) {
			setPlayerIsGoalie17(false)
		}
		if (!playerIsGoalie17) {
			setPlayerIsGoalie17(true)
		}
	}
	const togglePlayerIsTaxiCheckbox17 = (e) => {
		if (playerIsTaxi17) {
			setPlayerIsTaxi17(false)
		}
		if (!playerIsTaxi17) {
			setPlayerIsTaxi17(true)
		}
	}
	const togglePlayerIsActiveCheckbox17 = (e) => {
		if (playerIsActive17) {
			setPlayerIsActive17(false)
		}
		if (!playerIsActive17) {
			setPlayerIsActive17(true)
		}
	}
	//*************************************************** */
	//  Player 18
	//*************************************************** */
	const getName18 = (event) => {
		setName18(event.target.value)
	}
	const getNumber18 = (event) => {
		setNumber18(event.target.value)
	}
	const getBY18 = (event) => {
		setBY18(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox18 = (e) => {
		if (playerIsGoalie18) {
			setPlayerIsGoalie18(false)
		}
		if (!playerIsGoalie18) {
			setPlayerIsGoalie18(true)
		}
	}
	const togglePlayerIsTaxiCheckbox18 = (e) => {
		if (playerIsTaxi18) {
			setPlayerIsTaxi18(false)
		}
		if (!playerIsTaxi18) {
			setPlayerIsTaxi18(true)
		}
	}
	const togglePlayerIsActiveCheckbox18 = (e) => {
		if (playerIsActive18) {
			setPlayerIsActive18(false)
		}
		if (!playerIsActive18) {
			setPlayerIsActive18(true)
		}
	}
	//*************************************************** */
	//  Player 19
	//*************************************************** */
	const getName19 = (event) => {
		setName19(event.target.value)
	}
	const getNumber19 = (event) => {
		setNumber19(event.target.value)
	}
	const getBY19 = (event) => {
		setBY19(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox19 = (e) => {
		if (playerIsGoalie19) {
			setPlayerIsGoalie19(false)
		}
		if (!playerIsGoalie19) {
			setPlayerIsGoalie19(true)
		}
	}
	const togglePlayerIsTaxiCheckbox19 = (e) => {
		if (playerIsTaxi19) {
			setPlayerIsTaxi19(false)
		}
		if (!playerIsTaxi19) {
			setPlayerIsTaxi19(true)
		}
	}
	const togglePlayerIsActiveCheckbox19 = (e) => {
		if (playerIsActive19) {
			setPlayerIsActive19(false)
		}
		if (!playerIsActive19) {
			setPlayerIsActive19(true)
		}
	}
	//*************************************************** */
	//  Player 20
	//*************************************************** */
	const getName20 = (event) => {
		setName20(event.target.value)
	}
	const getNumber20 = (event) => {
		setNumber20(event.target.value)
	}
	const getBY20 = (event) => {
		setBY20(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox20 = (e) => {
		if (playerIsGoalie20) {
			setPlayerIsGoalie20(false)
		}
		if (!playerIsGoalie20) {
			setPlayerIsGoalie20(true)
		}
	}
	const togglePlayerIsTaxiCheckbox20 = (e) => {
		if (playerIsTaxi20) {
			setPlayerIsTaxi20(false)
		}
		if (!playerIsTaxi20) {
			setPlayerIsTaxi20(true)
		}
	}
	const togglePlayerIsActiveCheckbox20 = (e) => {
		if (playerIsActive20) {
			setPlayerIsActive20(false)
		}
		if (!playerIsActive20) {
			setPlayerIsActive20(true)
		}
	}
	//*************************************************** */
	//  Player 21
	//*************************************************** */
	const getName21 = (event) => {
		setName21(event.target.value)
	}
	const getNumber21 = (event) => {
		setNumber21(event.target.value)
	}
	const getBY21 = (event) => {
		setBY21(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox21 = (e) => {
		if (playerIsGoalie21) {
			setPlayerIsGoalie21(false)
		}
		if (!playerIsGoalie21) {
			setPlayerIsGoalie21(true)
		}
	}
	const togglePlayerIsTaxiCheckbox21 = (e) => {
		if (playerIsTaxi21) {
			setPlayerIsTaxi21(false)
		}
		if (!playerIsTaxi21) {
			setPlayerIsTaxi21(true)
		}
	}
	const togglePlayerIsActiveCheckbox21 = (e) => {
		if (playerIsActive21) {
			setPlayerIsActive21(false)
		}
		if (!playerIsActive21) {
			setPlayerIsActive21(true)
		}
	}
	//*************************************************** */
	//  Player 22
	//*************************************************** */
	const getName22 = (event) => {
		setName22(event.target.value)
	}
	const getNumber22 = (event) => {
		setNumber22(event.target.value)
	}
	const getBY22 = (event) => {
		setBY22(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox22 = (e) => {
		if (playerIsGoalie22) {
			setPlayerIsGoalie22(false)
		}
		if (!playerIsGoalie22) {
			setPlayerIsGoalie22(true)
		}
	}
	const togglePlayerIsTaxiCheckbox22 = (e) => {
		if (playerIsTaxi22) {
			setPlayerIsTaxi22(false)
		}
		if (!playerIsTaxi22) {
			setPlayerIsTaxi22(true)
		}
	}
	const togglePlayerIsActiveCheckbox22 = (e) => {
		if (playerIsActive22) {
			setPlayerIsActive22(false)
		}
		if (!playerIsActive22) {
			setPlayerIsActive22(true)
		}
	}
	//*************************************************** */
	//  Player 23
	//*************************************************** */
	const getName23 = (event) => {
		setName23(event.target.value)
	}
	const getNumber23 = (event) => {
		setNumber23(event.target.value)
	}
	const getBY23 = (event) => {
		setBY23(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox23 = (e) => {
		if (playerIsGoalie23) {
			setPlayerIsGoalie23(false)
		}
		if (!playerIsGoalie23) {
			setPlayerIsGoalie23(true)
		}
	}
	const togglePlayerIsTaxiCheckbox23 = (e) => {
		if (playerIsTaxi23) {
			setPlayerIsTaxi23(false)
		}
		if (!playerIsTaxi23) {
			setPlayerIsTaxi23(true)
		}
	}
	const togglePlayerIsActiveCheckbox23 = (e) => {
		if (playerIsActive23) {
			setPlayerIsActive23(false)
		}
		if (!playerIsActive23) {
			setPlayerIsActive23(true)
		}
	}
	//*************************************************** */
	//  Player 24
	//*************************************************** */
	const getName24 = (event) => {
		setName24(event.target.value)
	}
	const getNumber24 = (event) => {
		setNumber24(event.target.value)
	}
	const getBY24 = (event) => {
		setBY24(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox24 = (e) => {
		if (playerIsGoalie24) {
			setPlayerIsGoalie24(false)
		}
		if (!playerIsGoalie24) {
			setPlayerIsGoalie24(true)
		}
	}
	const togglePlayerIsTaxiCheckbox24 = (e) => {
		if (playerIsTaxi24) {
			setPlayerIsTaxi24(false)
		}
		if (!playerIsTaxi24) {
			setPlayerIsTaxi24(true)
		}
	}
	const togglePlayerIsActiveCheckbox24 = (e) => {
		if (playerIsActive24) {
			setPlayerIsActive24(false)
		}
		if (!playerIsActive24) {
			setPlayerIsActive24(true)
		}
	}
	//*************************************************** */
	//  Player 25
	//*************************************************** */
	const getName25 = (event) => {
		setName25(event.target.value)
	}
	const getNumber25 = (event) => {
		setNumber25(event.target.value)
	}
	const getBY25 = (event) => {
		setBY25(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox25 = (e) => {
		if (playerIsGoalie25) {
			setPlayerIsGoalie25(false)
		}
		if (!playerIsGoalie25) {
			setPlayerIsGoalie25(true)
		}
	}
	const togglePlayerIsTaxiCheckbox25 = (e) => {
		if (playerIsTaxi25) {
			setPlayerIsTaxi25(false)
		}
		if (!playerIsTaxi25) {
			setPlayerIsTaxi25(true)
		}
	}
	const togglePlayerIsActiveCheckbox25 = (e) => {
		if (playerIsActive25) {
			setPlayerIsActive25(false)
		}
		if (!playerIsActive25) {
			setPlayerIsActive25(true)
		}
	}
	//*************************************************** */
	//  Player 26
	//*************************************************** */
	const getName26 = (event) => {
		setName26(event.target.value)
	}
	const getNumber26 = (event) => {
		setNumber26(event.target.value)
	}
	const getBY26 = (event) => {
		setBY26(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox26 = (e) => {
		if (playerIsGoalie26) {
			setPlayerIsGoalie26(false)
		}
		if (!playerIsGoalie26) {
			setPlayerIsGoalie26(true)
		}
	}
	const togglePlayerIsTaxiCheckbox26 = (e) => {
		if (playerIsTaxi26) {
			setPlayerIsTaxi26(false)
		}
		if (!playerIsTaxi26) {
			setPlayerIsTaxi26(true)
		}
	}
	const togglePlayerIsActiveCheckbox26 = (e) => {
		if (playerIsActive26) {
			setPlayerIsActive26(false)
		}
		if (!playerIsActive26) {
			setPlayerIsActive26(true)
		}
	}
	//*************************************************** */
	//  Player 27
	//*************************************************** */
	const getName27 = (event) => {
		setName27(event.target.value)
	}
	const getNumber27 = (event) => {
		setNumber27(event.target.value)
	}
	const getBY27 = (event) => {
		setBY27(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox27 = (e) => {
		if (playerIsGoalie27) {
			setPlayerIsGoalie27(false)
		}
		if (!playerIsGoalie27) {
			setPlayerIsGoalie27(true)
		}
	}
	const togglePlayerIsTaxiCheckbox27 = (e) => {
		if (playerIsTaxi27) {
			setPlayerIsTaxi27(false)
		}
		if (!playerIsTaxi27) {
			setPlayerIsTaxi27(true)
		}
	}
	const togglePlayerIsActiveCheckbox27 = (e) => {
		if (playerIsActive27) {
			setPlayerIsActive27(false)
		}
		if (!playerIsActive27) {
			setPlayerIsActive27(true)
		}
	}
	//*************************************************** */
	//  Player 28
	//*************************************************** */
	const getName28 = (event) => {
		setName28(event.target.value)
	}
	const getNumber28 = (event) => {
		setNumber28(event.target.value)
	}
	const getBY28 = (event) => {
		setBY28(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox28 = (e) => {
		if (playerIsGoalie28) {
			setPlayerIsGoalie28(false)
		}
		if (!playerIsGoalie28) {
			setPlayerIsGoalie28(true)
		}
	}
	const togglePlayerIsTaxiCheckbox28 = (e) => {
		if (playerIsTaxi28) {
			setPlayerIsTaxi28(false)
		}
		if (!playerIsTaxi28) {
			setPlayerIsTaxi28(true)
		}
	}
	const togglePlayerIsActiveCheckbox28 = (e) => {
		if (playerIsActive28) {
			setPlayerIsActive28(false)
		}
		if (!playerIsActive28) {
			setPlayerIsActive28(true)
		}
	}
	//*************************************************** */
	//  Player 29
	//*************************************************** */
	const getName29 = (event) => {
		setName29(event.target.value)
	}
	const getNumber29 = (event) => {
		setNumber29(event.target.value)
	}
	const getBY29 = (event) => {
		setBY29(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox29 = (e) => {
		if (playerIsGoalie29) {
			setPlayerIsGoalie29(false)
		}
		if (!playerIsGoalie29) {
			setPlayerIsGoalie29(true)
		}
	}
	const togglePlayerIsTaxiCheckbox29 = (e) => {
		if (playerIsTaxi29) {
			setPlayerIsTaxi29(false)
		}
		if (!playerIsTaxi29) {
			setPlayerIsTaxi29(true)
		}
	}
	const togglePlayerIsActiveCheckbox29 = (e) => {
		if (playerIsActive29) {
			setPlayerIsActive29(false)
		}
		if (!playerIsActive29) {
			setPlayerIsActive29(true)
		}
	}
	//*************************************************** */
	//  Player 30
	//*************************************************** */
	const getName30 = (event) => {
		setName30(event.target.value)
	}
	const getNumber30 = (event) => {
		setNumber30(event.target.value)
	}
	const getBY30 = (event) => {
		setBY30(event.target.value)
	}
	const togglePlayerIsGoalieCheckbox30 = (e) => {
		if (playerIsGoalie30) {
			setPlayerIsGoalie30(false)
		}
		if (!playerIsGoalie30) {
			setPlayerIsGoalie30(true)
		}
	}
	const togglePlayerIsTaxiCheckbox30 = (e) => {
		if (playerIsTaxi30) {
			setPlayerIsTaxi30(false)
		}
		if (!playerIsTaxi30) {
			setPlayerIsTaxi30(true)
		}
	}
	const togglePlayerIsActiveCheckbox30 = (e) => {
		if (playerIsActive30) {
			setPlayerIsActive30(false)
		}
		if (!playerIsActive30) {
			setPlayerIsActive30(true)
		}
	}
	//
	//
	console.log('originalOrg: ' + originalOrg)
	//
	let navigate = useNavigate()
	//
	//
	//Lets go into the database and update this team
	//
	//admin-controller      editRegisteredTeam
	//
	const registerUpdateHandler = async (event) => {
		let headCoachOut,
			assistantCoachesOut,
			teamOut,
			orgOut,
			yearOut,
			dateOut,
			name1Out,
			number1Out,
			BY1Out,
			name2Out,
			number2Out,
			BY2Out,
			name3Out,
			number3Out,
			BY3Out,
			name4Out,
			number4Out,
			BY4Out,
			name5Out,
			number5Out,
			BY5Out,
			name6Out,
			number6Out,
			BY6Out,
			name7Out,
			number7Out,
			BY7Out,
			name8Out,
			number8Out,
			BY8Out,
			name9Out,
			number9Out,
			BY9Out,
			name10Out,
			number10Out,
			BY10Out,
			name11Out,
			number11Out,
			BY11Out,
			name12Out,
			number12Out,
			BY12Out,
			name13Out,
			number13Out,
			BY13Out,
			name14Out,
			number14Out,
			BY14Out,
			name15Out,
			number15Out,
			BY15Out,
			name16Out,
			number16Out,
			BY16Out,
			name17Out,
			number17Out,
			BY17Out,
			name18Out,
			number18Out,
			BY18Out,
			name19Out,
			number19Out,
			BY19Out,
			name20Out,
			number20Out,
			BY20Out,
			name21Out,
			number21Out,
			BY21Out,
			name22Out,
			number22Out,
			BY22Out,
			name23Out,
			number23Out,
			BY23Out,
			name24Out,
			number24Out,
			BY24Out,
			name25Out,
			number25Out,
			BY25Out,
			name26Out,
			number26Out,
			BY26Out,
			name27Out,
			number27Out,
			BY27Out,
			name28Out,
			number28Out,
			BY28Out,
			name29Out,
			number29Out,
			BY29Out,
			name30Out,
			number30Out,
			BY30Out
		//
		//
		if (!headCoach) {
			headCoachOut = originalHeadCoach
		} else {
			headCoachOut = headCoach
		}
		if (!assistantCoaches) {
			assistantCoachesOut = originalAssistantCoaches
		} else {
			assistantCoachesOut = assistantCoaches
		}
		if (!team) {
			teamOut = originalTeam
		} else {
			teamOut = team
		}
		//
		//
		if (!org) {
			orgOut = originalOrg
		} else {
			orgOut = org
		}
		//
		//
		if (!year) {
			yearOut = originalYear
		} else {
			yearOut = year
		}
		if (!date) {
			dateOut = originalDate
		} else {
			dateOut = date
		}
		//
		//
		name1Out = document.getElementById('playerName1').value
		name2Out = document.getElementById('playerName2').value
		console.log('name2Out: ' + name2Out)
		name3Out = document.getElementById('playerName3').value
		name4Out = document.getElementById('playerName4').value
		name5Out = document.getElementById('playerName5').value
		name6Out = document.getElementById('playerName6').value
		name7Out = document.getElementById('playerName7').value
		name8Out = document.getElementById('playerName8').value
		name9Out = document.getElementById('playerName9').value
		name10Out = document.getElementById('playerName10').value
		name11Out = document.getElementById('playerName11').value
		name12Out = document.getElementById('playerName12').value
		name13Out = document.getElementById('playerName13').value
		name14Out = document.getElementById('playerName14').value
		name15Out = document.getElementById('playerName15').value
		name16Out = document.getElementById('playerName16').value
		name17Out = document.getElementById('playerName17').value
		name18Out = document.getElementById('playerName18').value
		name19Out = document.getElementById('playerName19').value
		name20Out = document.getElementById('playerName20').value
		name21Out = document.getElementById('playerName21').value
		name22Out = document.getElementById('playerName22').value
		name23Out = document.getElementById('playerName23').value
		name24Out = document.getElementById('playerName24').value
		name25Out = document.getElementById('playerName25').value
		name26Out = document.getElementById('playerName26').value
		name27Out = document.getElementById('playerName27').value
		name28Out = document.getElementById('playerName28').value
		name29Out = document.getElementById('playerName29').value
		name30Out = document.getElementById('playerName30').value
		//
		number1Out = document.getElementById('playerNumber1').value
		number2Out = document.getElementById('playerNumber2').value
		console.log('number2Out: ' + number2Out)
		number3Out = document.getElementById('playerNumber3').value
		number4Out = document.getElementById('playerNumber4').value
		number5Out = document.getElementById('playerNumber5').value
		number6Out = document.getElementById('playerNumber6').value
		number7Out = document.getElementById('playerNumber7').value
		number8Out = document.getElementById('playerNumber8').value
		number9Out = document.getElementById('playerNumber9').value
		number10Out = document.getElementById('playerNumber10').value
		number11Out = document.getElementById('playerNumber11').value
		number12Out = document.getElementById('playerNumber12').value
		number13Out = document.getElementById('playerNumber13').value
		number14Out = document.getElementById('playerNumber14').value
		number15Out = document.getElementById('playerNumber15').value
		number16Out = document.getElementById('playerNumber16').value
		number17Out = document.getElementById('playerNumber17').value
		number18Out = document.getElementById('playerNumber18').value
		number19Out = document.getElementById('playerNumber19').value
		number20Out = document.getElementById('playerNumber20').value
		number21Out = document.getElementById('playerNumber21').value
		number22Out = document.getElementById('playerNumber22').value
		number23Out = document.getElementById('playerNumber23').value
		number24Out = document.getElementById('playerNumber24').value
		number25Out = document.getElementById('playerNumber25').value
		number26Out = document.getElementById('playerNumber26').value
		number27Out = document.getElementById('playerNumber27').value
		number28Out = document.getElementById('playerNumber28').value
		number29Out = document.getElementById('playerNumber29').value
		number30Out = document.getElementById('playerNumber30').value
		//
		BY1Out = document.getElementById('playerBY1').value
		BY2Out = document.getElementById('playerBY2').value
		BY3Out = document.getElementById('playerBY3').value
		BY4Out = document.getElementById('playerBY4').value
		BY5Out = document.getElementById('playerBY5').value
		BY6Out = document.getElementById('playerBY6').value
		BY7Out = document.getElementById('playerBY7').value
		BY8Out = document.getElementById('playerBY8').value
		BY9Out = document.getElementById('playerBY9').value
		BY10Out = document.getElementById('playerBY10').value
		BY11Out = document.getElementById('playerBY11').value
		BY12Out = document.getElementById('playerBY12').value
		BY13Out = document.getElementById('playerBY13').value
		BY14Out = document.getElementById('playerBY14').value
		BY15Out = document.getElementById('playerBY15').value
		BY16Out = document.getElementById('playerBY16').value
		BY17Out = document.getElementById('playerBY17').value
		BY18Out = document.getElementById('playerBY18').value
		BY19Out = document.getElementById('playerBY19').value
		BY20Out = document.getElementById('playerBY20').value
		BY21Out = document.getElementById('playerBY21').value
		BY22Out = document.getElementById('playerBY22').value
		BY23Out = document.getElementById('playerBY23').value
		BY24Out = document.getElementById('playerBY24').value
		BY25Out = document.getElementById('playerBY25').value
		BY26Out = document.getElementById('playerBY26').value
		BY27Out = document.getElementById('playerBY27').value
		BY28Out = document.getElementById('playerBY28').value
		BY29Out = document.getElementById('playerBY29').value
		BY30Out = document.getElementById('playerBY30').value
		//**************************************** */
		// Player 1
		//**************************************** */
		/* if (!name1) {
			name1Out = originalName1
		} else {
			name1Out = name1
		} */
		/* if (!number1) {
			number1Out = originalNumber1
		} else {
			number1Out = number1
		}
		if (!BY1) {
			BY1Out = originalBY1
		} else {
			BY1Out = BY1
		} */
		//**************************************** */
		// Player 2
		//**************************************** */
		/* if (!name2) {
			name2Out = originalName2
		} else {
			name2Out = name2
		} */
		//name2Out = newName2
		/* if (!number2) {
			number2Out = originalNumber2
		} else {
			number2Out = number2
		} */
		/* if (!BY2) {
			BY2Out = originalBY2
		} else {
			BY2Out = BY2
		} */
		//**************************************** */
		// Player 3
		//**************************************** */
		/* if (!name3) {
			name3Out = originalName3
		} else {
			name3Out = name3
		} */
		/* if (!number3) {
			number3Out = originalNumber3
		} else {
			number3Out = number3
		}
		if (!BY3) {
			BY3Out = originalBY3
		} else {
			BY3Out = BY3
		} */
		//**************************************** */
		// Player 4
		//**************************************** */
		/* if (!name4) {
			name4Out = originalName4
		} else {
			name4Out = name4
		} */
		/* if (!number4) {
			number4Out = originalNumber4
		} else {
			number4Out = number4
		}
		if (!BY4) {
			BY4Out = originalBY4
		} else {
			BY4Out = BY4
		} */
		//**************************************** */
		// Player 5
		//**************************************** */
		/* if (!name5) {
			name5Out = originalName5
		} else {
			name5Out = name5
		} */
		/* if (!number5) {
			number5Out = originalNumber5
		} else {
			number5Out = number5
		}
		if (!BY5) {
			BY5Out = originalBY5
		} else {
			BY5Out = BY5
		} */
		//**************************************** */
		// Player 6
		//**************************************** */
		/* if (!name6) {
			name6Out = originalName6
		} else {
			name6Out = name6
		} */
		/* if (!number6) {
			number6Out = originalNumber6
		} else {
			number6Out = number6
		}
		if (!BY6) {
			BY6Out = originalBY6
		} else {
			BY6Out = BY6
		} */
		//**************************************** */
		// Player 7
		//**************************************** */
		/* if (!name7) {
			name7Out = originalName7
		} else {
			name7Out = name7
		} */
		/* if (!number7) {
			number7Out = originalNumber7
		} else {
			number7Out = number7
		}
		if (!BY7) {
			BY7Out = originalBY7
		} else {
			BY7Out = BY7
		} */
		//**************************************** */
		// Player 8
		//**************************************** */
		/* if (!name8) {
			name8Out = originalName8
		} else {
			name8Out = name8
		} */
		/* if (!number8) {
			number8Out = originalNumber8
		} else {
			number8Out = number8
		}
		if (!BY8) {
			BY8Out = originalBY8
		} else {
			BY8Out = BY8
		} */
		//**************************************** */
		// Player 9
		//**************************************** */
		/* if (!name9) {
			name9Out = originalName9
		} else {
			name9Out = name9
		} */
		/* if (!number9) {
			number9Out = originalNumber9
		} else {
			number9Out = number9
		}
		if (!BY9) {
			BY9Out = originalBY9
		} else {
			BY9Out = BY9
		} */
		//**************************************** */
		// Player 10
		//**************************************** */
		/* if (!name10) {
			name10Out = originalName10
		} else {
			name10Out = name10
		} */
		/* if (!number10) {
			number10Out = originalNumber10
		} else {
			number10Out = number10
		}
		if (!BY10) {
			BY10Out = originalBY10
		} else {
			BY10Out = BY10
		} */
		//**************************************** */
		// Player 11
		//**************************************** */
		/* if (!name11) {
			name11Out = originalName11
		} else {
			name11Out = name11
		} */
		/* if (!number11) {
			number11Out = originalNumber11
		} else {
			number11Out = number11
		}
		if (!BY11) {
			BY11Out = originalBY11
		} else {
			BY11Out = BY11
		} */
		//**************************************** */
		// Player 12
		//**************************************** */
		/* if (!name12) {
			name12Out = originalName12
		} else {
			name12Out = name12
		} */
		/* if (!number12) {
			number12Out = originalNumber12
		} else {
			number12Out = number12
		}
		if (!BY12) {
			BY12Out = originalBY12
		} else {
			BY12Out = BY12
		} */
		//**************************************** */
		// Player 13
		//**************************************** */
		/* if (!name13) {
			name13Out = originalName13
		} else {
			name13Out = name13
		} */
		/* if (!number13) {
			number13Out = originalNumber13
		} else {
			number13Out = number13
		}
		if (!BY13) {
			BY13Out = originalBY13
		} else {
			BY13Out = BY13
		} */
		//**************************************** */
		// Player 14
		//**************************************** */
		/* if (!name14) {
			name14Out = originalName14
		} else {
			name14Out = name14
		} */
		/* if (!number14) {
			number14Out = originalNumber14
		} else {
			number14Out = number14
		}
		if (!BY14) {
			BY14Out = originalBY14
		} else {
			B *Y14Out = BY14
		}/
		//**************************************** */
		// Player 15
		//**************************************** */
		/* if (!name15) {
			name15Out = originalName15
		} else {
			name15Out = name15
		} */
		/* if (!number15) {
			number15Out = originalNumber15
		} else {
			number15Out = number15
		}
		if (!BY15) {
			BY15Out = originalBY15
		} else {
			BY15Out = BY15
		} */
		//**************************************** */
		// Player 16
		//**************************************** */
		/* if (!name16) {
			name16Out = originalName16
		} else {
			name16Out = name16
		} */
		/* if (!number16) {
			number16Out = originalNumber16
		} else {
			number16Out = number16
		}
		if (!BY16) {
			BY16Out = originalBY16
		} else {
			BY16Out = BY16
		} */
		//**************************************** */
		// Player 17
		//**************************************** */
		/* if (!name17) {
			name17Out = originalName17
		} else {
			name17Out = name17
		} */
		/* if (!number17) {
			number17Out = originalNumber17
		} else {
			number17Out = number17
		}
		if (!BY17) {
			BY17Out = originalBY17
		} else {
			BY17Out = BY17
		} */
		//**************************************** */
		// Player 18
		//**************************************** */
		/* if (!name18) {
			name18Out = originalName18
		} else {
			name18Out = name18
		} */
		/* if (!number18) {
			number18Out = originalNumber18
		} else {
			number18Out = number18
		}
		if (!BY18) {
			BY18Out = originalBY18
		} else {
			BY18Out = BY18
		} */
		//**************************************** */
		// Player 19
		//**************************************** */
		/* if (!name19) {
			name19Out = originalName19
		} else {
			name19Out = name19
		} */
		/* if (!number19) {
			number19Out = originalNumber19
		} else {
			number19Out = number19
		}
		if (!BY19) {
			BY19Out = originalBY19
		} else {
			BY19Out = BY19
		} */
		//**************************************** */
		// Player 20
		//**************************************** */
		/* 	if (!name20) {
			name20Out = originalName20
		} else {
			name20Out = name20
		} */
		/* if (!number20) {
			number20Out = originalNumber20
		} else {
			number20Out = number20
		}
		if (!BY20) {
			BY20Out = originalBY20
		} else {
			BY20Out = BY20
		} */
		//**************************************** */
		// Player 21
		//**************************************** */
		/* if (!name21) {
			name21Out = originalName21
		} else {
			name21Out = name21
		} */
		/* if (!number21) {
			number21Out = originalNumber21
		} else {
			number21Out = number21
		}
		if (!BY21) {
			BY21Out = originalBY21
		} else {
			BY21Out = BY21
		} */
		//**************************************** */
		// Player 22
		//**************************************** */
		/* if (!name22) {
			name22Out = originalName22
		} else {
			name22Out = name22
		} */
		/* if (!number22) {
			number22Out = originalNumber22
		} else {
			number22Out = number22
		}
		if (!BY22) {
			BY22Out = originalBY22
		} else {
			BY22Out = BY22
		} */
		//**************************************** */
		// Player 23
		//**************************************** */
		/* if (!name23) {
			name23Out = originalName23
		} else {
			name23Out = name23
		} */
		/* if (!number23) {
			number23Out = originalNumber23
		} else {
			number23Out = number23
		}
		if (!BY23) {
			BY23Out = originalBY23
		} else {
			BY23Out = BY23
		} */
		//**************************************** */
		// Player 24
		//**************************************** */
		/* if (!name24) {
			name24Out = originalName24
		} else {
			name24Out = name24
		} */
		/* if (!number24) {
			number24Out = originalNumber24
		} else {
			number24Out = number24
		}
		if (!BY24) {
			BY24Out = originalBY24
		} else {
			BY24Out = BY24
		} */
		//**************************************** */
		// Player 25
		//**************************************** */
		/* if (!name25) {
			name25Out = originalName25
		} else {
			name25Out = name25
		} */
		/* if (!number25) {
			number25Out = originalNumber25
		} else {
			number25Out = number25
		}
		if (!BY25) {
			BY25Out = originalBY25
		} else {
			BY25Out = BY25
		} */
		//**************************************** */
		// Player 26
		//**************************************** */
		/* if (!name26) {
			name26Out = originalName26
		} else {
			name26Out = name26
		} */
		/* if (!number26) {
			number26Out = originalNumber26
		} else {
			number26Out = number26
		}
		if (!BY26) {
			BY26Out = originalBY26
		} else {
			BY26Out = BY26
		} */
		//**************************************** */
		// Player 27
		//**************************************** */
		/* if (!name27) {
			name27Out = originalName27
		} else {
			name27Out = name27
		} */
		/* if (!number27) {
			number27Out = originalNumber27
		} else {
			number27Out = number27
		}
		if (!BY27) {
			BY27Out = originalBY27
		} else {
			BY27Out = BY27
		} */
		//**************************************** */
		// Player 28
		//**************************************** */
		/* if (!name28) {
			name28Out = originalName28
		} else {
			name28Out = name28
		} */
		/* if (!number28) {
			number28Out = originalNumber28
		} else {
			number28Out = number28
		}
		if (!BY28) {
			BY28Out = originalBY28
		} else {
			BY28Out = BY28
		} */
		//**************************************** */
		// Player 29
		//**************************************** */
		/* if (!name29) {
			name29Out = originalName29
		} else {
			name29Out = name29
		} */
		/* if (!number29) {
			number29Out = originalNumber29
		} else {
			number29Out = number29
		}
		if (!BY29) {
			BY29Out = originalBY29
		} else {
			BY29Out = BY29
		} */
		//**************************************** */
		// Player 30
		//**************************************** */
		/* if (!name30) {
			name30Out = originalName30
		} else {
			name30Out = name30
		} */
		/* if (!number30) {
			number30Out = originalNumber30
		} else {
			number30Out = number30
		}
		if (!BY30) {
			BY30Out = originalBY30
		} else {
			BY30Out = BY30
		} */
		//
		//
		//
		//admin-controller     editRegisteredTeam
		console.log('you are here 1')
		event.preventDefault()
		try {
			setIsLoading(true)
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					`/admin/updateRegisteredTeam/${coachId}/${inputTeamName}/${inputYear}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						headCoachOut,
						assistantCoachesOut,
						teamOut,
						orgOut,
						yearOut,
						dateOut,
						name1Out,
						number1Out,
						BY1Out,
						playerIsGoalie1,
						playerIsTaxi1,
						name2Out,
						number2Out,
						BY2Out,
						playerIsGoalie2,
						playerIsTaxi2,
						name3Out,
						number3Out,
						BY3Out,
						playerIsGoalie3,
						playerIsTaxi3,
						name4Out,
						number4Out,
						BY4Out,
						playerIsGoalie4,
						playerIsTaxi4,
						name5Out,
						number5Out,
						BY5Out,
						playerIsGoalie5,
						playerIsTaxi5,
						name6Out,
						number6Out,
						BY6Out,
						playerIsGoalie6,
						playerIsTaxi6,
						name7Out,
						number7Out,
						BY7Out,
						playerIsGoalie7,
						playerIsTaxi7,
						name8Out,
						number8Out,
						BY8Out,
						playerIsGoalie8,
						playerIsTaxi8,
						name9Out,
						number9Out,
						BY9Out,
						playerIsGoalie9,
						playerIsTaxi9,
						name10Out,
						number10Out,
						BY10Out,
						playerIsGoalie10,
						playerIsTaxi10,
						name11Out,
						number11Out,
						BY11Out,
						playerIsGoalie11,
						playerIsTaxi11,
						name12Out,
						number12Out,
						BY12Out,
						playerIsGoalie12,
						playerIsTaxi12,
						name13Out,
						number13Out,
						BY13Out,
						playerIsGoalie13,
						playerIsTaxi13,
						name14Out,
						number14Out,
						BY14Out,
						playerIsGoalie14,
						playerIsTaxi14,
						name15Out,
						number15Out,
						BY15Out,
						playerIsGoalie15,
						playerIsTaxi15,
						name16Out,
						number16Out,
						BY16Out,
						playerIsGoalie16,
						playerIsTaxi16,
						name17Out,
						number17Out,
						BY17Out,
						playerIsGoalie17,
						playerIsTaxi17,
						name18Out,
						number18Out,
						BY18Out,
						playerIsGoalie18,
						playerIsTaxi18,
						name19Out,
						number19Out,
						BY19Out,
						playerIsGoalie19,
						playerIsTaxi19,
						name20Out,
						number20Out,
						BY20Out,
						playerIsGoalie20,
						playerIsTaxi20,
						name21Out,
						number21Out,
						BY21Out,
						playerIsGoalie21,
						playerIsTaxi21,
						name22Out,
						number22Out,
						BY22Out,
						playerIsGoalie22,
						playerIsTaxi22,
						name23Out,
						number23Out,
						BY23Out,
						playerIsGoalie23,
						playerIsTaxi23,
						name24Out,
						number24Out,
						BY24Out,
						playerIsGoalie24,
						playerIsTaxi24,
						name25Out,
						number25Out,
						BY25Out,
						playerIsGoalie25,
						playerIsTaxi25,
						name26Out,
						number26Out,
						BY26Out,
						playerIsGoalie26,
						playerIsTaxi26,
						name27Out,
						number27Out,
						BY27Out,
						playerIsGoalie27,
						playerIsTaxi27,
						name28Out,
						number28Out,
						BY28Out,
						playerIsGoalie28,
						playerIsTaxi28,
						name29Out,
						number29Out,
						BY29Out,
						playerIsGoalie29,
						playerIsTaxi29,
						name30Out,
						number30Out,
						BY30Out,
						playerIsGoalie30,
						playerIsTaxi30,
						playerIsActive1,
						playerIsActive2,
						playerIsActive3,
						playerIsActive4,
						playerIsActive5,
						playerIsActive6,
						playerIsActive7,
						playerIsActive8,
						playerIsActive9,
						playerIsActive10,
						playerIsActive11,
						playerIsActive12,
						playerIsActive13,
						playerIsActive14,
						playerIsActive15,
						playerIsActive16,
						playerIsActive17,
						playerIsActive18,
						playerIsActive19,
						playerIsActive20,
						playerIsActive21,
						playerIsActive22,
						playerIsActive23,
						playerIsActive24,
						playerIsActive25,
						playerIsActive26,
						playerIsActive27,
						playerIsActive28,
						playerIsActive29,
						playerIsActive30,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error('ERROR: ' + responseData.message)
			}

			console.log('you are here, lets show the modal')
			showSuccessfulRegistrationHandler()
		} catch (err) {
			console.log('error: ' + err)
			setIsLoading(false)
			setError(err.message + ': Something went wrong with registering a team.')
		}
	}
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//When user clicks OKAY on the popup modal (popup that says their confirmation was successful)
	//all this does is A: close the popup, and B: go back to the home page
	const okHandler = () => {
		console.log('ok has been clicked')
		setShowSuccessfulRegistrationModal(null)
		// setShowEditTeamModal(null)
		navigate(`/teamRegistration/${coachId}`)
	}
	//
	//
	//
	const showSuccessfulRegistrationHandler = () => {
		console.log('inside showSuccessfulRegistrationHandler')
		setShowSuccessfulRegistrationModal(true)
	}
	//
	//
	return (
		<React.Fragment>
			<Modal
				show={showSuccessfulRegistrationModal}
				header='Thank You!'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Your team has successfully been edited. Thanks!
						</p>
						<br></br>
						<button
							className='okButton buttonButton'
							inverse
							onClick={okHandler}
						>
							OKAY
						</button>
					</React.Fragment>
				}
			></Modal>
			<ErrorModal error={error} onClear={errorHandler} />
			<div>
				{isLoading && <LoadingSpinner asOverlay />}
				{!isLoading && loadedOrgs && (
					<main>
						<h2>Edit Your Team</h2>
						{/* {allTeamsForACoach && allTeamsForACoach.length === 0 && ( */}
						<div className='rcard'>
							<div className='rcard-body rcard-padding'>
								<form
									className='register-form'
									onSubmit={registerUpdateHandler}
								>
									<div className='row'>
										<div className='row' style={{ marginBottom: '2rem' }}>
											<select
												className='my-1 mr-sm-2 selectLeagueDropdown'
												onChange={getOrg}
												style={{
													background: 'white',
													borderWidth: '3px',
													width: '30vh',
													fontSize: 'medium',
													fontFamily: 'Montserrat, Work Sans, sans-serif',
												}}
											>
												<option defaultValue>
													{originalOrg && originalOrg.toUpperCase()}
												</option>
												{orgArray.map((org, i) => (
													<option value={i} key={i}>
														{org.toUpperCase()}
													</option>
												))}
											</select>
										</div>
										<div className='row'>
											<div className='col-sm-4'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtHeadCoach'
														style={{ fontWeight: 'bold' }}
													>
														Head Coach <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtHeadCoach'
														type='text'
														id='txtHeadCoach'
														tabIndex='6'
														className='rform-control'
														//placeholder='Head Coach'
														defaultValue={originalHeadCoach}
														onChange={getHeadCoach}
														required
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-sm-12'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtAssistantCoaches'
														style={{ fontWeight: 'bold' }}
													>
														Assistant Coach(es):{' '}
														<span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtAssistantCoaches'
														type='text'
														id='txtAssistantCoaches'
														tabIndex='7'
														className='rform-control'
														onChange={getAssistantCoaches}
														defaultValue={originalAssistantCoaches}
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtTeam' style={{ fontWeight: 'bold' }}>
													Team Name: <span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtTeam'
													type='text'
													id='txtTeam'
													tabIndex='8'
													className='rform-control'
													defaultValue={originalTeam}
													onChange={getTeam}
													required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-12 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='year' style={{ fontWeight: 'bold' }}>
													Tournament Year: <span className='c-red'>*</span>
												</label>
												<select
													id='year'
													type='text'
													tabIndex='9'
													className='rform-control'
													defaultValue={originalYear}
													onChange={getYear}
													required
												>
													<option value='2022'>2022</option>
													<option value='2023'>2023</option>
													<option value='2024'>2024</option>
													<option value='2025'>2025</option>
													<option value='2026'>2026</option>
													<option value='2027'>2027</option>
													<option value='2028'>2028</option>
													<option value='2029'>2029</option>
													<option value='2030'>2030</option>
													<option value='2031'>2031</option>
													<option value='2032'>2032</option>
													<option value='2033'>2033</option>
													<option value='2034'>2034</option>
													<option value='2035'>2035</option>
													<option value='2036'>2036</option>
													<option value='2037'>2037</option>
													<option value='2038'>2038</option>
												</select>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label
													htmlFor='txtPlayers'
													style={{ fontWeight: 'bold' }}
												>
													Rostered Players:
												</label>
												<table className='form-table'>
													<thead className='tableHeader2'>
														<tr>
															<th>Name</th>
															<th>#</th>
															<th>Birth Year</th>
															<th>Goalie?</th>
															<th>Taxi?</th>
															<th>Active?</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	id='playerName1'
																	type='text'
																	defaultValue={originalName1}
																	onChange={getName1}
																/>
															</td>
															<td>
																<input
																	id='playerNumber1'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber1}
																	onChange={getNumber1}
																/>
															</td>
															<td>
																<input
																	id='playerBY1'
																	type='text'
																	size='4'
																	defaultValue={originalBY1}
																	onChange={getBY1}
																/>
															</td>
															<td>
																<input
																	id='goalie1'
																	type='checkbox'
																	checked={playerIsGoalie1}
																	onChange={togglePlayerIsGoalieCheckbox1}
																/>
															</td>
															<td>
																<input
																	id='taxi1'
																	type='checkbox'
																	checked={playerIsTaxi1}
																	onChange={togglePlayerIsTaxiCheckbox1}
																/>
															</td>
															<td>
																<input
																	id='active1'
																	type='checkbox'
																	checked={playerIsActive1}
																	onChange={togglePlayerIsActiveCheckbox1}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName2'
																	type='text'
																	defaultValue={originalName2}
																	onChange={getName2}
																/>
															</td>
															<td>
																<input
																	id='playerNumber2'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber2}
																	onChange={getNumber2}
																/>
															</td>
															<td>
																<input
																	id='playerBY2'
																	type='text'
																	size='4'
																	defaultValue={originalBY2}
																	onChange={getBY2}
																/>
															</td>
															<td>
																<input
																	id='goalie2'
																	type='checkbox'
																	checked={playerIsGoalie2}
																	onChange={togglePlayerIsGoalieCheckbox2}
																/>
															</td>
															<td>
																<input
																	id='taxi2'
																	type='checkbox'
																	checked={playerIsTaxi2}
																	onChange={togglePlayerIsTaxiCheckbox2}
																/>
															</td>
															<td>
																<input
																	id='active2'
																	type='checkbox'
																	checked={playerIsActive2}
																	onChange={togglePlayerIsActiveCheckbox2}
																/>
															</td>
															{/* <td>
																<button
																	className='buttonButton'
																	style={{ color: 'red' }}
																	id={key}
																	onClick={(e) =>
																		showDeleteWarningHandler(e, org.orgName)
																	}
																>
																	DELETE
																</button>
															</td> */}
														</tr>
														<tr>
															<td>
																<input
																	id='playerName3'
																	type='text'
																	defaultValue={originalName3}
																	onChange={getName3}
																/>
															</td>
															<td>
																<input
																	id='playerNumber3'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber3}
																	onChange={getNumber3}
																/>
															</td>
															<td>
																<input
																	id='playerBY3'
																	type='text'
																	size='4'
																	defaultValue={originalBY3}
																	onChange={getBY3}
																/>
															</td>
															<td>
																<input
																	id='goalie3'
																	type='checkbox'
																	checked={playerIsGoalie3}
																	onChange={togglePlayerIsGoalieCheckbox3}
																/>
															</td>
															<td>
																<input
																	id='taxi3'
																	type='checkbox'
																	checked={playerIsTaxi3}
																	onChange={togglePlayerIsTaxiCheckbox3}
																/>
															</td>
															<td>
																<input
																	id='active3'
																	type='checkbox'
																	checked={playerIsActive3}
																	onChange={togglePlayerIsActiveCheckbox3}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName4'
																	type='text'
																	defaultValue={originalName4}
																	onChange={getName4}
																/>
															</td>
															<td>
																<input
																	id='playerNumber4'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber4}
																	onChange={getNumber4}
																/>
															</td>
															<td>
																<input
																	id='playerBY4'
																	type='text'
																	size='4'
																	defaultValue={originalBY4}
																	onChange={getBY4}
																/>
															</td>
															<td>
																<input
																	id='goalie4'
																	type='checkbox'
																	checked={playerIsGoalie4}
																	onChange={togglePlayerIsGoalieCheckbox4}
																/>
															</td>
															<td>
																<input
																	id='taxi4'
																	type='checkbox'
																	checked={playerIsTaxi4}
																	onChange={togglePlayerIsTaxiCheckbox4}
																/>
															</td>
															<td>
																<input
																	id='active4'
																	type='checkbox'
																	checked={playerIsActive4}
																	onChange={togglePlayerIsActiveCheckbox4}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName5'
																	type='text'
																	defaultValue={originalName5}
																	onChange={getName5}
																/>
															</td>
															<td>
																<input
																	id='playerNumber5'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber5}
																	onChange={getNumber5}
																/>
															</td>
															<td>
																<input
																	id='playerBY5'
																	type='text'
																	size='4'
																	defaultValue={originalBY5}
																	onChange={getBY5}
																/>
															</td>
															<td>
																<input
																	id='goalie5'
																	type='checkbox'
																	checked={playerIsGoalie5}
																	onChange={togglePlayerIsGoalieCheckbox5}
																/>
															</td>
															<td>
																<input
																	id='taxi5'
																	type='checkbox'
																	checked={playerIsTaxi5}
																	onChange={togglePlayerIsTaxiCheckbox5}
																/>
															</td>
															<td>
																<input
																	id='active5'
																	type='checkbox'
																	checked={playerIsActive5}
																	onChange={togglePlayerIsActiveCheckbox5}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName6'
																	type='text'
																	defaultValue={originalName6}
																	onChange={getName6}
																/>
															</td>
															<td>
																<input
																	id='playerNumber6'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber6}
																	onChange={getNumber6}
																/>
															</td>
															<td>
																<input
																	id='playerBY6'
																	type='text'
																	size='4'
																	defaultValue={originalBY6}
																	onChange={getBY6}
																/>
															</td>
															<td>
																<input
																	id='goalie6'
																	type='checkbox'
																	checked={playerIsGoalie6}
																	onChange={togglePlayerIsGoalieCheckbox6}
																/>
															</td>
															<td>
																<input
																	id='taxi6'
																	type='checkbox'
																	checked={playerIsTaxi6}
																	onChange={togglePlayerIsTaxiCheckbox6}
																/>
															</td>
															<td>
																<input
																	id='active6'
																	type='checkbox'
																	checked={playerIsActive6}
																	onChange={togglePlayerIsActiveCheckbox6}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName7'
																	type='text'
																	defaultValue={originalName7}
																	onChange={getName7}
																/>
															</td>
															<td>
																<input
																	id='playerNumber7'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber7}
																	onChange={getNumber7}
																/>
															</td>
															<td>
																<input
																	id='playerBY7'
																	type='text'
																	size='4'
																	defaultValue={originalBY7}
																	onChange={getBY7}
																/>
															</td>
															<td>
																<input
																	id='goalie7'
																	type='checkbox'
																	checked={playerIsGoalie7}
																	onChange={togglePlayerIsGoalieCheckbox7}
																/>
															</td>
															<td>
																<input
																	id='taxi7'
																	type='checkbox'
																	checked={playerIsTaxi7}
																	onChange={togglePlayerIsTaxiCheckbox7}
																/>
															</td>
															<td>
																<input
																	id='active7'
																	type='checkbox'
																	checked={playerIsActive7}
																	onChange={togglePlayerIsActiveCheckbox7}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName8'
																	type='text'
																	defaultValue={originalName8}
																	onChange={getName8}
																/>
															</td>
															<td>
																<input
																	id='playerNumber8'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber8}
																	onChange={getNumber8}
																/>
															</td>
															<td>
																<input
																	id='playerBY8'
																	type='text'
																	size='4'
																	defaultValue={originalBY8}
																	onChange={getBY8}
																/>
															</td>
															<td>
																<input
																	id='goalie8'
																	type='checkbox'
																	checked={playerIsGoalie8}
																	onChange={togglePlayerIsGoalieCheckbox8}
																/>
															</td>
															<td>
																<input
																	id='taxi8'
																	type='checkbox'
																	checked={playerIsTaxi8}
																	onChange={togglePlayerIsTaxiCheckbox8}
																/>
															</td>
															<td>
																<input
																	id='active8'
																	type='checkbox'
																	checked={playerIsActive8}
																	onChange={togglePlayerIsActiveCheckbox8}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName9'
																	type='text'
																	defaultValue={originalName9}
																	onChange={getName9}
																/>
															</td>
															<td>
																<input
																	id='playerNumber9'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber9}
																	onChange={getNumber9}
																/>
															</td>
															<td>
																<input
																	id='playerBY9'
																	type='text'
																	size='4'
																	defaultValue={originalBY9}
																	onChange={getBY9}
																/>
															</td>
															<td>
																<input
																	id='goalie9'
																	type='checkbox'
																	checked={playerIsGoalie9}
																	onChange={togglePlayerIsGoalieCheckbox9}
																/>
															</td>
															<td>
																<input
																	id='taxi9'
																	type='checkbox'
																	checked={playerIsTaxi9}
																	onChange={togglePlayerIsTaxiCheckbox9}
																/>
															</td>
															<td>
																<input
																	id='active9'
																	type='checkbox'
																	checked={playerIsActive9}
																	onChange={togglePlayerIsActiveCheckbox9}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName10'
																	type='text'
																	defaultValue={originalName10}
																	onChange={getName10}
																/>
															</td>
															<td>
																<input
																	id='playerNumber10'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber10}
																	onChange={getNumber10}
																/>
															</td>
															<td>
																<input
																	id='playerBY10'
																	type='text'
																	size='4'
																	defaultValue={originalBY10}
																	onChange={getBY10}
																/>
															</td>
															<td>
																<input
																	id='goalie10'
																	type='checkbox'
																	checked={playerIsGoalie10}
																	onChange={togglePlayerIsGoalieCheckbox10}
																/>
															</td>
															<td>
																<input
																	id='taxi10'
																	type='checkbox'
																	checked={playerIsTaxi10}
																	onChange={togglePlayerIsTaxiCheckbox10}
																/>
															</td>
															<td>
																<input
																	id='active10'
																	type='checkbox'
																	checked={playerIsActive10}
																	onChange={togglePlayerIsActiveCheckbox10}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName11'
																	type='text'
																	defaultValue={originalName11}
																	onChange={getName11}
																/>
															</td>
															<td>
																<input
																	id='playerNumber11'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber11}
																	onChange={getNumber11}
																/>
															</td>
															<td>
																<input
																	id='playerBY11'
																	type='text'
																	size='4'
																	defaultValue={originalBY11}
																	onChange={getBY11}
																/>
															</td>
															<td>
																<input
																	id='goalie11'
																	type='checkbox'
																	checked={playerIsGoalie11}
																	onChange={togglePlayerIsGoalieCheckbox11}
																/>
															</td>
															<td>
																<input
																	id='taxi11'
																	type='checkbox'
																	checked={playerIsTaxi11}
																	onChange={togglePlayerIsTaxiCheckbox11}
																/>
															</td>
															<td>
																<input
																	id='active11'
																	type='checkbox'
																	checked={playerIsActive11}
																	onChange={togglePlayerIsActiveCheckbox11}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName12'
																	type='text'
																	defaultValue={originalName12}
																	onChange={getName12}
																/>
															</td>
															<td>
																<input
																	id='playerNumber12'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber12}
																	onChange={getNumber12}
																/>
															</td>
															<td>
																<input
																	id='playerBY12'
																	type='text'
																	size='4'
																	defaultValue={originalBY12}
																	onChange={getBY12}
																/>
															</td>
															<td>
																<input
																	id='goalie12'
																	type='checkbox'
																	checked={playerIsGoalie12}
																	onChange={togglePlayerIsGoalieCheckbox12}
																/>
															</td>
															<td>
																<input
																	id='taxi12'
																	type='checkbox'
																	checked={playerIsTaxi12}
																	onChange={togglePlayerIsTaxiCheckbox12}
																/>
															</td>
															<td>
																<input
																	id='active12'
																	type='checkbox'
																	checked={playerIsActive12}
																	onChange={togglePlayerIsActiveCheckbox12}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName13'
																	type='text'
																	defaultValue={originalName13}
																	onChange={getName13}
																/>
															</td>
															<td>
																<input
																	id='playerNumber13'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber13}
																	onChange={getNumber13}
																/>
															</td>
															<td>
																<input
																	id='playerBY13'
																	type='text'
																	size='4'
																	defaultValue={originalBY13}
																	onChange={getBY13}
																/>
															</td>
															<td>
																<input
																	id='goalie13'
																	type='checkbox'
																	checked={playerIsGoalie13}
																	onChange={togglePlayerIsGoalieCheckbox13}
																/>
															</td>
															<td>
																<input
																	id='taxi13'
																	type='checkbox'
																	checked={playerIsTaxi13}
																	onChange={togglePlayerIsTaxiCheckbox13}
																/>
															</td>
															<td>
																<input
																	id='active13'
																	type='checkbox'
																	checked={playerIsActive13}
																	onChange={togglePlayerIsActiveCheckbox13}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName14'
																	type='text'
																	defaultValue={originalName14}
																	onChange={getName14}
																/>
															</td>
															<td>
																<input
																	id='playerNumber14'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber14}
																	onChange={getNumber14}
																/>
															</td>
															<td>
																<input
																	id='playerBY14'
																	type='text'
																	size='4'
																	defaultValue={originalBY14}
																	onChange={getBY14}
																/>
															</td>
															<td>
																<input
																	id='goalie14'
																	type='checkbox'
																	checked={playerIsGoalie14}
																	onChange={togglePlayerIsGoalieCheckbox14}
																/>
															</td>
															<td>
																<input
																	id='taxi14'
																	type='checkbox'
																	checked={playerIsTaxi14}
																	onChange={togglePlayerIsTaxiCheckbox14}
																/>
															</td>
															<td>
																<input
																	id='active14'
																	type='checkbox'
																	checked={playerIsActive14}
																	onChange={togglePlayerIsActiveCheckbox14}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName15'
																	type='text'
																	defaultValue={originalName15}
																	onChange={getName15}
																/>
															</td>
															<td>
																<input
																	id='playerNumber15'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber15}
																	onChange={getNumber15}
																/>
															</td>
															<td>
																<input
																	id='playerBY15'
																	type='text'
																	size='4'
																	defaultValue={originalBY15}
																	onChange={getBY15}
																/>
															</td>
															<td>
																<input
																	id='goalie15'
																	type='checkbox'
																	checked={playerIsGoalie15}
																	onChange={togglePlayerIsGoalieCheckbox15}
																/>
															</td>
															<td>
																<input
																	id='taxi15'
																	type='checkbox'
																	checked={playerIsTaxi15}
																	onChange={togglePlayerIsTaxiCheckbox15}
																/>
															</td>
															<td>
																<input
																	id='active15'
																	type='checkbox'
																	checked={playerIsActive15}
																	onChange={togglePlayerIsActiveCheckbox15}
																/>
															</td>
														</tr>
													</tbody>
												</table>
												<table className='form-table'>
													<thead className='tableHeader2'>
														<tr>
															<th>Name</th>
															<th>#</th>
															<th>Birth Year</th>
															<th>Goalie?</th>
															<th>Taxi?</th>
															<th>Active?</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	id='playerName16'
																	type='text'
																	defaultValue={originalName16}
																	onChange={getName16}
																/>
															</td>
															<td>
																<input
																	id='playerNumber16'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber16}
																	onChange={getNumber16}
																/>
															</td>
															<td>
																<input
																	id='playerBY16'
																	type='text'
																	size='4'
																	defaultValue={originalBY16}
																	onChange={getBY16}
																/>
															</td>
															<td>
																<input
																	id='goalie16'
																	type='checkbox'
																	checked={playerIsGoalie16}
																	onChange={togglePlayerIsGoalieCheckbox16}
																/>
															</td>
															<td>
																<input
																	id='taxi16'
																	type='checkbox'
																	checked={playerIsTaxi16}
																	onChange={togglePlayerIsTaxiCheckbox16}
																/>
															</td>
															<td>
																<input
																	id='active16'
																	type='checkbox'
																	checked={playerIsActive16}
																	onChange={togglePlayerIsActiveCheckbox16}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName17'
																	type='text'
																	defaultValue={originalName17}
																	onChange={getName17}
																/>
															</td>
															<td>
																<input
																	id='playerNumber17'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber17}
																	onChange={getNumber17}
																/>
															</td>
															<td>
																<input
																	id='playerBY17'
																	type='text'
																	size='4'
																	defaultValue={originalBY17}
																	onChange={getBY17}
																/>
															</td>
															<td>
																<input
																	id='goalie17'
																	type='checkbox'
																	checked={playerIsGoalie17}
																	onChange={togglePlayerIsGoalieCheckbox17}
																/>
															</td>
															<td>
																<input
																	id='taxi17'
																	type='checkbox'
																	checked={playerIsTaxi17}
																	onChange={togglePlayerIsTaxiCheckbox17}
																/>
															</td>
															<td>
																<input
																	id='active17'
																	type='checkbox'
																	checked={playerIsActive17}
																	onChange={togglePlayerIsActiveCheckbox17}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName18'
																	type='text'
																	defaultValue={originalName18}
																	onChange={getName18}
																/>
															</td>
															<td>
																<input
																	id='playerNumber18'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber18}
																	onChange={getNumber18}
																/>
															</td>
															<td>
																<input
																	id='playerBY18'
																	type='text'
																	size='4'
																	defaultValue={originalBY18}
																	onChange={getBY18}
																/>
															</td>
															<td>
																<input
																	id='goalie18'
																	type='checkbox'
																	checked={playerIsGoalie18}
																	onChange={togglePlayerIsGoalieCheckbox18}
																/>
															</td>
															<td>
																<input
																	id='taxi8'
																	type='checkbox'
																	checked={playerIsTaxi18}
																	onChange={togglePlayerIsTaxiCheckbox18}
																/>
															</td>
															<td>
																<input
																	id='active18'
																	type='checkbox'
																	checked={playerIsActive18}
																	onChange={togglePlayerIsActiveCheckbox18}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName19'
																	type='text'
																	defaultValue={originalName19}
																	onChange={getName19}
																/>
															</td>
															<td>
																<input
																	id='playerNumber19'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber19}
																	onChange={getNumber19}
																/>
															</td>
															<td>
																<input
																	id='playerBY19'
																	type='text'
																	size='4'
																	defaultValue={originalBY19}
																	onChange={getBY19}
																/>
															</td>
															<td>
																<input
																	id='goalie19'
																	type='checkbox'
																	checked={playerIsGoalie19}
																	onChange={togglePlayerIsGoalieCheckbox19}
																/>
															</td>
															<td>
																<input
																	id='taxi19'
																	type='checkbox'
																	checked={playerIsTaxi19}
																	onChange={togglePlayerIsTaxiCheckbox19}
																/>
															</td>

															<td>
																<input
																	id='active19'
																	type='checkbox'
																	checked={playerIsActive19}
																	onChange={togglePlayerIsActiveCheckbox19}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName20'
																	type='text'
																	defaultValue={originalName20}
																	onChange={getName20}
																/>
															</td>
															<td>
																<input
																	id='playerNumber20'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber20}
																	onChange={getNumber20}
																/>
															</td>
															<td>
																<input
																	id='playerBY20'
																	type='text'
																	size='4'
																	defaultValue={originalBY20}
																	onChange={getBY20}
																/>
															</td>
															<td>
																<input
																	id='goalie20'
																	type='checkbox'
																	checked={playerIsGoalie20}
																	onChange={togglePlayerIsGoalieCheckbox20}
																/>
															</td>
															<td>
																<input
																	id='taxi20'
																	type='checkbox'
																	checked={playerIsTaxi20}
																	onChange={togglePlayerIsTaxiCheckbox20}
																/>
															</td>
															<td>
																<input
																	id='active20'
																	type='checkbox'
																	checked={playerIsActive20}
																	onChange={togglePlayerIsActiveCheckbox20}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName21'
																	type='text'
																	defaultValue={originalName21}
																	onChange={getName21}
																/>
															</td>
															<td>
																<input
																	id='playerNumber21'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber21}
																	onChange={getNumber21}
																/>
															</td>
															<td>
																<input
																	id='playerBY21'
																	type='text'
																	size='4'
																	defaultValue={originalBY21}
																	onChange={getBY21}
																/>
															</td>
															<td>
																<input
																	id='goalie21'
																	type='checkbox'
																	checked={playerIsGoalie21}
																	onChange={togglePlayerIsGoalieCheckbox21}
																/>
															</td>
															<td>
																<input
																	id='taxi21'
																	type='checkbox'
																	checked={playerIsTaxi21}
																	onChange={togglePlayerIsTaxiCheckbox21}
																/>
															</td>
															<td>
																<input
																	id='active21'
																	type='checkbox'
																	checked={playerIsActive21}
																	onChange={togglePlayerIsActiveCheckbox21}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName22'
																	type='text'
																	defaultValue={originalName22}
																	onChange={getName22}
																/>
															</td>
															<td>
																<input
																	id='playerNumber22'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber22}
																	onChange={getNumber22}
																/>
															</td>
															<td>
																<input
																	id='playerBY22'
																	type='text'
																	size='4'
																	defaultValue={originalBY22}
																	onChange={getBY22}
																/>
															</td>
															<td>
																<input
																	id='goalie22'
																	type='checkbox'
																	checked={playerIsGoalie22}
																	onChange={togglePlayerIsGoalieCheckbox22}
																/>
															</td>
															<td>
																<input
																	id='taxi22'
																	type='checkbox'
																	checked={playerIsTaxi22}
																	onChange={togglePlayerIsTaxiCheckbox22}
																/>
															</td>
															<td>
																<input
																	id='active22'
																	type='checkbox'
																	checked={playerIsActive22}
																	onChange={togglePlayerIsActiveCheckbox22}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName23'
																	type='text'
																	defaultValue={originalName23}
																	onChange={getName23}
																/>
															</td>
															<td>
																<input
																	id='playerNumber23'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber23}
																	onChange={getNumber23}
																/>
															</td>
															<td>
																<input
																	id='playerBY23'
																	type='text'
																	size='4'
																	defaultValue={originalBY23}
																	onChange={getBY23}
																/>
															</td>
															<td>
																<input
																	id='goalie23'
																	type='checkbox'
																	checked={playerIsGoalie23}
																	onChange={togglePlayerIsGoalieCheckbox23}
																/>
															</td>
															<td>
																<input
																	id='taxi23'
																	type='checkbox'
																	checked={playerIsTaxi23}
																	onChange={togglePlayerIsTaxiCheckbox23}
																/>
															</td>
															<td>
																<input
																	id='active23'
																	type='checkbox'
																	checked={playerIsActive23}
																	onChange={togglePlayerIsActiveCheckbox23}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName24'
																	type='text'
																	defaultValue={originalName24}
																	onChange={getName24}
																/>
															</td>
															<td>
																<input
																	id='playerNumber24'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber24}
																	onChange={getNumber24}
																/>
															</td>
															<td>
																<input
																	id='playerBY24'
																	type='text'
																	size='4'
																	defaultValue={originalBY24}
																	onChange={getBY24}
																/>
															</td>
															<td>
																<input
																	id='goalie24'
																	type='checkbox'
																	checked={playerIsGoalie24}
																	onChange={togglePlayerIsGoalieCheckbox24}
																/>
															</td>
															<td>
																<input
																	id='taxi24'
																	type='checkbox'
																	checked={playerIsTaxi24}
																	onChange={togglePlayerIsTaxiCheckbox24}
																/>
															</td>
															<td>
																<input
																	id='active24'
																	type='checkbox'
																	checked={playerIsActive24}
																	onChange={togglePlayerIsActiveCheckbox24}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName25'
																	type='text'
																	defaultValue={originalName25}
																	onChange={getName25}
																/>
															</td>
															<td>
																<input
																	id='playerNumber25'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber25}
																	onChange={getNumber25}
																/>
															</td>
															<td>
																<input
																	id='playerBY25'
																	type='text'
																	size='4'
																	defaultValue={originalBY25}
																	onChange={getBY25}
																/>
															</td>
															<td>
																<input
																	id='goalie25'
																	type='checkbox'
																	checked={playerIsGoalie25}
																	onChange={togglePlayerIsGoalieCheckbox25}
																/>
															</td>
															<td>
																<input
																	id='taxi25'
																	type='checkbox'
																	checked={playerIsTaxi25}
																	onChange={togglePlayerIsTaxiCheckbox25}
																/>
															</td>
															<td>
																<input
																	id='active25'
																	type='checkbox'
																	checked={playerIsActive25}
																	onChange={togglePlayerIsActiveCheckbox25}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName26'
																	type='text'
																	defaultValue={originalName26}
																	onChange={getName26}
																/>
															</td>
															<td>
																<input
																	id='playerNumber26'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber26}
																	onChange={getNumber26}
																/>
															</td>
															<td>
																<input
																	id='playerBY26'
																	type='text'
																	size='4'
																	defaultValue={originalBY26}
																	onChange={getBY26}
																/>
															</td>
															<td>
																<input
																	id='goalie26'
																	type='checkbox'
																	checked={playerIsGoalie26}
																	onChange={togglePlayerIsGoalieCheckbox26}
																/>
															</td>
															<td>
																<input
																	id='taxi26'
																	type='checkbox'
																	checked={playerIsTaxi26}
																	onChange={togglePlayerIsTaxiCheckbox26}
																/>
															</td>
															<td>
																<input
																	id='active26'
																	type='checkbox'
																	checked={playerIsActive26}
																	onChange={togglePlayerIsActiveCheckbox26}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName27'
																	type='text'
																	defaultValue={originalName27}
																	onChange={getName27}
																/>
															</td>
															<td>
																<input
																	id='playerNumber27'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber27}
																	onChange={getNumber27}
																/>
															</td>
															<td>
																<input
																	id='playerBY27'
																	type='text'
																	size='4'
																	defaultValue={originalBY27}
																	onChange={getBY27}
																/>
															</td>
															<td>
																<input
																	id='goalie27'
																	type='checkbox'
																	checked={playerIsGoalie27}
																	onChange={togglePlayerIsGoalieCheckbox27}
																/>
															</td>
															<td>
																<input
																	id='taxi27'
																	type='checkbox'
																	checked={playerIsTaxi27}
																	onChange={togglePlayerIsTaxiCheckbox27}
																/>
															</td>
															<td>
																<input
																	id='active27'
																	type='checkbox'
																	checked={playerIsActive27}
																	onChange={togglePlayerIsActiveCheckbox27}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName28'
																	type='text'
																	defaultValue={originalName28}
																	onChange={getName28}
																/>
															</td>
															<td>
																<input
																	id='playerNumber28'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber28}
																	onChange={getNumber28}
																/>
															</td>
															<td>
																<input
																	id='playerBY28'
																	type='text'
																	size='4'
																	defaultValue={originalBY28}
																	onChange={getBY28}
																/>
															</td>
															<td>
																<input
																	id='goalie28'
																	type='checkbox'
																	checked={playerIsGoalie28}
																	onChange={togglePlayerIsGoalieCheckbox28}
																/>
															</td>
															<td>
																<input
																	id='taxi28'
																	type='checkbox'
																	checked={playerIsTaxi28}
																	onChange={togglePlayerIsTaxiCheckbox28}
																/>
															</td>
															<td>
																<input
																	id='active28'
																	type='checkbox'
																	checked={playerIsActive28}
																	onChange={togglePlayerIsActiveCheckbox28}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName29'
																	type='text'
																	defaultValue={originalName29}
																	onChange={getName29}
																/>
															</td>
															<td>
																<input
																	id='playerNumber29'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber29}
																	onChange={getNumber29}
																/>
															</td>
															<td>
																<input
																	id='playerBY29'
																	type='text'
																	size='4'
																	defaultValue={originalBY29}
																	onChange={getBY29}
																/>
															</td>
															<td>
																<input
																	id='goalie29'
																	type='checkbox'
																	checked={playerIsGoalie29}
																	onChange={togglePlayerIsGoalieCheckbox29}
																/>
															</td>
															<td>
																<input
																	id='taxi29'
																	type='checkbox'
																	checked={playerIsTaxi29}
																	onChange={togglePlayerIsTaxiCheckbox29}
																/>
															</td>
															<td>
																<input
																	id='active29'
																	type='checkbox'
																	checked={playerIsActive29}
																	onChange={togglePlayerIsActiveCheckbox29}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName30'
																	type='text'
																	defaultValue={originalName30}
																	onChange={getName30}
																/>
															</td>
															<td>
																<input
																	id='playerNumber30'
																	type='text'
																	size='3'
																	min='0'
																	max='99'
																	defaultValue={originalNumber30}
																	onChange={getNumber30}
																/>
															</td>
															<td>
																<input
																	id='playerBY30'
																	type='text'
																	size='4'
																	defaultValue={originalBY30}
																	onChange={getBY30}
																/>
															</td>
															<td>
																<input
																	id='goalie30'
																	type='checkbox'
																	checked={playerIsGoalie30}
																	onChange={togglePlayerIsGoalieCheckbox30}
																/>
															</td>
															<td>
																<input
																	id='taxi30'
																	type='checkbox'
																	checked={playerIsTaxi30}
																	onChange={togglePlayerIsTaxiCheckbox30}
																/>
															</td>
															<td>
																<input
																	id='active30'
																	type='checkbox'
																	checked={playerIsActive30}
																	onChange={togglePlayerIsActiveCheckbox30}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									{/* <div className='row'>
									<div className='col-lg-6 col-lg-3'>
										<div
											className='form-group fg-line net'
											style={{ marginBottom: '2rem' }}
										>
											<label htmlFor='txtDate' style={{ fontWeight: 'bold' }}>
												Todays Date: <span className='c-red'>*</span>
											</label>
											<input
												name='ctl00$cphBody$txtCity'
												type='date'
												id='txtDate'
												tabIndex='10'
												className='rform-control'
												placeholder='Date'
												defaultValue={originalDate}
												onChange={getDate}
												required
											/>
										</div>
									</div>
								</div> */}
									<Button type='submit'>UPDATE</Button>
								</form>
							</div>
						</div>
						{/* )} */}
					</main>
				)}
			</div>
			<Footer />
		</React.Fragment>
	)
}

export default TeamRegistrationUpdate
