import React from 'react'
import StatsAndStandingsHomeNavigation from './StatsAndStandingsHomeNavigation'
//import SelectLeague from '../UIElements/SelectLeague'
//import RosterAndSchedulePage from '../../../players/components/RosterAndSchedulePage'

const StatsAndStandingsHome = () => {
	return (
		<div>
			<StatsAndStandingsHomeNavigation />
			{/* <RosterAndSchedulePage /> */}
			{/* <SelectLeague /> */}
			{/* I just want to go to the RosterAndSchedulePage from here */}
		</div>
	)
}

export default StatsAndStandingsHome
