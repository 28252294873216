//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ModalEmail from '../../shared/components/UIElements/ModalEmail'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminArchivedSuspensions = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedSuspensions, setLoadedSuspensions] = useState()
	const [year, setYear] = useState()
	const [rosterModal, setRosterModal] = useState()
	const [filteredLoadedTeams, setFilteredLoadedTeams] = useState()
	const [teamName, setTeamName] = useState()
	const [dateOfLastEdit, setDateOfLastEdit] = useState()
	const [showConfirmMoveToCurrentModal, setShowConfirmMoveToCurrentModal] =
		useState(false)
	const [suspensionId, setSuspensionId] = useState()
	const { sendRequest, clearError } = useHttpClient()
	const [suspensionToCurrent, setSuspensionToCurrent] = useState()
	//
	//
	//First, lets tap into the following route on the backend to get all
	//the ARCHIVED suspensions.     registration-controller     getArchivedSuspensions
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/registration/allArchivedSuspensions`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedSuspensions(responseData.archivedSuspensions)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setSuspensionToCurrent(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [suspensionToCurrent])
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//******************************************************** */
	//
	//     'Move to current' warning
	//
	//******************************************************** */
	const showMoveToCurrentHandler = (e) => {
		const value = e.target.id
		console.log(loadedSuspensions[value]._id)
		setSuspensionId(loadedSuspensions[value]._id)
		setShowConfirmMoveToCurrentModal(true)
	}

	const cancelMoveToCurrentHandler = () => {
		setShowConfirmMoveToCurrentModal(false)
	}

	const confirmMoveToCurrentHandler = async () => {
		setShowConfirmMoveToCurrentModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${suspensionId}/archiveCurrentToggleSuspension`,
				'PATCH'
			)
		} catch (err) {}
		setSuspensionToCurrent(true)
	}
	//
	//
	//
	//
	//
	//
	return (
		<React.Fragment>
			<Modal
				show={showConfirmMoveToCurrentModal}
				onCancel={cancelMoveToCurrentHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to move this suspension to Current status?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelMoveToCurrentHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmMoveToCurrentHandler}
						>
							MOVE TO CURRENT
						</button>
					</React.Fragment>
				}
			></Modal>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !loadedSuspensions && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>No Archived Suspensions!</h1>
				</div>
			)}
			{!isLoading && loadedSuspensions && (
				<div className='RegLeagues' style={{ marginBottom: '5rem' }}>
					<AdminNavigation />
					<h1>Archived Suspensions</h1>
					<div style={{ marginBottom: '1.5rem' }}>
						{!isLoading && loadedSuspensions && (
							<div className='row'>
								<div className='col'></div>
								<div className='col-sm'></div>
								<div className='col'></div>
							</div>
						)}
					</div>
					<table id='regLeagues'>
						<thead>
							<tr>
								<th>Date</th>
								<th>Player</th>
								<th>Team</th>
								<th>Reason</th>
								<th># of games</th>
								{/* <th>Served?</th>
								<th>Appealed Successfully?</th> */}
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedSuspensions &&
								loadedSuspensions.map((suspension, key) => {
									return (
										<tr key={key}>
											<td>{suspension.date}</td>
											<td>{suspension.playerName}</td>
											<td>{suspension.playerTeam}</td>
											<td>{suspension.reason}</td>
											<td>{suspension.howManyGames}</td>
											{/* <td>{suspension.served}</td>
											<td>{suspension.appealedSuccessfully}</td> */}
											<td>
												{/* <NavLink
													style={{
														backgroundColor: '#92562e',
														border: '1px solid #92562e',
														borderRadius: '4px',
														color: 'white',
														padding: '0.5rem 1.5rem',
													}}
													className='buttonButton button2'
													id={key}
													onClick={(e) => showMoveToCurrentHandler(e)}
												>
													Move To Current
												</NavLink> */}
												<NavLink
													className='navlinks'
													id={key}
													onClick={(e) => showMoveToCurrentHandler(e)}
												>
													Move to Current
												</NavLink>
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminArchivedSuspensions
