//We'll use this page for a coach to come in and register their team.
import React, { useState, useEffect, useContext } from 'react'
import Card from '../../shared/components/UIElements/Card'
import './Register.css'
import Button from '../../shared/components/UIElements/Button'
import {
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { AuthContext } from '../../shared/context/auth-context'
//import { CoachAuthContext } from '../../shared/context/coach-auth-context'
import Input from '../../shared/components/FormElements/Input'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import PasswordErrorModal from '../../shared/components/UIElements/PasswordErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import Footer from '../../shared/components/UIElements/Footer'

const Register = () => {
	const [type, setType] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [card, setCard] = useState()
	const [allTeamsForThisCoach, setAllTeamsForThisCoach] = useState()
	//const coachAuth = useContext(CoachAuthContext)
	const auth = useContext(AuthContext)
	//
	//
	//I had to do the signup form variables this way instead of useState,
	// I think because there's no useEffect in this component.  Thats all I
	// can think of at least.  I couldnt get it working to save my life, but
	// its working THIS way.  So...whatever
	//
	let signUpEmail, signUpPassword, signUpPassword2
	//
	const getSignUpEmail = (event) => {
		signUpEmail = event.target.value
	}
	const getSignUpPassword = (event) => {
		signUpPassword = event.target.value
	}
	const getSignUpPassword2 = (event) => {
		signUpPassword2 = event.target.value
	}

	const [formState, inputHandler] = useForm(
		{
			email: {
				value: '',
				isValid: false,
			},
			password: {
				value: '',
				isValid: false,
			},
		},
		false
	)
	//
	//
	// This is for signing up. user doesnt have an account yet
	//
	//
	//
	const authSubmitSignupHandler = async (event) => {
		event.preventDefault()

		let lowerCase = /[a-z]/g
		let upperCase = /[A-Z]/g
		let numbers = /[0-9]/g

		if (signUpPassword !== signUpPassword2) {
			setError('Passwords dont match!')
		} else if (signUpPassword.length < 8) {
			setError(
				'Password must be at least 8 charachters long, should contain uppercase and lowercase letters, and at least ONE number!'
			)
		} else if (!signUpPassword.match(lowerCase)) {
			setError(
				'Password must be at least 8 charachters long, should contain uppercase and lowercase letters, and at least ONE number!'
			)
		} else if (!signUpPassword.match(upperCase)) {
			setError(
				'Password must be at least 8 charachters long, should contain uppercase and lowercase letters, and at least ONE number!'
			)
		} else if (!signUpPassword.match(numbers)) {
			setError(
				'Password must be at least 8 charachters long, should contain uppercase and lowercase letters, and at least ONE number!'
			)
		} else
			try {
				setIsLoading(true)
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/coachSignup',
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							signUpEmail,
							signUpPassword,
						}),
					}
				)
				const responseData = await response.json()
				if (!response.ok) {
					//console.log('response: ' + response)
					throw new Error(responseData.message)
				}
				setIsLoading(false)
				const coachId = responseData.coachId
				//console.log('lets send coachId to coachLogin: ' + responseData.coachId)
				auth.coachLogin(responseData.token)
				auth.setCoachId(coachId)
				//console.log('coachId: ' + responseData.coachId)
				navigate(`/teamRegistration/${coachId}`)
			} catch (err) {
				setIsLoading(false)
				setError(err.message || 'Something went wrong with creating an account')
			}
	}
	//
	//
	// This is for logging in.  Assume they already have an account
	//
	//
	//
	const authSubmitHandler = async (event) => {
		//event.preventDefault()
		try {
			setIsLoading(true)
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/admin/coachLogin',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: formState.inputs.email.value,
						password: formState.inputs.password.value,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			const coachId = responseData.coachId
			//console.log('lets send coachId to coachLogin: ' + responseData.coachId)
			auth.coachLogin(responseData.token)
			auth.setCoachId(coachId)
			navigate(`/teamRegistration/${coachId}`)
		} catch (err) {
			setIsLoading(false)
			setError(err.message || 'Something went wrong with logging in')
		}
	}
	//
	//
	//
	let navigate = useNavigate()
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	const signupHandler = () => {
		console.log('no account, creating a new one!')
		//window.location.reload(true)
		setCard(signupCard)
	}
	//
	const loginHandler = () => {
		console.log('account exists, lets just log in')
		window.location.reload(true)
		setCard(loginCard)
	}
	//
	//
	//This is the Login card.  It will be used by coaches that already have an account
	// and maybe want to go in and edit their roster.
	//
	let loginCard
	loginCard = []
	loginCard.push(
		<div>
			<Card className='authentication' style={{ marginTop: '2rem' }}>
				<h2 style={{ marginTop: '1rem' }}>Login</h2>
				<hr />
				<form id='loginForm' onSubmit={authSubmitHandler}>
					<Input
						element='input'
						id='email'
						type='email'
						label='E-mail'
						validators={[VALIDATOR_EMAIL()]}
						errorText='Please enter a valid email address.'
						//onChange={getLoginEmail}
						onInput={inputHandler}
					/>
					<Input
						element='input'
						id='password'
						type='password'
						label='Password'
						validators={[VALIDATOR_MINLENGTH(8)]}
						errorText='Please enter a valid password.'
						//onChange={getLoginPassword}
						onInput={inputHandler}
					/>
					{/* <Button type='submit' disabled={!formState.isValid}> */}
					<Button type='submit'>Login</Button>
					<p style={{ marginTop: '1rem' }}>
						Not registered?{' '}
						<a onClick={signupHandler} className='loginLink'>
							Create an account.
						</a>
					</p>
				</form>
			</Card>
		</div>
	)
	//
	//
	//This is the signup card.  It will be used initially by coaches to create an account.
	//
	//
	let signupCard
	signupCard = []
	signupCard.push(
		<div>
			<Card className='authentication' style={{ marginTop: '2rem' }}>
				<h2 style={{ marginTop: '1rem' }}>Sign Up</h2>
				<hr />
				<form id='signupForm' onSubmit={authSubmitSignupHandler}>
					<div className='form-control'>
						<label>Email:</label>
						<input
							element='input'
							id='email'
							type='email'
							validators={[VALIDATOR_EMAIL()]}
							errorText='Please enter a valid email address.'
							onChange={getSignUpEmail}
						/>
					</div>
					<div className='form-control'>
						<label>Password</label>
						<input
							element='input'
							id='password'
							type='password'
							validators={[VALIDATOR_MINLENGTH(8)]}
							errorText='Please enter a valid password.'
							onChange={getSignUpPassword}
						/>
					</div>
					<div className='form-control'>
						<label>Re-Enter Password</label>
						<input
							element='input'
							id='password2'
							type='password'
							validators={[VALIDATOR_MINLENGTH(8)]}
							errorText='Please enter a valid password.'
							onChange={getSignUpPassword2}
						/>
					</div>
					<Button type='submit'>Sign Up</Button>
					<p style={{ marginTop: '1rem' }}>
						Already registered?{' '}
						<a onClick={loginHandler} className='loginLink'>
							Login.
						</a>
					</p>
				</form>
			</Card>
		</div>
	)

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<PasswordErrorModal error={error} onClear={errorHandler} />
				<div>
					{isLoading && <LoadingSpinner asOverlay />}
					{!isLoading && (
						<main>
							<h2>Register Your Team</h2>
							<h5 style={{ marginLeft: '1rem', marginRight: '1rem' }}>
								Coaches: use this page to log in (or create an account) and
								submit your team roster.
							</h5>
							{(card && card) || loginCard}
						</main>
					)}
				</div>
				<div style={{ marginTop: '7rem' }}>
					<Footer />
				</div>
			</React.Fragment>
		</div>
	)
}

export default Register
