import React, { useState, useEffect } from 'react'
import StatsAndStandingsHomeNavigation from '../../shared/components/Navigation/StatsAndStandingsHomeNavigation'
import SelectLeague from '../../shared/components/UIElements/SelectPreviousTeam'
import { useHttpClient } from '../../shared/hooks/http-hook'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import './PreviousLeagues.css'
import { Link, NavLink } from 'react-router-dom'

const PreviousLeagues = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [leagueId, setLeagueId] = useState('')
	const [loadedPreviousLeagues, setLoadedPreviousLeagues] = useState()

	//Make a call to the backend to return all previous (archived) leagues
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/league/previousLeagues'
				)
				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedPreviousLeagues(responseData.previousLeagues)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	console.log('loadedPreviousLeagues: ' + JSON.stringify(loadedPreviousLeagues))
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	const handleClick = (e) => {
		//e.preventDefault()
		console.log('e: ' + e)
	}
	//
	//
	//
	return (
		<div>
			<ErrorModal error={error} onClear={errorHandler} />
			<SelectLeague />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !loadedPreviousLeagues && (
				<div className='Leagues'>
					<h1>No Previous Leagues Found!</h1>
				</div>
			)}
			{!isLoading && loadedPreviousLeagues && (
				<div className='Leagues'>
					<h1>Previous Leagues</h1>
					<table id='leagues'>
						<thead>
							<tr>
								<th>Year</th>
								<th>League Name</th>
								<th>Session</th>
							</tr>
						</thead>
						<tbody>
							{loadedPreviousLeagues.map((league, key) => {
								return (
									<tr key={key}>
										<td>{league.year}</td>
										<td>
											<Link
												to={`/league/${league._id}`}
												style={{
													color: 'black',
													fontWeight: 600,
													textDecoration: 'none',
												}}
											>
												{league.leagueName}{' '}
												{league.divisionName &&
													' - ' + league.divisionName + ' Division'}
											</Link>
										</td>
										<td>{league.session}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
			<StatsAndStandingsHomeNavigation leagueId={leagueId} />
		</div>
	)
}

export default PreviousLeagues
