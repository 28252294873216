import React, { useState, useEffect } from 'react'
import './AdminRegistration.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate, useParams } from 'react-router-dom'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'

const AdminUpdateSuspension = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [playerName, setPlayerName] = useState()
	const [playerTeam, setPlayerTeam] = useState()
	const [reason, setReason] = useState()
	const [howManyGames, setHowManyGames] = useState()
	const [served, setServed] = useState()
	const [appealedSuccessfully, setAppealedSuccessfully] = useState()
	const [error, setError] = useState()
	const suspensionId = useParams().suspensionId

	let navigate = useNavigate()

	//This is where we call the backend to get the suspension data to
	//auto-populate the form
	useEffect(() => {
		const fetchSuspensionData = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/suspension/${suspensionId}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setPlayerName(responseData.suspension.playerName)
				setPlayerTeam(responseData.suspension.playerTeam)
				setReason(responseData.suspension.reason)
				setHowManyGames(responseData.suspension.howManyGames)
				setServed(responseData.suspension.served)
				setAppealedSuccessfully(responseData.suspension.appealedSuccessfully)

				setIsLoading(false)
			} catch (err) {}
		}
		fetchSuspensionData()
	}, [suspensionId])
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	let playername
	const handlePlayerName = (e) => {
		playername = e.target.value
		setPlayerName(playername)
	}
	let playerteam
	const handlePlayerTeam = (e) => {
		playerteam = e.target.value
		setPlayerTeam(playerteam)
	}
	let reasonn
	const handleReason = (e) => {
		reasonn = e.target.value
		setReason(reasonn)
	}
	let howmanygames
	const handleHowManyGames = (e) => {
		howmanygames = e.target.value
		setHowManyGames(howmanygames)
	}

	const handleServed = (event) => {
		setServed(event.target.checked)
	}
	const handleAppealed = (event) => {
		setAppealedSuccessfully(event.target.checked)
	}
	//
	//
	//
	//
	//
	//
	//this goes to admin-controller, updateSuspension
	const suspensionSubmitHandler = async (event) => {
		event.preventDefault()
		try {
			setIsLoading(true)

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${suspensionId}/updateSuspension`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						playerName,
						playerTeam,
						reason,
						howManyGames,
						served,
						appealedSuccessfully,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			navigate('/admin/suspended')
		} catch (err) {
			setIsLoading(false)
			setError(
				err.message || 'Something went wrong with editing this suspension'
			)
		}
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && <LoadingSpinner asOverlay />}
			{!isLoading && (
				<div className='AddGames' style={{ backgroundColor: '#e0e0e0' }}>
					<AdminNavigation />
					<h1>Update Suspension</h1>
					<form className='suspension-form' onSubmit={suspensionSubmitHandler}>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Player Name:
							</td>
							<td>
								<input
									id='playerName'
									type='text'
									label='Player Name'
									defaultValue={playerName && playerName}
									onChange={handlePlayerName}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Player Team:
							</td>
							<td>
								<input
									id='playerTeam'
									type='text'
									label='Player Team'
									defaultValue={playerTeam && playerTeam}
									onChange={handlePlayerTeam}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Reason:
							</td>
							<td>
								<input
									id='playerTeam'
									type='text'
									label='Reason'
									defaultValue={reason && reason}
									onChange={handleReason}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Number of games:
							</td>
							<td>
								<input
									type='number'
									defaultValue={howManyGames && howManyGames}
									onChange={handleHowManyGames}
								></input>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Served?
							</td>
							<td>
								<input
									type='checkbox'
									name='served'
									onChange={handleServed}
									checked={served}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Appealed Successfully?
							</td>
							<td>
								<input
									type='checkbox'
									name='appealed'
									onChange={handleAppealed}
									checked={appealedSuccessfully}
								/>
							</td>
						</tr>
						<AdminButton type='button' onClick={suspensionSubmitHandler}>
							UPDATE
						</AdminButton>
					</form>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminUpdateSuspension
