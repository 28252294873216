import React from 'react'
import TeamRegistrationHomeNavigation from './TeamRegistrationHomeNavigation'
import TeamRegistration from '../../schedule/TeamRegistration'

const TeamRegistrationHome = () => {
	return (
		<div>
			<TeamRegistrationHomeNavigation />
			<TeamRegistration />
		</div>
	)
}

export default TeamRegistrationHome
