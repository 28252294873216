import React from 'react'
import AdminNavigation from '../components/AdminNavigation'
import '../components/AdminHeader.css'
import './AdminPage.css'
import AdminCurrentLeagueList from '../components/AdminCurrentLeagueList'

const AdminPage = () => {
	return (
		<React.Fragment>
			<AdminNavigation />
			<AdminCurrentLeagueList />
		</React.Fragment>
	)
}

export default AdminPage
