const VALIDATOR_TYPE_REQUIRE = 'REQUIRE'
const VALIDATOR_TYPE_NOT_REQUIRE = 'NOT_REQUIRE'
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH'
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH'
const VALIDATOR_TYPE_MIN = 'MIN' //numeric
const VALIDATOR_TYPE_MAX = 'MAX' //numeric
const VALIDATOR_TYPE_EMAIL = 'EMAIL'
const VALIDATOR_TYPE_FILE = 'FILE'

//These straight-forward functions; some of them take an input, some
//of them dont.   Returns a validator description: an object with a type
//of one of the identifiers above.  The functions that DO take an input
//are the validators that require extra configuration, for example, the MINLENGTH
//validator needs to know which min length a string should have and that's
//then stored in the val property.  So in the end, these functions return validator
//configuration objects, you can say, where every object at least has a type
//and maybe also has a val
export const VALIDATOR_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE })
export const VALIDATOR_NOT_REQUIRE = () => ({
	type: VALIDATOR_TYPE_NOT_REQUIRE,
})
export const VALIDATOR_FILE = () => ({ type: VALIDATOR_TYPE_FILE })
export const VALIDATOR_MINLENGTH = (val) => ({
	type: VALIDATOR_TYPE_MINLENGTH,
	val: val,
})
export const VALIDATOR_MAXLENGTH = (val) => ({
	type: VALIDATOR_TYPE_MAXLENGTH,
	val: val,
})
export const VALIDATOR_MIN = (val) => ({ type: VALIDATOR_TYPE_MIN, val: val })
export const VALIDATOR_MAX = (val) => ({ type: VALIDATOR_TYPE_MAX, val: val })
export const VALIDATOR_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL })

//Then we have the validate function as well.  And this function
//takes a value (the concrete value that the user entered into an
//input), and an array of validators, and it then goes through that
//array of validators and checks for the different types we are aware
//of and runs different validation login based on the type of validator
//we got.
//MATT: feel free to fine-tune or tweak this if needed going forward.
//NOTE:  this function below is imported into Input.js
export const validate = (value, validators) => {
	let isValid = true
	for (const validator of validators) {
		if (validator.type === VALIDATOR_TYPE_REQUIRE) {
			isValid = isValid && value.trim().length > 0
		}
		if (validator.type === VALIDATOR_TYPE_NOT_REQUIRE) {
			isValid = isValid && value.trim().length >= 0
		}
		if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
			isValid = isValid && value.trim().length >= validator.val
		}
		if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
			isValid = isValid && value.trim().length <= validator.val
		}
		if (validator.type === VALIDATOR_TYPE_MIN) {
			isValid = isValid && +value >= validator.val
		}
		if (validator.type === VALIDATOR_TYPE_MAX) {
			isValid = isValid && +value <= validator.val
		}
		if (validator.type === VALIDATOR_TYPE_EMAIL) {
			isValid = isValid && /^\S+@\S+\.\S+$/.test(value)
		}
	}
	return isValid
}
