//See a list of teams within the chosen league.
//TeamList is a component.  This here is a page that OUTPUTS that list.
//This page is very simple because we outsourced the rendering of
//the list into a separate component (TeamList) to keep things lean.
import React from 'react'
import Leaders from '../../leaders/pages/Leaders'
import Standings from '../../standings/pages/Standings'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import Footer from '../../shared/components/UIElements/Footer'

const Teams = () => {
	return (
		<div>
			{/* <StatsAndStandingsHome /> */}
			<Standings />
			<Leaders />
			<Footer />
		</div>
	)
}

export default Teams
