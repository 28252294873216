import React from 'react'
import RegisterHomeNavigation from './RegisterHomeNavigation'
import Register from './Register'

const RegisterHome = () => {
	return (
		<div>
			<RegisterHomeNavigation />
			<Register />
		</div>
	)
}

export default RegisterHome
