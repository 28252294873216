import React, { useState, useEffect } from 'react'
import '../games/AddGamesForm.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/UIElements/Button'
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_NOT_REQUIRE,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'

const AdminNewOrg = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [playerName, setPlayerName] = useState('')
	const [team, setTeam] = useState('')
	const [reason, setReason] = useState('')
	const [numberOfGames, setNumberOfGames] = useState()
	const [served, setServed] = useState()
	const [appealed, setAppealed] = useState()

	let navigate = useNavigate()
	//
	//
	//
	//
	const [formState, inputHandler] = useForm(
		{
			orgName: {
				value: '',
				isValid: false,
			},
			orgAddress: {
				value: '',
				isValid: false,
			},
		},
		false
	)
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	const getNumberOfGames = (event) => {
		setNumberOfGames(event.target.value)
	}
	const servedCheckbox = (e) => {
		setServed(true)
	}

	const appealedCheckbox = (e) => {
		setAppealed(true)
	}
	//
	//
	const orgSubmitHandler = async (event) => {
		event.preventDefault()

		try {
			setIsLoading(true)

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/admin/createOrg',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						orgName: formState.inputs.orgName.value,
						orgAddress: formState.inputs.orgAddress.value,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			navigate('/admin/orgs')
			//navigate('/admin/leagues/current')
		} catch (err) {
			setIsLoading(false)
			setError(
				err.message || 'Something went wrong with creating a new organization'
			)
		}
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && <LoadingSpinner asOverlay />}
			{!isLoading && (
				<div className='AddEvents'>
					<AdminNavigation />
					<h1>New Organization</h1>
					<form className='suspension-form' onSubmit={orgSubmitHandler}>
						<Input
							id='orgName'
							element='input'
							type='text'
							label='Organization Name'
							validators={[VALIDATOR_REQUIRE()]}
							errorText='Please enter a name for the organization'
							onInput={inputHandler}
						/>
						<Input
							id='orgAddress'
							element='input'
							type='text'
							label='Address'
							validators={[VALIDATOR_NOT_REQUIRE()]}
							errorText='Please enter an address'
							onInput={inputHandler}
						/>
						<Button type='submit' disabled={!formState.isValid}>
							ADD ORGANIZATION
						</Button>
					</form>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminNewOrg
