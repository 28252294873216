//This is the ADMIN view of all scheduled games and events

import React, { useEffect, useState } from 'react'
import './AdminGameResults.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
//import Modal from '../../shared/components/UIElements/Modal'
import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
//import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminGameResults = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [homeRoster, setHomeRoster] = useState()
	const [homeTeamName, setHomeTeamName] = useState()
	const [opponent, setOpponent] = useState()
	const [visitorRoster, setVisitorRoster] = useState()
	const [visitorTeamName, setVisitorTeamName] = useState()
	const [rosterPlayerGameStats, setRosterPlayerGameStats] = useState()
	const [gameStats, setGameStats] = useState([])
	const [dayOfWeek, setDayOfWeek] = useState()
	const [date, setDate] = useState()
	const [time, setTime] = useState()
	const [venue, setVenue] = useState()
	const [leagueName, setLeagueName] = useState()
	const gameId = useParams().gameId

	//Here, I want to go to the backend, using the gameId, and get the roster lists
	//of both the home team and the visiting team.   getGameRostersAndPointsPerPeriod in admin-controller.js
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/${gameId}/rostersAndPoints`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setHomeRoster(responseData.homeRoster)
				setHomeTeamName(responseData.teamName)
				setOpponent(responseData.opponent)
				setRosterPlayerGameStats(responseData.rosterPlayerGameStats)
				setGameStats(responseData.gameStats)
				setDayOfWeek(responseData.dayOfWeek)
				setDate(responseData.date)
				setTime(responseData.time)
				setVenue(responseData.venue)
				setIsLoading(false)
			} catch (err) {
				setError(err)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [gameId])

	homeRoster && console.log('homeRoster: ' + homeRoster)

	let homePoints1, homePoints2, homePoints3, homePoints4, homePointsTotal
	let visitorPoints1,
		visitorPoints2,
		visitorPoints3,
		visitorPoints4,
		visitorPointsTotal,
		status,
		summary
	if (gameStats) {
		if (gameStats.length > 0) {
			console.log('WE HAVE GAME STATS')
			console.log('gameStats: ' + JSON.stringify(gameStats))
			homePoints1 = gameStats[0].homeGoalsPeriod1
			homePoints2 = gameStats[0].homeGoalsPeriod2
			homePoints3 = gameStats[0].homeGoalsPeriod3
			homePoints4 = gameStats[0].homeGoalsPeriod4
			homePointsTotal = gameStats[0].homeGoalsTotal
			visitorPoints1 = gameStats[0].visitorGoalsPeriod1
			visitorPoints2 = gameStats[0].visitorGoalsPeriod2
			visitorPoints3 = gameStats[0].visitorGoalsPeriod3
			visitorPoints4 = gameStats[0].visitorGoalsPeriod4
			visitorPointsTotal = gameStats[0].visitorGoalsTotal
			status = gameStats[0].status
			summary = gameStats[0].summary
			console.log('summary here: ' + summary)
			console.log('homeGoalsTotal here: ' + homePointsTotal)
		}
	} else {
		console.log('NO GAME STATS YET!!!')
	}
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	const returnGoals = (playerId) => {
		let gameGoals
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameGoals = rosterPlayer.goals
			}
		})
		return gameGoals
	}
	//
	//
	//
	const returnAssists = (playerId) => {
		let gameAssists
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameAssists = rosterPlayer.assists
			}
		})
		return gameAssists
	}
	//
	//
	let navigate = useNavigate()
	//Here, I want to tap into the backend and update stats for the game and rosterPlayers
	//Maybe...or maybe I want to do it another way...
	const logGameStats = async () => {
		//console.log('inside logGameStats')
		//
		const gameStatus = document.getElementById('gameStatus').value
		const gameSummary = document.getElementById('gameSummary').value
		//**************************************************** */
		//
		//  Game stats:
		//
		//***************************************************** */
		let homePointsPerPeriod, visitorPointsPerPeriod
		homePointsPerPeriod = []
		visitorPointsPerPeriod = []
		//
		//
		homePointsPerPeriod.push(
			document.getElementById('homeGoalsPeriod1').value +
				'|' +
				document.getElementById('homeGoalsPeriod2').value +
				'|' +
				document.getElementById('homeGoalsPeriod3').value +
				'|' +
				document.getElementById('homeGoalsPeriod4').value +
				'|' +
				document.getElementById('homeGoalsTotal').value
		)
		//
		visitorPointsPerPeriod.push(
			document.getElementById('visitorGoalsPeriod1').value +
				'|' +
				document.getElementById('visitorGoalsPeriod2').value +
				'|' +
				document.getElementById('visitorGoalsPeriod3').value +
				'|' +
				document.getElementById('visitorGoalsPeriod4').value +
				'|' +
				document.getElementById('visitorGoalsTotal').value
		)

		//console.log('homePointsPerPeriod: ' + homePointsPerPeriod)
		//console.log('visitorPointsPerPeriod: ' + visitorPointsPerPeriod)
		//**************************************************** */
		//
		//  Individual Player Stats:
		//
		//**************************************************** */

		let homeStats //visitorStats
		homeStats = []
		//visitorStats = []
		//
		homeRoster.forEach((rosterPlayer) => {
			homeStats.push(
				rosterPlayer._id +
					'|' +
					document.getElementById('goals' + rosterPlayer._id).value +
					'|' +
					document.getElementById('assists' + rosterPlayer._id).value
			)
		})

		//console.log('homeStats: ' + homeStats)

		try {
			//tapping into backend for adding player and game stats...
			// admin-controller    createGameStats
			setIsLoading(true)
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/admin/gameStats/${gameId}`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						homeStats,
						homePointsPerPeriod,
						visitorPointsPerPeriod,
						gameStatus,
						gameSummary,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			navigate('/admin/masterSchedule')
		} catch (err) {
			console.log('error: ' + err)
			setIsLoading(false)
			setError(err.message || 'Something went wrong with adding player stats')
		}
	}
	//
	//
	//
	//
	return (
		<React.Fragment>
			<AdminNavigation />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<span>
				<h1 style={{ marginTop: '5rem' }}>
					{homeTeamName} vs. {opponent}
				</h1>
				<p style={{ textAlign: 'center' }}>
					{dayOfWeek},{' '}
					{date && date.substr(0, 2) === '01' && 'Jan. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '02' && 'Feb. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '03' && 'Mar. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '04' && 'Apr. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '05' && 'May ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '06' && 'June ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '07' && 'July ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '08' && 'Aug. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '09' && 'Sept. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '10' && 'Oct. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '11' && 'Nov. ' + date.substr(3, 2)}
					{date &&
						date.substr(0, 2) === '12' &&
						'Dec. ' + date.substr(3, 2)},{' '}
					{time &&
						time.substr(0, 2) === '01' &&
						'1:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '02' &&
						'2:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '03' &&
						'3:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '04' &&
						'4:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '05' &&
						'5:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '06' &&
						'6:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '07' &&
						'7:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '08' &&
						'8:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '09' &&
						'9:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '10' &&
						'10:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '11' &&
						'11:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '12' &&
						'12:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '13' &&
						'1:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '14' &&
						'2:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '15' &&
						'3:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '16' &&
						'4:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '17' &&
						'5:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '18' &&
						'6:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '19' &&
						'7:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '20' &&
						'8:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '21' &&
						'9:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '22' &&
						'10:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '23' &&
						'11:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '24' &&
						'12:' + time.substr(3, 2) + ' am'}
					, {venue}
				</p>
			</span>
			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm gameStatus'>
					GAME STATUS:
					<select
						className='statusDropdown'
						id='gameStatus'
						type='number'
						min='0'
						max='99'
						required
					>
						<option defaultValue={status}>{status && status}</option>
						<option value='TBP'>TBP</option>
						<option value='FINAL'>FINAL</option>
						<option value='Overtime'>Overtime</option>
						<option value='Shootout'>Shootout</option>
					</select>
				</div>
				<div className='col-sm'></div>
			</div>

			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm gameSummary'>
					<label for='textarea'>GAME SUMMARY:</label>
					<textarea
						className='summaryTextArea'
						id='gameSummary'
						defaultValue={summary}
						placeholder='Good game?  Write something about it...'
						rows='2'
						cols='35'
					></textarea>
				</div>
				<div className='col-sm'></div>
			</div>

			<table
				className='AdminRoster'
				id='adminRoster'
				style={{ width: '30%', marginBottom: '0rem' }}
			>
				<thead>
					<tr>
						<th>Team</th>
						<th>Period 1</th>
						<th>2</th>
						<th>3</th>
						<th>OT/SO</th>
						<th>Final</th>
					</tr>
				</thead>
				<tbody>
					<tr style={{ height: '5px' }}>
						<td style={{ fontWeight: 'bold' }}>{homeTeamName}</td>
						<td>
							<input
								id='homeGoalsPeriod1'
								type='number'
								min='0'
								max='99'
								defaultValue={homePoints1}
							/>
						</td>
						<td>
							<input
								id='homeGoalsPeriod2'
								type='number'
								min='0'
								max='99'
								defaultValue={homePoints2}
							/>
						</td>
						<td>
							<input
								id='homeGoalsPeriod3'
								type='number'
								min='0'
								max='99'
								defaultValue={homePoints3}
							/>
						</td>
						<td>
							<input
								id='homeGoalsPeriod4'
								type='number'
								min='0'
								max='99'
								defaultValue={homePoints4}
							/>
						</td>
						<td>
							<input
								id='homeGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={homePointsTotal}
							/>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: 'bold' }}>{opponent}</td>
						<td>
							<input
								id='visitorGoalsPeriod1'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPoints1}
							/>
						</td>
						<td>
							<input
								id='visitorGoalsPeriod2'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPoints2}
							/>
						</td>
						<td>
							<input
								id='visitorGoalsPeriod3'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPoints3}
							/>
						</td>
						<td>
							<input
								id='visitorGoalsPeriod4'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPoints4}
							/>
						</td>
						<td>
							<input
								id='visitorGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPointsTotal}
							/>
						</td>
					</tr>
				</tbody>
			</table>

			<div className='row'>
				<div className='col' /* style={{ marginLeft: '5%' }} */>
					{!isLoading && homeRoster && (
						<div className='AdminRoster2'>
							<h1>{homeTeamName} - Player Stats</h1>
							<table id='adminRoster' style={{ width: '40%' }}>
								<thead>
									<tr>
										<th>#</th>
										<th>Player</th>
										<th>Goals</th>
										<th>Assists</th>
									</tr>
								</thead>
								<tbody>
									{homeRoster.map((player, key) => {
										return (
											<tr key={key}>
												{/* <td>{player.number}</td> */}
												<td>{player.number}</td>
												<td>
													{player.firstName}{' '}
													{player.middleInitial && player.middleInitial}{' '}
													{player.lastName}
												</td>
												<td>
													<input
														id={'goals' + player._id}
														type='number'
														defaultValue={returnGoals(player._id)}
														min='0'
														max='99'
													/>
												</td>
												<td>
													<input
														id={'assists' + player._id}
														type='number'
														defaultValue={returnAssists(player._id)}
														min='0'
														max='99'
													/>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
			<div
				style={{
					width: '7%',
					margin: 'auto',
					paddingTop: '3%',
					marginBottom: '3%',
				}}
			>
				<AdminButton onClick={logGameStats}>SAVE</AdminButton>
			</div>
		</React.Fragment>
	)
}

export default AdminGameResults
