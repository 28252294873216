import React, { useContext, useState } from 'react'
import './NavLinks.css'
import './Dropdown.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../shared/context/auth-context'

const NavLinks = (props) => {
	const auth = useContext(AuthContext)
	const [loggedOut, setLoggedOut] = useState(false)
	let navigate = useNavigate()
	//
	//
	const logoutStuff = () => {
		setLoggedOut(true)
	}

	if (loggedOut) {
		navigate('/admin/login')
		auth.logout()
	}
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//

	return (
		<ul className='nav-links'>
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/getAllCOCCoaches' exact>
						CODE OF CONDUCT
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/registration' exact>
						REGISTERED TEAMS
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/archived' exact>
						ARCHIVED TEAMS
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/orgs' exact>
						ORGANIZATIONS
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/suspended' exact>
						SUSPENDED PLAYERS
					</NavLink>
				</li>
			)}
			<li>
				<NavLink to='/' exact>
					HOME
				</NavLink>
			</li>
			<li>
				<button onClick={logoutStuff} style={{ border: 'none' }}>
					LOGOUT
				</button>
			</li>
			<li></li>
		</ul>
	)
}

export default NavLinks
