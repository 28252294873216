//This will be the dropdown item you see on the schedule page.  You can choose from
//either a league or a team to view a desired schedule
import React, { useState, useEffect } from 'react'
import './AdminSearchByVenue.css'
import { useNavigate } from 'react-router-dom'

const AdminSearchByLeague = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [loadedLeagues, setLoadedLeagues] = useState()
	const [error, setError] = useState()

	let selectedLeague

	let navigate = useNavigate()
	//
	//
	//
	//Call backend to get all the current Leagues for our dropdown
	// admin-controller       getCurrentLeagues
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/leagues/current'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error('error message: ' + responseData.message)
				}

				setLoadedLeagues(responseData.currentLeaguesSortedNoDuplicates)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedLeagues])

	let leagueArray

	//Here, we take in the loadedLeagues from the backend and make an array
	//of just leagueNames
	//
	if (loadedLeagues && !isLoading) {
		leagueArray = []
		loadedLeagues.forEach((league) => {
			leagueArray.push(league.leagueName)
		})
	}

	//For handling a search by League request
	const handleChange = (e) => {
		e.preventDefault()
		const selectedValue = e.target.value
		selectedLeague = loadedLeagues[selectedValue]
		const leagueId = selectedLeague._id
		navigate(`/schedule/admin/${leagueId}`)
	}

	return (
		<React.Fragment>
			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm instructions searchByLeague'>
					Search by League:{' '}
				</div>
				{!isLoading && loadedLeagues && (
					<div className='col-sm'>
						<form className='form-control-lg'>
							{/* matt:  wtf is custom-select?   was a className here but i deleted*/}
							<select
								className='my-1 mr-sm-2 scheduleselector'
								onChange={handleChange}
							>
								<option defaultValue>Select League....</option>
								{leagueArray.map((league, i) => (
									<option value={i} key={i}>
										{league}
									</option>
								))}
							</select>
						</form>
					</div>
				)}
				<div className='col-sm'></div>
			</div>
			{/* </div> */}
		</React.Fragment>
	)
}

export default AdminSearchByLeague
