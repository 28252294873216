//for sloths, this turned into PlayersList.  I dont believe were using this for this job
import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
//import { NavLink } from 'react-router-dom'
import AdminNavigation from './AdminNavigation'
import { useParams } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'

const AdminTeamList = () => {
	console.log('inside AdminTeamList')
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [playerId, setPlayerId] = useState()
	const [loadedPlayers, setLoadedPlayers] = useState()
	const [isCurrent, setIsCurrent] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [playerDeleted, setPlayerDeleted] = useState()
	const teamName = useParams().teamName
	const year = useParams().year

	//First, lets tap into the following route on the backend to get all teams in league:
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setPlayerDeleted(false)

			try {
				const response = await fetch(
					/* `${process.env.REACT_APP_BACKEND_URL}/admin/${leagueName}/${session}/${year}/teams` */
					`${process.env.REACT_APP_BACKEND_URL}/admin/${teamName}/${year}/roster`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedPlayers(responseData.listOfPlayers)
				setIsCurrent(responseData.isCurrent)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [teamName, setLoadedPlayers, year, playerDeleted])

	const errorHandler = () => {
		setError(null)
	}

	const showDeleteWarningHandler = (e) => {
		console.log('e: ' + e.target.id)
		const value = e.target.id
		console.log(
			'Player you wish to delete: ' + JSON.stringify(loadedPlayers[value])
		)
		//rosterPlayerId = loadedPlayers[value]._id
		setPlayerId(loadedPlayers[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('team right here: ' + teamId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		console.log('playerId: ' + playerId)
		try {
			await sendRequest(
				// process.env.REACT_APP_BACKEND_URL + `/admin/${teamId}/removeTeam`,
				process.env.REACT_APP_BACKEND_URL + `/admin/${playerId}/removePlayer`,
				'DELETE'
			)
		} catch (err) {}
		setPlayerDeleted(true)
		console.log('DELETING...')
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this player?
						</p>
						{/* <p className='modalText'>
							This will also delete all the rostered players from the team.
						</p> */}
						<br></br>
						<button
							class='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE PLAYER
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedPlayers && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>{teamName} Roster</h1>
					<h2>{year}</h2>
					{/* <h1>{divisionName && divisionName + ' ' + 'Division'}</h1>
					<h2>
						{session} {year}
					</h2> */}
					<table id='teams'>
						<thead>
							<tr>
								<th>Player Name</th>
								<th>Number</th>
								<th></th>
								{isCurrent && <th style={{ width: '25%' }}></th>}
								{isCurrent && <th></th>}
							</tr>
						</thead>
						<tbody>
							{loadedPlayers.map((player, key) => {
								return (
									<tr key={key}>
										<td>
											{player.firstName} {player.lastName}
										</td>
										<td>{player.number}</td>
										{isCurrent && (
											<td>
												<button
													className='buttonButton'
													style={{ color: 'red' }}
													id={key}
													onClick={(e) => showDeleteWarningHandler(e)}
												>
													DELETE
												</button>
											</td>
										)}
									</tr>
								)
							})}
						</tbody>
						{isCurrent && (
							<AdminButton
								className='add'
								style={{ width: '150%' }}
								to={`/admin/${teamName}/${year}/newPlayer`}
								exact
							>
								Add Player(s)
							</AdminButton>
						)}
					</table>
				</div>
			)}
			{!isLoading && !loadedPlayers && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>{teamName} Roster</h1>
					<h2>{year}</h2>
					<table id='teams'>
						<thead>
							<tr>
								<th>Player Name</th>
								<th>Number</th>
								<th></th>
								{isCurrent && <th></th>}
								{isCurrent && <th></th>}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>NO PLAYERS ON THIS TEAM YET</td>
							</tr>
						</tbody>
						{isCurrent && (
							<AdminButton
								className='add'
								style={{ width: '150%' }}
								to={`/admin/${teamName}/${year}/newPlayer`}
								exact
							>
								Add Player(s)
							</AdminButton>
						)}
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminTeamList
