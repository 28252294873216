import { createContext } from 'react'

//console.log('inside auth-context.js')

export const AuthContext = createContext({
	isLoggedIn: false,
	login: () => {},
	logout: () => {},
	coachIsLoggedIn: false,
	coachLogin: () => {},
	setCoachId: () => {},
	coachLogout: () => {},
})
