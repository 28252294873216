//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './../../admin/components/AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Footer from '../../shared/components/UIElements/Footer'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import Button from '../../shared/components/UIElements/Button'
import HomeHomeNavigation from '../../shared/components/Navigation/HomeHomeNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ModalEmail from '../../shared/components/UIElements/ModalEmail'

const NonAdminRegisteredTeams = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedTeams, setLoadedTeams] = useState()
	const [year, setYear] = useState()
	const [org, setOrg] = useState()
	const [emailModal, setEmailModal] = useState()
	const [rosterModal, setRosterModal] = useState()
	const [filteredLoadedOrgs, setFilteredLoadedOrgs] = useState()
	const [filteredLoadedTeams, setFilteredLoadedTeams] = useState()
	const [emailAddresses, setEmailAddresses] = useState()
	//const [filteredByYear, setFilteredByYear] = useState()
	const [teamName, setTeamName] = useState()
	const [dateOfLastEdit, setDateOfLastEdit] = useState()
	const [loadedOrgs, setLoadedOrgs] = useState()
	const [orgDropdownCreated, setOrgDropdownCreated] = useState(false)
	const [orgArray, setOrgArray] = useState([])
	//
	const [name1, setName1] = useState()
	const [number1, setNumber1] = useState()
	const [BY1, setBY1] = useState()
	const [isGoalie1, setIsGoalie1] = useState()
	const [isTaxi1, setIsTaxi1] = useState()
	//
	const [name2, setName2] = useState()
	const [number2, setNumber2] = useState()
	const [BY2, setBY2] = useState()
	const [isGoalie2, setIsGoalie2] = useState()
	const [isTaxi2, setIsTaxi2] = useState()
	//
	const [name3, setName3] = useState()
	const [number3, setNumber3] = useState()
	const [BY3, setBY3] = useState()
	const [isGoalie3, setIsGoalie3] = useState()
	const [isTaxi3, setIsTaxi3] = useState()
	//
	const [name4, setName4] = useState()
	const [number4, setNumber4] = useState()
	const [BY4, setBY4] = useState()
	const [isGoalie4, setIsGoalie4] = useState()
	const [isTaxi4, setIsTaxi4] = useState()
	//
	const [name5, setName5] = useState()
	const [number5, setNumber5] = useState()
	const [BY5, setBY5] = useState()
	const [isGoalie5, setIsGoalie5] = useState()
	const [isTaxi5, setIsTaxi5] = useState()
	//
	const [name6, setName6] = useState()
	const [number6, setNumber6] = useState()
	const [BY6, setBY6] = useState()
	const [isGoalie6, setIsGoalie6] = useState()
	const [isTaxi6, setIsTaxi6] = useState()
	//
	const [name7, setName7] = useState()
	const [number7, setNumber7] = useState()
	const [BY7, setBY7] = useState()
	const [isGoalie7, setIsGoalie7] = useState()
	const [isTaxi7, setIsTaxi7] = useState()
	//
	const [name8, setName8] = useState()
	const [number8, setNumber8] = useState()
	const [BY8, setBY8] = useState()
	const [isGoalie8, setIsGoalie8] = useState()
	const [isTaxi8, setIsTaxi8] = useState()
	//
	const [name9, setName9] = useState()
	const [number9, setNumber9] = useState()
	const [BY9, setBY9] = useState()
	const [isGoalie9, setIsGoalie9] = useState()
	const [isTaxi9, setIsTaxi9] = useState()
	//
	const [name10, setName10] = useState()
	const [number10, setNumber10] = useState()
	const [BY10, setBY10] = useState()
	const [isGoalie10, setIsGoalie10] = useState()
	const [isTaxi10, setIsTaxi10] = useState()
	//
	const [name11, setName11] = useState()
	const [number11, setNumber11] = useState()
	const [BY11, setBY11] = useState()
	const [isGoalie11, setIsGoalie11] = useState()
	const [isTaxi11, setIsTaxi11] = useState()
	//
	const [name12, setName12] = useState()
	const [number12, setNumber12] = useState()
	const [BY12, setBY12] = useState()
	const [isGoalie12, setIsGoalie12] = useState()
	const [isTaxi12, setIsTaxi12] = useState()
	//
	const [name13, setName13] = useState()
	const [number13, setNumber13] = useState()
	const [BY13, setBY13] = useState()
	const [isGoalie13, setIsGoalie13] = useState()
	const [isTaxi13, setIsTaxi13] = useState()
	//
	const [name14, setName14] = useState()
	const [number14, setNumber14] = useState()
	const [BY14, setBY14] = useState()
	const [isGoalie14, setIsGoalie14] = useState()
	const [isTaxi14, setIsTaxi14] = useState()
	//
	const [name15, setName15] = useState()
	const [number15, setNumber15] = useState()
	const [BY15, setBY15] = useState()
	const [isGoalie15, setIsGoalie15] = useState()
	const [isTaxi15, setIsTaxi15] = useState()
	//
	const [name16, setName16] = useState()
	const [number16, setNumber16] = useState()
	const [BY16, setBY16] = useState()
	const [isGoalie16, setIsGoalie16] = useState()
	const [isTaxi16, setIsTaxi16] = useState()
	//
	const [name17, setName17] = useState()
	const [number17, setNumber17] = useState()
	const [BY17, setBY17] = useState()
	const [isGoalie17, setIsGoalie17] = useState()
	const [isTaxi17, setIsTaxi17] = useState()
	//
	const [name18, setName18] = useState()
	const [number18, setNumber18] = useState()
	const [BY18, setBY18] = useState()
	const [isGoalie18, setIsGoalie18] = useState()
	const [isTaxi18, setIsTaxi18] = useState()
	//
	const [name19, setName19] = useState()
	const [number19, setNumber19] = useState()
	const [BY19, setBY19] = useState()
	const [isGoalie19, setIsGoalie19] = useState()
	const [isTaxi19, setIsTaxi19] = useState()
	//
	const [name20, setName20] = useState()
	const [number20, setNumber20] = useState()
	const [BY20, setBY20] = useState()
	const [isGoalie20, setIsGoalie20] = useState()
	const [isTaxi20, setIsTaxi20] = useState()
	//
	const [name21, setName21] = useState()
	const [number21, setNumber21] = useState()
	const [BY21, setBY21] = useState()
	const [isGoalie21, setIsGoalie21] = useState()
	const [isTaxi21, setIsTaxi21] = useState()
	//
	const [name22, setName22] = useState()
	const [number22, setNumber22] = useState()
	const [BY22, setBY22] = useState()
	const [isGoalie22, setIsGoalie22] = useState()
	const [isTaxi22, setIsTaxi22] = useState()
	//
	const [name23, setName23] = useState()
	const [number23, setNumber23] = useState()
	const [BY23, setBY23] = useState()
	const [isGoalie23, setIsGoalie23] = useState()
	const [isTaxi23, setIsTaxi23] = useState()
	//
	const [name24, setName24] = useState()
	const [number24, setNumber24] = useState()
	const [BY24, setBY24] = useState()
	const [isGoalie24, setIsGoalie24] = useState()
	const [isTaxi24, setIsTaxi24] = useState()
	//
	const [name25, setName25] = useState()
	const [number25, setNumber25] = useState()
	const [BY25, setBY25] = useState()
	const [isGoalie25, setIsGoalie25] = useState()
	const [isTaxi25, setIsTaxi25] = useState()
	//
	const [name26, setName26] = useState()
	const [number26, setNumber26] = useState()
	const [BY26, setBY26] = useState()
	const [isGoalie26, setIsGoalie26] = useState()
	const [isTaxi26, setIsTaxi26] = useState()
	//
	const [name27, setName27] = useState()
	const [number27, setNumber27] = useState()
	const [BY27, setBY27] = useState()
	const [isGoalie27, setIsGoalie27] = useState()
	const [isTaxi27, setIsTaxi27] = useState()
	//
	const [name28, setName28] = useState()
	const [number28, setNumber28] = useState()
	const [BY28, setBY28] = useState()
	const [isGoalie28, setIsGoalie28] = useState()
	const [isTaxi28, setIsTaxi28] = useState()
	//
	const [name29, setName29] = useState()
	const [number29, setNumber29] = useState()
	const [BY29, setBY29] = useState()
	const [isGoalie29, setIsGoalie29] = useState()
	const [isTaxi29, setIsTaxi29] = useState()
	//
	const [name30, setName30] = useState()
	const [number30, setNumber30] = useState()
	const [BY30, setBY30] = useState()
	const [isGoalie30, setIsGoalie30] = useState()
	const [isTaxi30, setIsTaxi30] = useState()
	//
	const [isActive1, setIsActive1] = useState()
	const [isActive2, setIsActive2] = useState()
	const [isActive3, setIsActive3] = useState()
	const [isActive4, setIsActive4] = useState()
	const [isActive5, setIsActive5] = useState()
	const [isActive6, setIsActive6] = useState()
	const [isActive7, setIsActive7] = useState()
	const [isActive8, setIsActive8] = useState()
	const [isActive9, setIsActive9] = useState()
	const [isActive10, setIsActive10] = useState()
	const [isActive11, setIsActive11] = useState()
	const [isActive12, setIsActive12] = useState()
	const [isActive13, setIsActive13] = useState()
	const [isActive14, setIsActive14] = useState()
	const [isActive15, setIsActive15] = useState()
	const [isActive16, setIsActive16] = useState()
	const [isActive17, setIsActive17] = useState()
	const [isActive18, setIsActive18] = useState()
	const [isActive19, setIsActive19] = useState()
	const [isActive20, setIsActive20] = useState()
	const [isActive21, setIsActive21] = useState()
	const [isActive22, setIsActive22] = useState()
	const [isActive23, setIsActive23] = useState()
	const [isActive24, setIsActive24] = useState()
	const [isActive25, setIsActive25] = useState()
	const [isActive26, setIsActive26] = useState()
	const [isActive27, setIsActive27] = useState()
	const [isActive28, setIsActive28] = useState()
	const [isActive29, setIsActive29] = useState()
	const [isActive30, setIsActive30] = useState()
	//
	//First, lets tap into the following route on the backend to get all
	//the CURRENT registered teams.  getAllRegisteredTeams
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/registration/allRegisteredTeams`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTeams(responseData.allRegisteredTeams)
				setEmailAddresses(responseData.allEmailAddresses)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//console.log('loadedTeams: ' + JSON.stringify(loadedTeams))
	//
	//
	let emailAddressesArray, uniqueEmailAddresses
	emailAddressesArray = []
	if (emailAddresses) {
		emailAddresses.forEach((emailAddress) => {
			emailAddressesArray.push(emailAddress)
		})
	}
	//
	//
	if (emailAddressesArray.length > 0) {
		uniqueEmailAddresses = emailAddressesArray.filter(
			(value, index) => emailAddressesArray.indexOf(value) === index
		)
	}
	//
	let totalActivePlayers = 0
	if (loadedTeams) {
		if (isActive1) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive2) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive3) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive4) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive5) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive6) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive7) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive8) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive9) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive10) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive11) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive12) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive13) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive14) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive15) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive16) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive17) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive18) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive19) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive20) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive21) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive22) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive23) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive24) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive25) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive26) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive27) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive28) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive29) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive30) {
			totalActivePlayers = totalActivePlayers + 1
		}
	}
	//
	//
	//Next, lets call the backend to get all the organizations for our dropdown
	//     allOrgs
	useEffect(() => {
		console.log('useEffect')
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/allOrgs'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setLoadedOrgs(responseData.allOrgs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//This creates an array from the loadedOrgs.  It will be our 'Organization' dropdown:
	if (loadedOrgs && !isLoading && !orgDropdownCreated) {
		const innerOrgArray = []
		loadedOrgs.forEach((org) => {
			const orgDisplay = org.orgName
			innerOrgArray.push(orgDisplay)
		})
		//NEW  This was added for leagues that have divisions
		let uniqueArray = []
		const duplicateFilter = innerOrgArray.filter((element) => {
			if (!uniqueArray.includes(element)) {
				uniqueArray.push(element)

				return true
			}
			return false
		})
		//end NEW
		setOrgArray(duplicateFilter)
		setOrgDropdownCreated(true)
	}
	//
	//
	//
	//
	//
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	let teamsFilteredByOrg
	teamsFilteredByOrg = []
	//
	const handleFilterByOrg = (e) => {
		e.preventDefault()
		const selectedValue = e.target.value
		const selectedOrg = loadedOrgs[selectedValue].orgName
		setOrg(selectedOrg)

		loadedTeams.forEach((team) => {
			if (team.organization === selectedOrg) {
				teamsFilteredByOrg.push(team)
			}
		})
		setFilteredLoadedTeams(teamsFilteredByOrg)
	}
	//
	//
	//
	//
	const showEmailModal = (e) => {
		const value = e.target.id
		setEmailModal(true)
	}
	//
	//
	const cancelEmailHandler = () => {
		setEmailModal(false)
	}
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_qeuvtpo',
				'template_htf42za',
				document.getElementById('emailForm'),
				'GFXJpgnQXBXpF8Y3w'
			)
			.then((res) => {
				console.log(res)
				alert('Your email is on its way!')
				setEmailModal(false)
			})
			.catch((err) => console.log(err))
	}
	//
	//
	//
	//
	const showRosterModal = (e, key) => {
		const value = e.target.id
		console.log('roster for: ' + key)

		if (filteredLoadedTeams) {
			setTeamName(filteredLoadedTeams[key].team)
			setDateOfLastEdit(filteredLoadedTeams[key].date)
			//
			setName1(filteredLoadedTeams[key].name1)
			setNumber1(filteredLoadedTeams[key].number1)
			setBY1(filteredLoadedTeams[key].BY1)
			setIsGoalie1(filteredLoadedTeams[key].playerIsGoalie1)
			setIsTaxi1(filteredLoadedTeams[key].playerIsTaxi1)
			//
			setName2(filteredLoadedTeams[key].name2)
			setNumber2(filteredLoadedTeams[key].number2)
			setBY2(filteredLoadedTeams[key].BY2)
			setIsGoalie2(filteredLoadedTeams[key].playerIsGoalie2)
			setIsTaxi2(filteredLoadedTeams[key].playerIsTaxi2)
			//
			setName3(filteredLoadedTeams[key].name3)
			setNumber3(filteredLoadedTeams[key].number3)
			setBY3(filteredLoadedTeams[key].BY3)
			setIsGoalie3(filteredLoadedTeams[key].playerIsGoalie3)
			setIsTaxi3(filteredLoadedTeams[key].playerIsTaxi3)
			//
			setName4(filteredLoadedTeams[key].name4)
			setNumber4(filteredLoadedTeams[key].number4)
			setBY4(filteredLoadedTeams[key].BY4)
			setIsGoalie4(filteredLoadedTeams[key].playerIsGoalie4)
			setIsTaxi4(filteredLoadedTeams[key].playerIsTaxi4)
			//
			setName5(filteredLoadedTeams[key].name5)
			setNumber5(filteredLoadedTeams[key].number5)
			setBY5(filteredLoadedTeams[key].BY5)
			setIsGoalie5(filteredLoadedTeams[key].playerIsGoalie5)
			setIsTaxi5(filteredLoadedTeams[key].playerIsTaxi5)
			//
			setName6(filteredLoadedTeams[key].name6)
			setNumber6(filteredLoadedTeams[key].number6)
			setBY6(filteredLoadedTeams[key].BY6)
			setIsGoalie6(filteredLoadedTeams[key].playerIsGoalie6)
			setIsTaxi6(filteredLoadedTeams[key].playerIsTaxi6)
			//
			setName7(filteredLoadedTeams[key].name7)
			setNumber7(filteredLoadedTeams[key].number7)
			setBY7(filteredLoadedTeams[key].BY7)
			setIsGoalie7(filteredLoadedTeams[key].playerIsGoalie7)
			setIsTaxi7(filteredLoadedTeams[key].playerIsTaxi7)
			//
			setName8(filteredLoadedTeams[key].name8)
			setNumber8(filteredLoadedTeams[key].number8)
			setBY8(filteredLoadedTeams[key].BY8)
			setIsGoalie8(filteredLoadedTeams[key].playerIsGoalie8)
			setIsTaxi8(filteredLoadedTeams[key].playerIsTaxi8)
			//
			setName9(filteredLoadedTeams[key].name9)
			setNumber9(filteredLoadedTeams[key].number9)
			setBY9(filteredLoadedTeams[key].BY9)
			setIsGoalie9(filteredLoadedTeams[key].playerIsGoalie9)
			setIsTaxi9(filteredLoadedTeams[key].playerIsTaxi9)
			//
			setName10(filteredLoadedTeams[key].name10)
			setNumber10(filteredLoadedTeams[key].number10)
			setBY10(filteredLoadedTeams[key].BY10)
			setIsGoalie10(filteredLoadedTeams[key].playerIsGoalie10)
			setIsTaxi10(filteredLoadedTeams[key].playerIsTaxi10)
			//
			setName11(filteredLoadedTeams[key].name11)
			setNumber11(filteredLoadedTeams[key].number11)
			setBY11(filteredLoadedTeams[key].BY11)
			setIsGoalie11(filteredLoadedTeams[key].playerIsGoalie11)
			setIsTaxi11(filteredLoadedTeams[key].playerIsTaxi11)
			//
			setName12(filteredLoadedTeams[key].name12)
			setNumber12(filteredLoadedTeams[key].number12)
			setBY12(filteredLoadedTeams[key].BY12)
			setIsGoalie12(filteredLoadedTeams[key].playerIsGoalie12)
			setIsTaxi12(filteredLoadedTeams[key].playerIsTaxi12)
			//
			setName13(filteredLoadedTeams[key].name13)
			setNumber13(filteredLoadedTeams[key].number13)
			setBY13(filteredLoadedTeams[key].BY13)
			setIsGoalie13(filteredLoadedTeams[key].playerIsGoalie13)
			setIsTaxi13(filteredLoadedTeams[key].playerIsTaxi13)
			//
			setName14(filteredLoadedTeams[key].name14)
			setNumber14(filteredLoadedTeams[key].number14)
			setBY14(filteredLoadedTeams[key].BY14)
			setIsGoalie14(filteredLoadedTeams[key].playerIsGoalie14)
			setIsTaxi14(filteredLoadedTeams[key].playerIsTaxi14)
			//
			setName15(filteredLoadedTeams[key].name15)
			setNumber15(filteredLoadedTeams[key].number15)
			setBY15(filteredLoadedTeams[key].BY15)
			setIsGoalie15(filteredLoadedTeams[key].playerIsGoalie15)
			setIsTaxi15(filteredLoadedTeams[key].playerIsTaxi15)
			//
			setName16(filteredLoadedTeams[key].name16)
			setNumber16(filteredLoadedTeams[key].number16)
			setBY16(filteredLoadedTeams[key].BY16)
			setIsGoalie16(filteredLoadedTeams[key].playerIsGoalie16)
			setIsTaxi16(filteredLoadedTeams[key].playerIsTaxi16)
			//
			setName17(filteredLoadedTeams[key].name17)
			setNumber17(filteredLoadedTeams[key].number17)
			setBY17(filteredLoadedTeams[key].BY17)
			setIsGoalie17(filteredLoadedTeams[key].playerIsGoalie17)
			setIsTaxi17(filteredLoadedTeams[key].playerIsTaxi17)
			//
			setName18(filteredLoadedTeams[key].name18)
			setNumber18(filteredLoadedTeams[key].number18)
			setBY18(filteredLoadedTeams[key].BY18)
			setIsGoalie18(filteredLoadedTeams[key].playerIsGoalie18)
			setIsTaxi18(filteredLoadedTeams[key].playerIsTaxi18)
			//
			setName19(filteredLoadedTeams[key].name19)
			setNumber19(filteredLoadedTeams[key].number19)
			setBY19(filteredLoadedTeams[key].BY19)
			setIsGoalie19(filteredLoadedTeams[key].playerIsGoalie19)
			setIsTaxi19(filteredLoadedTeams[key].playerIsTaxi19)
			//
			setName20(filteredLoadedTeams[key].name20)
			setNumber20(filteredLoadedTeams[key].number20)
			setBY20(filteredLoadedTeams[key].BY20)
			setIsGoalie20(filteredLoadedTeams[key].playerIsGoalie20)
			setIsTaxi20(filteredLoadedTeams[key].playerIsTaxi20)
			//
			setName21(filteredLoadedTeams[key].name21)
			setNumber21(filteredLoadedTeams[key].number21)
			setBY21(filteredLoadedTeams[key].BY21)
			setIsGoalie21(filteredLoadedTeams[key].playerIsGoalie21)
			setIsTaxi21(filteredLoadedTeams[key].playerIsTaxi21)
			//
			setName22(filteredLoadedTeams[key].name22)
			setNumber22(filteredLoadedTeams[key].number22)
			setBY22(filteredLoadedTeams[key].BY22)
			setIsGoalie22(filteredLoadedTeams[key].playerIsGoalie22)
			setIsTaxi22(filteredLoadedTeams[key].playerIsTaxi22)
			//
			setName23(filteredLoadedTeams[key].name23)
			setNumber23(filteredLoadedTeams[key].number23)
			setBY23(filteredLoadedTeams[key].BY23)
			setIsGoalie23(filteredLoadedTeams[key].playerIsGoalie23)
			setIsTaxi23(filteredLoadedTeams[key].playerIsTaxi23)
			//
			setName24(filteredLoadedTeams[key].name24)
			setNumber24(filteredLoadedTeams[key].number24)
			setBY24(filteredLoadedTeams[key].BY24)
			setIsGoalie24(filteredLoadedTeams[key].playerIsGoalie24)
			setIsTaxi24(filteredLoadedTeams[key].playerIsTaxi24)
			//
			setName25(filteredLoadedTeams[key].name25)
			setNumber25(filteredLoadedTeams[key].number25)
			setBY25(filteredLoadedTeams[key].BY25)
			setIsGoalie25(filteredLoadedTeams[key].playerIsGoalie25)
			setIsTaxi25(filteredLoadedTeams[key].playerIsTaxi25)
			//
			setName26(filteredLoadedTeams[key].name26)
			setNumber26(filteredLoadedTeams[key].number26)
			setBY26(filteredLoadedTeams[key].BY26)
			setIsGoalie26(filteredLoadedTeams[key].playerIsGoalie26)
			setIsTaxi26(filteredLoadedTeams[key].playerIsTaxi26)
			//
			setName27(filteredLoadedTeams[key].name27)
			setNumber27(filteredLoadedTeams[key].number27)
			setBY27(filteredLoadedTeams[key].BY27)
			setIsGoalie27(filteredLoadedTeams[key].playerIsGoalie27)
			setIsTaxi27(filteredLoadedTeams[key].playerIsTaxi27)
			//
			setName28(filteredLoadedTeams[key].name28)
			setNumber28(filteredLoadedTeams[key].number28)
			setBY28(filteredLoadedTeams[key].BY28)
			setIsGoalie28(filteredLoadedTeams[key].playerIsGoalie28)
			setIsTaxi28(filteredLoadedTeams[key].playerIsTaxi28)
			//
			setName29(filteredLoadedTeams[key].name29)
			setNumber29(filteredLoadedTeams[key].number29)
			setBY29(filteredLoadedTeams[key].BY29)
			setIsGoalie29(filteredLoadedTeams[key].playerIsGoalie29)
			setIsTaxi29(filteredLoadedTeams[key].playerIsTaxi29)
			//
			setName30(filteredLoadedTeams[key].name30)
			setNumber30(filteredLoadedTeams[key].number30)
			setBY30(filteredLoadedTeams[key].BY30)
			setIsGoalie30(filteredLoadedTeams[key].playerIsGoalie30)
			setIsTaxi30(filteredLoadedTeams[key].playerIsTaxi30)
			//
			setIsActive1(filteredLoadedTeams[key].playerIsActive1)
			setIsActive2(filteredLoadedTeams[key].playerIsActive2)
			setIsActive3(filteredLoadedTeams[key].playerIsActive3)
			setIsActive4(filteredLoadedTeams[key].playerIsActive4)
			setIsActive5(filteredLoadedTeams[key].playerIsActive5)
			setIsActive6(filteredLoadedTeams[key].playerIsActive6)
			setIsActive7(filteredLoadedTeams[key].playerIsActive7)
			setIsActive8(filteredLoadedTeams[key].playerIsActive8)
			setIsActive9(filteredLoadedTeams[key].playerIsActive9)
			setIsActive10(filteredLoadedTeams[key].playerIsActive10)
			setIsActive11(filteredLoadedTeams[key].playerIsActive11)
			setIsActive12(filteredLoadedTeams[key].playerIsActive12)
			setIsActive13(filteredLoadedTeams[key].playerIsActive13)
			setIsActive14(filteredLoadedTeams[key].playerIsActive14)
			setIsActive15(filteredLoadedTeams[key].playerIsActive15)
			setIsActive16(filteredLoadedTeams[key].playerIsActive16)
			setIsActive17(filteredLoadedTeams[key].playerIsActive17)
			setIsActive18(filteredLoadedTeams[key].playerIsActive18)
			setIsActive19(filteredLoadedTeams[key].playerIsActive19)
			setIsActive20(filteredLoadedTeams[key].playerIsActive20)
			setIsActive21(filteredLoadedTeams[key].playerIsActive21)
			setIsActive22(filteredLoadedTeams[key].playerIsActive22)
			setIsActive23(filteredLoadedTeams[key].playerIsActive23)
			setIsActive24(filteredLoadedTeams[key].playerIsActive24)
			setIsActive25(filteredLoadedTeams[key].playerIsActive25)
			setIsActive26(filteredLoadedTeams[key].playerIsActive26)
			setIsActive27(filteredLoadedTeams[key].playerIsActive27)
			setIsActive28(filteredLoadedTeams[key].playerIsActive28)
			setIsActive29(filteredLoadedTeams[key].playerIsActive29)
			setIsActive30(filteredLoadedTeams[key].playerIsActive30)
		} else {
			setTeamName(loadedTeams[key].team)

			setDateOfLastEdit(loadedTeams[key].date)
			//
			setName1(loadedTeams[key].name1)
			setNumber1(loadedTeams[key].number1)
			setBY1(loadedTeams[key].BY1)
			setIsGoalie1(loadedTeams[key].playerIsGoalie1)
			setIsTaxi1(loadedTeams[key].playerIsTaxi1)
			//
			setName2(loadedTeams[key].name2)
			setNumber2(loadedTeams[key].number2)
			setBY2(loadedTeams[key].BY2)
			setIsGoalie2(loadedTeams[key].playerIsGoalie2)
			setIsTaxi2(loadedTeams[key].playerIsTaxi2)
			//
			setName3(loadedTeams[key].name3)
			setNumber3(loadedTeams[key].number3)
			setBY3(loadedTeams[key].BY3)
			setIsGoalie3(loadedTeams[key].playerIsGoalie3)
			setIsTaxi3(loadedTeams[key].playerIsTaxi3)
			//
			setName4(loadedTeams[key].name4)
			setNumber4(loadedTeams[key].number4)
			setBY4(loadedTeams[key].BY4)
			setIsGoalie4(loadedTeams[key].playerIsGoalie4)
			setIsTaxi4(loadedTeams[key].playerIsTaxi4)
			//
			setName5(loadedTeams[key].name5)
			setNumber5(loadedTeams[key].number5)
			setBY5(loadedTeams[key].BY5)
			setIsGoalie5(loadedTeams[key].playerIsGoalie5)
			setIsTaxi5(loadedTeams[key].playerIsTaxi5)
			//
			setName6(loadedTeams[key].name6)
			setNumber6(loadedTeams[key].number6)
			setBY6(loadedTeams[key].BY6)
			setIsGoalie6(loadedTeams[key].playerIsGoalie6)
			setIsTaxi6(loadedTeams[key].playerIsTaxi6)
			//
			setName7(loadedTeams[key].name7)
			setNumber7(loadedTeams[key].number7)
			setBY7(loadedTeams[key].BY7)
			setIsGoalie7(loadedTeams[key].playerIsGoalie7)
			setIsTaxi7(loadedTeams[key].playerIsTaxi7)
			//
			setName8(loadedTeams[key].name8)
			setNumber8(loadedTeams[key].number8)
			setBY8(loadedTeams[key].BY8)
			setIsGoalie8(loadedTeams[key].playerIsGoalie8)
			setIsTaxi8(loadedTeams[key].playerIsTaxi8)
			//
			setName9(loadedTeams[key].name9)
			setNumber9(loadedTeams[key].number9)
			setBY9(loadedTeams[key].BY9)
			setIsGoalie9(loadedTeams[key].playerIsGoalie9)
			setIsTaxi9(loadedTeams[key].playerIsTaxi9)
			//
			setName10(loadedTeams[key].name10)
			setNumber10(loadedTeams[key].number10)
			setBY10(loadedTeams[key].BY10)
			setIsGoalie10(loadedTeams[key].playerIsGoalie10)
			setIsTaxi10(loadedTeams[key].playerIsTaxi10)
			//
			setName11(loadedTeams[key].name11)
			setNumber11(loadedTeams[key].number11)
			setBY11(loadedTeams[key].BY11)
			setIsGoalie11(loadedTeams[key].playerIsGoalie11)
			setIsTaxi11(loadedTeams[key].playerIsTaxi11)
			//
			setName12(loadedTeams[key].name12)
			setNumber12(loadedTeams[key].number12)
			setBY12(loadedTeams[key].BY12)
			setIsGoalie12(loadedTeams[key].playerIsGoalie12)
			setIsTaxi12(loadedTeams[key].playerIsTaxi12)
			//
			setName13(loadedTeams[key].name13)
			setNumber13(loadedTeams[key].number13)
			setBY13(loadedTeams[key].BY13)
			setIsGoalie13(loadedTeams[key].playerIsGoalie13)
			setIsTaxi13(loadedTeams[key].playerIsTaxi13)
			//
			setName14(loadedTeams[key].name14)
			setNumber14(loadedTeams[key].number14)
			setBY14(loadedTeams[key].BY14)
			setIsGoalie14(loadedTeams[key].playerIsGoalie14)
			setIsTaxi14(loadedTeams[key].playerIsTaxi14)
			//
			setName15(loadedTeams[key].name15)
			setNumber15(loadedTeams[key].number15)
			setBY15(loadedTeams[key].BY15)
			setIsGoalie15(loadedTeams[key].playerIsGoalie15)
			setIsTaxi15(loadedTeams[key].playerIsTaxi15)
			//
			setName16(loadedTeams[key].name16)
			setNumber16(loadedTeams[key].number16)
			setBY16(loadedTeams[key].BY16)
			setIsGoalie16(loadedTeams[key].playerIsGoalie16)
			setIsTaxi16(loadedTeams[key].playerIsTaxi16)
			//
			setName17(loadedTeams[key].name17)
			setNumber17(loadedTeams[key].number17)
			setBY17(loadedTeams[key].BY17)
			setIsGoalie17(loadedTeams[key].playerIsGoalie17)
			setIsTaxi17(loadedTeams[key].playerIsTaxi17)
			//
			setName18(loadedTeams[key].name18)
			setNumber18(loadedTeams[key].number18)
			setBY18(loadedTeams[key].BY18)
			setIsGoalie18(loadedTeams[key].playerIsGoalie18)
			setIsTaxi18(loadedTeams[key].playerIsTaxi18)
			//
			setName19(loadedTeams[key].name19)
			setNumber19(loadedTeams[key].number19)
			setBY19(loadedTeams[key].BY19)
			setIsGoalie19(loadedTeams[key].playerIsGoalie19)
			setIsTaxi19(loadedTeams[key].playerIsTaxi19)
			//
			setName20(loadedTeams[key].name20)
			setNumber20(loadedTeams[key].number20)
			setBY20(loadedTeams[key].BY20)
			setIsGoalie20(loadedTeams[key].playerIsGoalie20)
			setIsTaxi20(loadedTeams[key].playerIsTaxi20)
			//
			setName21(loadedTeams[key].name21)
			setNumber21(loadedTeams[key].number21)
			setBY21(loadedTeams[key].BY21)
			setIsGoalie21(loadedTeams[key].playerIsGoalie21)
			setIsTaxi21(loadedTeams[key].playerIsTaxi21)
			//
			setName22(loadedTeams[key].name22)
			setNumber22(loadedTeams[key].number22)
			setBY22(loadedTeams[key].BY22)
			setIsGoalie22(loadedTeams[key].playerIsGoalie22)
			setIsTaxi22(loadedTeams[key].playerIsTaxi22)
			//
			setName23(loadedTeams[key].name23)
			setNumber23(loadedTeams[key].number23)
			setBY23(loadedTeams[key].BY23)
			setIsGoalie23(loadedTeams[key].playerIsGoalie23)
			setIsTaxi23(loadedTeams[key].playerIsTaxi23)
			//
			setName24(loadedTeams[key].name24)
			setNumber24(loadedTeams[key].number24)
			setBY24(loadedTeams[key].BY24)
			setIsGoalie24(loadedTeams[key].playerIsGoalie24)
			setIsTaxi24(loadedTeams[key].playerIsTaxi24)
			//
			setName25(loadedTeams[key].name25)
			setNumber25(loadedTeams[key].number25)
			setBY25(loadedTeams[key].BY25)
			setIsGoalie25(loadedTeams[key].playerIsGoalie25)
			setIsTaxi25(loadedTeams[key].playerIsTaxi25)
			//
			setName26(loadedTeams[key].name26)
			setNumber26(loadedTeams[key].number26)
			setBY26(loadedTeams[key].BY26)
			setIsGoalie26(loadedTeams[key].playerIsGoalie26)
			setIsTaxi26(loadedTeams[key].playerIsTaxi26)
			//
			setName27(loadedTeams[key].name27)
			setNumber27(loadedTeams[key].number27)
			setBY27(loadedTeams[key].BY27)
			setIsGoalie27(loadedTeams[key].playerIsGoalie27)
			setIsTaxi27(loadedTeams[key].playerIsTaxi27)
			//
			setName28(loadedTeams[key].name28)
			setNumber28(loadedTeams[key].number28)
			setBY28(loadedTeams[key].BY28)
			setIsGoalie28(loadedTeams[key].playerIsGoalie28)
			setIsTaxi28(loadedTeams[key].playerIsTaxi28)
			//
			setName29(loadedTeams[key].name29)
			setNumber29(loadedTeams[key].number29)
			setBY29(loadedTeams[key].BY29)
			setIsGoalie29(loadedTeams[key].playerIsGoalie29)
			setIsTaxi29(loadedTeams[key].playerIsTaxi29)
			//
			setName30(loadedTeams[key].name30)
			setNumber30(loadedTeams[key].number30)
			setBY30(loadedTeams[key].BY30)
			setIsGoalie30(loadedTeams[key].playerIsGoalie30)
			setIsTaxi30(loadedTeams[key].playerIsTaxi30)
			//
			setIsActive1(loadedTeams[key].playerIsActive1)
			setIsActive2(loadedTeams[key].playerIsActive2)
			setIsActive3(loadedTeams[key].playerIsActive3)
			setIsActive4(loadedTeams[key].playerIsActive4)
			setIsActive5(loadedTeams[key].playerIsActive5)
			setIsActive6(loadedTeams[key].playerIsActive6)
			setIsActive7(loadedTeams[key].playerIsActive7)
			setIsActive8(loadedTeams[key].playerIsActive8)
			setIsActive9(loadedTeams[key].playerIsActive9)
			setIsActive10(loadedTeams[key].playerIsActive10)
			setIsActive11(loadedTeams[key].playerIsActive11)
			setIsActive12(loadedTeams[key].playerIsActive12)
			setIsActive13(loadedTeams[key].playerIsActive13)
			setIsActive14(loadedTeams[key].playerIsActive14)
			setIsActive15(loadedTeams[key].playerIsActive15)
			setIsActive16(loadedTeams[key].playerIsActive16)
			setIsActive17(loadedTeams[key].playerIsActive17)
			setIsActive18(loadedTeams[key].playerIsActive18)
			setIsActive19(loadedTeams[key].playerIsActive19)
			setIsActive20(loadedTeams[key].playerIsActive20)
			setIsActive21(loadedTeams[key].playerIsActive21)
			setIsActive22(loadedTeams[key].playerIsActive22)
			setIsActive23(loadedTeams[key].playerIsActive23)
			setIsActive24(loadedTeams[key].playerIsActive24)
			setIsActive25(loadedTeams[key].playerIsActive25)
			setIsActive26(loadedTeams[key].playerIsActive26)
			setIsActive27(loadedTeams[key].playerIsActive27)
			setIsActive28(loadedTeams[key].playerIsActive28)
			setIsActive29(loadedTeams[key].playerIsActive29)
			setIsActive30(loadedTeams[key].playerIsActive30)
		}
		//
		setRosterModal(true)
	}
	//
	const cancelRosterHandler = () => {
		setRosterModal(false)
	}
	//
	//
	let rem
	if (filteredLoadedTeams && filteredLoadedTeams.length > 0) {
		if (filteredLoadedTeams.length < 5) {
			rem = '18rem'
		} else {
			rem = '5rem'
		}
	}
	//
	if (filteredLoadedTeams && filteredLoadedTeams.length === 0) {
		rem = '20rem'
	}

	//If its the offseason and we have zero teams at the moment
	if (loadedTeams && loadedTeams.length === 0) {
		rem = '20rem'
	}

	//
	//
	return (
		<React.Fragment>
			<ModalEmail
				show={emailModal}
				onCancel={cancelEmailHandler}
				header='Send an Email'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{ marginTop: '0.5rem', width: '80%' }}
						>
							<form
								method='post'
								enctype='text/plain'
								id='emailForm'
								style={{ height: '80%' }}
							>
								<label>Subject: </label>
								<input
									type='text'
									name='subject'
									className='form-control emailInput'
									defaultValue={'New Message from PBHL'}
									required
								></input>

								<br></br>
								<br></br>

								<label>From: </label>
								<input
									type='text'
									name='from'
									className='form-control emailInput'
									defaultValue='Pennsylvania Ball Hockey League'
								></input>

								<br></br>
								<br></br>

								<label>To: </label>
								<input
									type='text'
									name='to'
									className='form-control emailInput'
									defaultValue={uniqueEmailAddresses && uniqueEmailAddresses}
								></input>

								<br></br>
								<br></br>

								<label>Message</label>
								<textarea
									name='message'
									rows='6'
									className='form-control emailInput'
								/>
								<button class='buttonButton2' type='submit' onClick={sendEmail}>
									Send
								</button>
								<button
									className='buttonButton2'
									style={{ marginLeft: '2rem' }}
									onClick={cancelEmailHandler}
								>
									CANCEL
								</button>
							</form>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<ModalEmail
				show={rosterModal}
				onCancel={cancelRosterHandler}
				header={teamName}
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{
								marginTop: '0.5rem',
								width: '80%',
								marginBottom: '2rem',
							}}
						>
							<h3>Total Active Players: {totalActivePlayers}</h3>
							<table className='rosterModalTable'>
								<thead className='tableHeader2'>
									<tr>
										<th>Name</th>
										<th>#</th>
										<th></th>
										<th style={{ textAlign: 'center' }}>Birth Year</th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ textAlign: 'left' }}>{name1}</td>
										<td style={{ textAlign: 'left' }}>{number1}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY1}</td>
										{isGoalie1 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi1 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name1 && !isActive1 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name2}</td>
										<td style={{ textAlign: 'left' }}>{number2}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY2}</td>
										{isGoalie2 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi2 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name2 && !isActive2 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name3}</td>
										<td style={{ textAlign: 'left' }}>{number3}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY3}</td>
										{isGoalie3 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi3 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name3 && !isActive3 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name4}</td>
										<td style={{ textAlign: 'left' }}>{number4}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY4}</td>
										{isGoalie4 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi4 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name4 && !isActive4 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name5}</td>
										<td style={{ textAlign: 'left' }}>{number5}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY5}</td>
										{isGoalie5 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi5 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name5 && !isActive5 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name6}</td>
										<td style={{ textAlign: 'left' }}>{number6}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY6}</td>
										{isGoalie6 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi6 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name6 && !isActive6 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name7}</td>
										<td style={{ textAlign: 'left' }}>{number7}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY7}</td>
										{isGoalie7 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi7 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name7 && !isActive7 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name8}</td>
										<td style={{ textAlign: 'left' }}>{number8}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY8}</td>
										{isGoalie8 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi8 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name8 && !isActive8 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name9}</td>
										<td style={{ textAlign: 'left' }}>{number9}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY9}</td>
										{isGoalie9 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi9 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name9 && !isActive9 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name10}</td>
										<td style={{ textAlign: 'left' }}>{number10}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY10}</td>
										{isGoalie10 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi10 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name10 && !isActive10 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name11}</td>
										<td style={{ textAlign: 'left' }}>{number11}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY11}</td>
										{isGoalie11 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi11 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name11 && !isActive11 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name12}</td>
										<td style={{ textAlign: 'left' }}>{number12}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY12}</td>
										{isGoalie12 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi12 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name12 && !isActive12 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name13}</td>
										<td style={{ textAlign: 'left' }}>{number13}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY13}</td>
										{isGoalie13 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi13 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name13 && !isActive13 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name14}</td>
										<td style={{ textAlign: 'left' }}>{number14}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY14}</td>
										{isGoalie14 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi14 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name14 && !isActive14 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name15}</td>
										<td style={{ textAlign: 'left' }}>{number15}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY15}</td>
										{isGoalie15 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi15 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name15 && !isActive15 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name16}</td>
										<td style={{ textAlign: 'left' }}>{number16}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY16}</td>
										{isGoalie16 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi16 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name16 && !isActive16 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name17}</td>
										<td style={{ textAlign: 'left' }}>{number17}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY17}</td>
										{isGoalie17 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi17 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name17 && !isActive17 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name18}</td>
										<td style={{ textAlign: 'left' }}>{number18}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY18}</td>
										{isGoalie18 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{name18 && isTaxi18 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name18 && !isActive18 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name19}</td>
										<td style={{ textAlign: 'left' }}>{number19}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY19}</td>
										{isGoalie19 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi19 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name19 && !isActive19 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name20}</td>
										<td style={{ textAlign: 'left' }}>{number20}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY20}</td>
										{isGoalie20 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi20 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name20 && !isActive20 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name21}</td>
										<td style={{ textAlign: 'left' }}>{number21}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY21}</td>
										{isGoalie21 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi21 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name21 && !isActive21 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name22}</td>
										<td style={{ textAlign: 'left' }}>{number22}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY22}</td>
										{isGoalie22 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi22 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name22 && !isActive22 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name23}</td>
										<td style={{ textAlign: 'left' }}>{number23}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY23}</td>
										{isGoalie23 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi23 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name23 && !isActive23 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name24}</td>
										<td style={{ textAlign: 'left' }}>{number24}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY24}</td>
										{isGoalie24 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi24 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name24 && !isActive24 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name25}</td>
										<td style={{ textAlign: 'left' }}>{number25}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY25}</td>
										{isGoalie25 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi25 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name25 && !isActive25 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name26}</td>
										<td style={{ textAlign: 'left' }}>{number26}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY26}</td>
										{isGoalie26 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi26 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name26 && !isActive26 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name27}</td>
										<td style={{ textAlign: 'left' }}>{number27}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY27}</td>
										{isGoalie27 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi27 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name27 && !isActive27 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name28}</td>
										<td style={{ textAlign: 'left' }}>{number28}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY28}</td>
										{isGoalie28 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi28 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name28 && !isActive28 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name29}</td>
										<td style={{ textAlign: 'left' }}>{number29}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY29}</td>
										{isGoalie29 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi29 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name29 && !isActive29 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name30}</td>
										<td style={{ textAlign: 'left' }}>{number30}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY30}</td>
										{isGoalie30 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi30 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name30 && !isActive30 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
								</tbody>
							</table>
							<div
								style={{
									textAlign: 'center',
									fontWeight: 'bolder',
								}}
							>
								Date of last edit: {dateOfLastEdit}
							</div>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedTeams && (
				<div className='RegLeaguesNonAdmin'>
					<HomeHomeNavigation />
					<h1>Tournament Rosters</h1>
					<div style={{ marginBottom: '1.5rem' }}>
						<div className='row'>
							<div className='LTVinstructions'>Filter by Organization: </div>
						</div>
						{!isLoading && loadedTeams && loadedOrgs && (
							<div className='row'>
								<div className='col'></div>
								<div className='col-sm'>
									<form className='orgDropdown'>
										<select
											className='my-1 mr-sm-2 selectLeagueDropdown'
											onChange={handleFilterByOrg}
										>
											<option defaultValue></option>
											{orgArray.map((org, i) => (
												<option value={i} key={i}>
													{org.toUpperCase()}
												</option>
											))}
										</select>
									</form>
								</div>
								<div className='col'></div>
							</div>
						)}
					</div>
					<table id='regLeaguesNonAdmin'>
						<thead>
							<tr>
								<th>Year</th>
								<th>Organization</th>
								<th>Team Name</th>
								<th>Head Coach</th>
								<th>Assistant Coaches</th>
								<th>Players</th>
							</tr>
						</thead>
						<tbody>
							{filteredLoadedTeams &&
								filteredLoadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<Button
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
													style={{
														color: 'white',
														textDecoration: 'none',
														backgroundColor: '#e0e0e0',
													}}
												>
													View Roster
												</Button>
											</td>
										</tr>
									)
								})}
							{!filteredLoadedTeams &&
								loadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<Button
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
												>
													View Roster
												</Button>
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>
				</div>
			)}
			<div style={{ marginTop: rem }}>
				<Footer />
			</div>
		</React.Fragment>
	)
}

export default NonAdminRegisteredTeams
