import React from 'react'
import GameSummaryNavigation from './GameSummaryNavigation'
import GameSummary from './GameSummary'

const GameSummaryHome = () => {
	return (
		<div>
			<GameSummaryNavigation />
			<GameSummary />
		</div>
	)
}

export default GameSummaryHome
