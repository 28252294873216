import React from 'react'
import './Footer.css'

const Footer = () => {
	return (
		<div style={{ backgroundColor: '#060153' }}>
			<footer>
				<div>
					<div className='row'>
						<div className='col text-center'>
							<h1
								className='font-weight-light text-capitalize p-3'
								style={{ color: 'white' }}
							>
								Pennsylvania Ball Hockey League
							</h1>
							<h4
								className='font-weight-bold font-italic'
								style={{ color: 'white' }}
							>
								Grow the Game.
							</h4>
							<div className='py-3'>
								<a
									href='https://www.facebook.com/profile.php?id=100077148484696&paipv=0&eav=AfYdN8KAI6ZSGWFMKzXWhNaPIZhtHTLCDNXc3PSHLh0-2rXwzG-wM8vZv27QzUP_IW0'
									target='_blank'
									style={{ color: 'white' }}
								>
									<i className='fab fa-facebook fa-3x mx-3'></i>
								</a>
							</div>
							<p
								className='py-4 m-0 copyright'
								style={{ color: 'white', backgroundColor: '#48667e' }}
							>
								A Web Application by{' '}
								<a
									href='https://www.shireystatsandstandings.com/#'
									target='_blank'
									style={{ color: 'white' }}
								>
									Shirey Stats and Standings, LLC
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer
