import React, { useState } from 'react'
import PasswordModal from './PasswordModal'
import Button from './Button'

const PasswordErrorModal = (props) => {
	//console.log('PasswordErrorModal props: ' + JSON.stringify(props))

	const forgotHandler = () => {
		alert(
			'This functionality is coming soon.  In the meantime, reach out to matt@shireystatsandstandings.com using your email address on file and we will send you your password.  Thanks.'
		)
	}
	//
	//
	//
	//
	if (props.error === 'Password is incorrect.  Try again.') {
		return (
			<PasswordModal
				onCancel={props.onClear}
				header='An Error Occurred!'
				show={!!props.error}
				footer={
					<React.Fragment>
						<Button onClick={props.onClear}>Okay</Button>
						<p style={{ marginTop: '1.5rem' }}>
							Forgot your password?{' '}
							<a onClick={forgotHandler} className='loginLink'>
								Click Here.
							</a>
						</p>
					</React.Fragment>
				}
			>
				<p>{props.error}</p>
			</PasswordModal>
		)
	} else {
		return (
			<PasswordModal
				onCancel={props.onClear}
				header='An Error Occurred!'
				show={!!props.error}
				footer={
					<React.Fragment>
						<Button onClick={props.onClear}>Okay</Button>
					</React.Fragment>
				}
			>
				<p>{props.error}</p>
			</PasswordModal>
		)
	}
}

export default PasswordErrorModal
