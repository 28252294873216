//Max made this in his course, so I did as well.  Not sure I'm going to use it though.
//Watch video 152 to see how and why he used it
import { useState, useCallback, useRef, useEffect } from 'react'

export const useHttpClient = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()

	const activeHttpRequests = useRef([])

	const sendRequest = useCallback(
		async (url, method = 'GET', body = null, headers = {}) => {
			setIsLoading(true)
			const httpAbortCtrl = new AbortController()
			activeHttpRequests.current.push(httpAbortCtrl)
			try {
				const response = await fetch(url, {
					method,
					body,
					headers,
					signal: httpAbortCtrl.signal,
				})

				const responseData = await response.json()

				activeHttpRequests.current = activeHttpRequests.current.filter(
					(reqCtrl) => reqCtrl !== httpAbortCtrl
				)

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setIsLoading(false)
				return responseData
			} catch (err) {
				setError(err.message)
				setIsLoading(false)
				throw err
			}
		},
		[]
	)

	const clearError = () => {
		setError(null)
	}

	//MATT:  I commented out the line of code below because I dont know wtf it does.
	//All I know is when I click on 'EDIT' to edit a team, I get an error that says
	//"The user aborted a request".  If I comment out the line below, I don't get that
	//error anymore.
	useEffect(() => {
		return () => {
			//activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort())
		}
	}, [])

	return { isLoading, error, sendRequest, clearError }
}
