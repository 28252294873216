import React from 'react'
import TeamRegistrationHomeNavigation from './TeamRegistrationHomeNavigation'
import TeamRegistrationUpdate from '../../schedule/TeamRegistrationUpdate'

const TeamRegistrationUpdateHome = () => {
	return (
		<div>
			<TeamRegistrationHomeNavigation />
			<TeamRegistrationUpdate />
		</div>
	)
}

export default TeamRegistrationUpdateHome
