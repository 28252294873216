//Create a roster for each team.
//Even while not logged in, you can see a list of players within the chosen team.
//This here is a page that OUTPUTS that list.
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import './Players.css'
import Card from '../../shared/components/UIElements/Card'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Footer from '../../shared/components/UIElements/Footer'
import TeamHistoryHomeNavigation from './TeamHistoryHomeNavigation'
import sloths from '../../shared/components/images/Sloths_Name.png'

const TeamHistoryHome = () => {
	console.log('inside TeamHistoryHome')
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [rosteredPlayers, setRosteredPlayers] = useState()
	const [assignedPlayers, setAssignedPlayers] = useState()
	const [gamesList, setGamesList] = useState()
	const [wins, setWins] = useState()
	const [losses, setLosses] = useState()
	const [overtimeLosses, setOvertimeLosses] = useState()
	const [shootoutLosses, setShootoutLosses] = useState()
	const [ties, setTies] = useState()
	const [teamName, setTeamName] = useState()
	const [year, setYear] = useState()
	const [loadedTeams, setLoadedTeams] = useState()

	//taps into LEAGUE backend, getPlayersOnTeam.
	//Also returns wins, losses, OTlosses, and SOlosses
	/* useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/league/${leagueId}/${session}/${year}/${teamName}`
					`${process.env.REACT_APP_BACKEND_URL}/league/rosterAndSchedule`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setRosteredPlayers(responseData.rosteredPlayers)
				setAssignedPlayers(responseData.assignedPlayers)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setGamesList(responseData.allGamesAndEventsArray)
				setTies(responseData.ties)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	 */
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	let selectedTeam

	//Call backend to get all the previous teams for our dropdown
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/archive'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTeams(responseData.archivedTeams)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedTeams])

	let teamArray

	if (loadedTeams && !isLoading) {
		teamArray = []
		loadedTeams.forEach((team) => {
			const teamDisplay = team.teamName + ' ' + team.year
			teamArray.push(teamDisplay)
		})
	}

	loadedTeams && console.log('loadedTeams: ' + loadedTeams)

	let navigate = useNavigate()

	const handleChange = async (e) => {
		if (e.target.value !== 'View Previous Teams') {
			e.preventDefault()
			const selectedValue = e.target.value
			console.log(selectedValue)
			selectedTeam = loadedTeams[selectedValue]
			const teamId = selectedTeam._id
			//navigate(`/history/${teamId}`) //< - - This changes the url
			//
			//
			//Maybe make a backend call right here to get this old teams data
			setIsLoading(true)
			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/league/${leagueId}/${session}/${year}/${teamName}`
					`${process.env.REACT_APP_BACKEND_URL}/league/oldRosterAndSchedule/${teamId}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setRosteredPlayers(responseData.rosteredPlayers)
				setAssignedPlayers(responseData.assignedPlayers)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setGamesList(responseData.allGamesAndEventsArray)
				setTies(responseData.ties)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
	}
	//
	//
	//
	//
	let rem
	if (assignedPlayers) {
		console.log('assignedPlayers: ' + assignedPlayers)
		if (assignedPlayers > 10) {
			rem = '2rem'
		} else {
			rem = '10rem'
		}
	}
	//
	//
	return (
		<React.Fragment>
			<TeamHistoryHomeNavigation />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center' style={{ marginTop: '10rem' }}>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !rosteredPlayers && !gamesList && (
				<React.Fragment>
					<div className='Roster' style={{ marginTop: '6rem' }}>
						<div>
							<img src={sloths} className='sloths' />
						</div>
						{/* <SelectPreviousTeam /> */}
						<div style={{ textAlign: 'center' }}>
							{!isLoading && loadedTeams && (
								<form className='form-control-lg'>
									<select
										className='my-1 mr-sm-2 selectLeagueDropdown'
										onChange={handleChange}
										style={{
											background: 'white',
											borderWidth: '3px',
											width: '30vh',
											fontSize: 'medium',
											fontFamily: 'Montserrat, Work Sans, sans-serif',
										}}
									>
										<option defaultValue>View Previous Teams</option>
										{teamArray.map((team, i) => (
											<option value={i} key={i}>
												{team.toUpperCase()}
											</option>
										))}
									</select>
								</form>
							)}
						</div>
						{/* <h2>{year}</h2>
						<h3 className='h3Center'>
							Record: {wins} - {losses} -{' '}
							{ties + overtimeLosses + shootoutLosses}
						</h3>
						<table id='roster'>
							<thead>
								<tr>
									<th>Number</th>
									<th>Name</th>
									<th>G</th>
									<th>A</th>
									<th>Total Points</th>
								</tr>
							</thead>
							<tbody>
								{rosteredPlayers.map((player, key) => {
									{
										return (
											<tr key={key}>
												<td>{player.number}</td>
												<td className='tableDataTeamName'>
													<Link
														style={{
															textDecoration: 'none',
															color: 'black',
															fontWeight: 600,
														}}
														to={`/${player.playerId}/history`}
													>
														{player.firstName} {player.lastName}
													</Link>
												</td>
												<td>{player.goals}</td>
												<td>{player.assists}</td>
												<td>{player.goals + player.assists}</td>
											</tr>
										)
									}
								})}
							</tbody>
						</table> */}
					</div>
					{/* THIS (BELOW) USED TO BE THE TEAMS SCHEDULE */}
					{/* IM NOT USING THIS FOR SLOTHS BECAUSE THEY ALREADY HAVE A SCHEDULE PAGE */}
					{/* THIS PAGE IS JUST FOR PLAYER STATS */}
					<div style={{ marginTop: `${rem}` }}>
						<Footer />
					</div>
				</React.Fragment>
			)}
			{!isLoading && rosteredPlayers && gamesList && (
				<React.Fragment>
					<div className='Roster' style={{ marginTop: '6rem' }}>
						<div>
							<img src={sloths} className='sloths' />
						</div>
						{/* <SelectPreviousTeam /> */}
						<div style={{ textAlign: 'center' }}>
							{!isLoading && loadedTeams && (
								<form className='form-control-lg'>
									<select
										className='my-1 mr-sm-2 selectLeagueDropdown'
										onChange={handleChange}
										style={{
											background: 'white',
											borderWidth: '3px',
											width: '30vh',
											fontSize: 'medium',
											fontFamily: 'Montserrat, Work Sans, sans-serif',
										}}
									>
										<option defaultValue>View Previous Teams</option>
										{teamArray.map((team, i) => (
											<option value={i} key={i}>
												{team.toUpperCase()}
											</option>
										))}
									</select>
								</form>
							)}
						</div>
						<h2>{year}</h2>
						<h3 className='h3Center'>
							Record: {wins} - {losses} -{' '}
							{ties + overtimeLosses + shootoutLosses}
						</h3>
						<table id='roster'>
							<thead>
								<tr>
									<th>Number</th>
									<th>Name</th>
									<th>G</th>
									<th>A</th>
									<th>Total Points</th>
								</tr>
							</thead>
							<tbody>
								{rosteredPlayers.map((player, key) => {
									{
										return (
											<tr key={key}>
												<td>{player.number}</td>
												<td className='tableDataTeamName'>
													<Link
														style={{
															textDecoration: 'none',
															color: 'black',
															fontWeight: 600,
														}}
														to={`/${player.playerId}/history`}
													>
														{player.firstName} {player.lastName}
													</Link>
												</td>
												<td>{player.goals}</td>
												<td>{player.assists}</td>
												<td>{player.goals + player.assists}</td>
											</tr>
										)
									}
								})}
							</tbody>
						</table>
					</div>
					{/* PREVIOUS TEAMS SCHEDULE */}
					<div style={{ marginTop: '6rem' }} className='Schedule'>
						{/* <AdminNavigation /> */}
						<div>
							<img src={sloths} className='sloths' />
						</div>
						<h2>
							Record: {wins} - {losses} -{' '}
							{ties + shootoutLosses + overtimeLosses}
						</h2>
						<h3 className='h3Center'>{teamName + ' - ' + year}</h3>
						<p>(Click on the final score of a game to see a Game Summary)</p>
						<table id='teamScheduleDisplay'>
							<thead>
								<tr>
									<th>Date</th>
									<th>Time</th>
									<th>Status</th>
									<th>Score</th>
									<th>Opponent</th>
									<th></th>
									<th>Venue</th>
								</tr>
							</thead>
							<tbody>
								{gamesList.map((game, key) => {
									// if (game.venueName === venue) {
									return (
										<tr key={key}>
											<td>
												{game.dayOfWeek}
												{', '}
												{game.date.substr(0, 2) === '01' &&
													'Jan. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '02' &&
													'Feb. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '03' &&
													'Mar. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '04' &&
													'Apr. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '05' &&
													'May ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '06' &&
													'June ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '07' &&
													'July ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '08' &&
													'Aug. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '09' &&
													'Sept. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '10' &&
													'Oct. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '11' &&
													'Nov. ' + game.date.substr(3, 2)}
												{game.date.substr(0, 2) === '12' &&
													'Dec. ' + game.date.substr(3, 2)}
											</td>
											<td>
												{game.time.substr(0, 2) === '00' &&
													'12:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '01' &&
													'1:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '02' &&
													'2:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '03' &&
													'3:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '04' &&
													'4:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '05' &&
													'5:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '06' &&
													'6:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '07' &&
													'7:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '08' &&
													'8:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '09' &&
													'9:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '10' &&
													'10:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '11' &&
													'11:' + game.time.substr(3, 2) + ' am'}
												{game.time.substr(0, 2) === '12' &&
													'12:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '13' &&
													'1:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '14' &&
													'2:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '15' &&
													'3:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '16' &&
													'4:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '17' &&
													'5:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '18' &&
													'6:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '19' &&
													'7:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '20' &&
													'8:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '21' &&
													'9:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '22' &&
													'10:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '23' &&
													'11:' + game.time.substr(3, 2) + ' pm'}
												{game.time.substr(0, 2) === '24' &&
													'12:' + game.time.substr(3, 2) + ' am'}
											</td>
											<td>
												{(game.status && game.status !== 'TBP' && (
													<Link
														style={{
															textDecoration: 'none',
															fontWeight: 600,
															color: 'black',
														}}
														to={`/gameSummary/${game._id}`}
													>
														{game.status}
													</Link>
												)) ||
													'TBP'}
											</td>
											{!game.score && <td></td>}
											{game.score && !game.winner && !game.loser && (
												<td style={{ color: 'black', fontWeight: 'bold' }}>
													<Link
														style={{
															textDecoration: 'none',
															fontWeight: 600,
															color: 'black',
														}}
														to={`/gameSummary/${game._id}`}
													>
														TIED {game.score}
													</Link>
												</td>
											)}
											{game.winner === game.opponent && !game.eventName && (
												<td style={{ color: 'red', fontWeight: 'bold' }}>
													<Link
														style={{
															textDecoration: 'none',
															fontWeight: 600,
															color: 'red',
														}}
														to={`/gameSummary/${game._id}`}
													>
														LOST {game.score}
													</Link>
												</td>
											)}
											{game.winner === teamName && (
												<td style={{ color: 'green', fontWeight: 'bold' }}>
													<Link
														style={{
															textDecoration: 'none',
															fontWeight: 600,
															color: 'green',
														}}
														to={`/gameSummary/${game._id}`}
													>
														WON {game.score}
													</Link>
												</td>
											)}
											{game.opponent && <td>{game.opponent}</td>}
											{game.eventName && (
												<td
													style={{
														fontWeight: 'bold',
														backgroundColor: 'pink',
													}}
												>
													{game.eventName}
												</td>
											)}
											{!game.playoff && !game.championship && <td></td>}
											{game.playoff && (
												<td style={{ color: 'red', fontWeight: 'bold' }}>
													PLAYOFF GAME
												</td>
											)}

											{game.championship && (
												<td style={{ color: 'red', fontWeight: 'bold' }}>
													CHAMPIONSHIP GAME
												</td>
											)}
											<td>{game.venueName}</td>
										</tr>
									)
									//}
								})}
							</tbody>
						</table>
					</div>
					<div style={{ marginTop: `${rem}` }}>
						<Footer />
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

export default TeamHistoryHome
