import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
import { NavLink } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'

const AdminVenueList = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedVenues, setLoadedVenues] = useState()

	//First, lets tap into the following route on the backend to get all teams in league:
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/venues`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedVenues(responseData.allVenues)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])

	const errorHandler = () => {
		setError(null)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedVenues && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>Venues</h1>
					<table id='teams'>
						<thead>
							<tr>
								<th>Venue Name</th>
								<th>Address</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedVenues.map((venue, key) => {
								return (
									<tr key={key}>
										<td>{venue.venueName}</td>
										<td>{venue.venueAddress}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updateVenue/${venue._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
									</tr>
								)
							})}
							<tr style={{ backgroundColor: '#e0e0e0' }}>
								<AdminButton
									to={`/admin/venue/new`}
									exact
									style={{
										color: 'white',
										textDecoration: 'none',
									}}
								>
									Add a new Venue
								</AdminButton>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminVenueList
