//non admin
//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { NavLink, useParams } from 'react-router-dom'
import Button from '../components/UIElements/Button'
import ErrorModal from '../components/UIElements/ErrorModal'
import Modal from '../components/UIElements/Modal'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'
//import COCHomeNavigation from '../components/Navigation/COCHomeNavigation'
//import sloths from '../../shared/components/images/Sloths_Name.png'

const TeamRegistration = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showSuccessfulRegistrationModal, setShowSuccessfulRegistrationModal] =
		useState(false)
	const [headCoach, setHeadCoach] = useState()
	const [assistantCoaches, setAssistantCoaches] = useState()
	const [team, setTeam] = useState()
	const [org, setOrg] = useState()
	const [year, setYear] = useState('2024')
	const [date, setDate] = useState()
	const [allTeamsForACoach, setAllTeamsForACoach] = useState()
	const [loadedOrgs, setLoadedOrgs] = useState()
	const [orgDropdownCreated, setOrgDropdownCreated] = useState(false)
	const [orgArray, setOrgArray] = useState([])
	//
	const [name1, setName1] = useState()
	const [number1, setNumber1] = useState()
	const [BY1, setBY1] = useState()
	const [playerIsGoalie1, setPlayerIsGoalie1] = useState()
	const [playerIsTaxi1, setPlayerIsTaxi1] = useState()
	const [playerIsActive1, setPlayerIsActive1] = useState()
	//
	const [name2, setName2] = useState()
	const [number2, setNumber2] = useState()
	const [BY2, setBY2] = useState()
	const [playerIsGoalie2, setPlayerIsGoalie2] = useState()
	const [playerIsTaxi2, setPlayerIsTaxi2] = useState()
	const [playerIsActive2, setPlayerIsActive2] = useState()
	//
	const [name3, setName3] = useState()
	const [number3, setNumber3] = useState()
	const [BY3, setBY3] = useState()
	const [playerIsGoalie3, setPlayerIsGoalie3] = useState()
	const [playerIsTaxi3, setPlayerIsTaxi3] = useState()
	const [playerIsActive3, setPlayerIsActive3] = useState()
	//
	const [name4, setName4] = useState()
	const [number4, setNumber4] = useState()
	const [BY4, setBY4] = useState()
	const [playerIsGoalie4, setPlayerIsGoalie4] = useState()
	const [playerIsTaxi4, setPlayerIsTaxi4] = useState()
	const [playerIsActive4, setPlayerIsActive4] = useState()
	//
	const [name5, setName5] = useState()
	const [number5, setNumber5] = useState()
	const [BY5, setBY5] = useState()
	const [playerIsGoalie5, setPlayerIsGoalie5] = useState()
	const [playerIsTaxi5, setPlayerIsTaxi5] = useState()
	const [playerIsActive5, setPlayerIsActive5] = useState()
	//
	const [name6, setName6] = useState()
	const [number6, setNumber6] = useState()
	const [BY6, setBY6] = useState()
	const [playerIsGoalie6, setPlayerIsGoalie6] = useState()
	const [playerIsTaxi6, setPlayerIsTaxi6] = useState()
	const [playerIsActive6, setPlayerIsActive6] = useState()
	//
	const [name7, setName7] = useState()
	const [number7, setNumber7] = useState()
	const [BY7, setBY7] = useState()
	const [playerIsGoalie7, setPlayerIsGoalie7] = useState()
	const [playerIsTaxi7, setPlayerIsTaxi7] = useState()
	const [playerIsActive7, setPlayerIsActive7] = useState()
	//
	const [name8, setName8] = useState()
	const [number8, setNumber8] = useState()
	const [BY8, setBY8] = useState()
	const [playerIsGoalie8, setPlayerIsGoalie8] = useState()
	const [playerIsTaxi8, setPlayerIsTaxi8] = useState()
	const [playerIsActive8, setPlayerIsActive8] = useState()
	//
	const [name9, setName9] = useState()
	const [number9, setNumber9] = useState()
	const [BY9, setBY9] = useState()
	const [playerIsGoalie9, setPlayerIsGoalie9] = useState()
	const [playerIsTaxi9, setPlayerIsTaxi9] = useState()
	const [playerIsActive9, setPlayerIsActive9] = useState()
	//
	const [name10, setName10] = useState()
	const [number10, setNumber10] = useState()
	const [BY10, setBY10] = useState()
	const [playerIsGoalie10, setPlayerIsGoalie10] = useState()
	const [playerIsTaxi10, setPlayerIsTaxi10] = useState()
	const [playerIsActive10, setPlayerIsActive10] = useState()
	//
	const [name11, setName11] = useState()
	const [number11, setNumber11] = useState()
	const [BY11, setBY11] = useState()
	const [playerIsGoalie11, setPlayerIsGoalie11] = useState()
	const [playerIsTaxi11, setPlayerIsTaxi11] = useState()
	const [playerIsActive11, setPlayerIsActive11] = useState()
	//
	const [name12, setName12] = useState()
	const [number12, setNumber12] = useState()
	const [BY12, setBY12] = useState()
	const [playerIsGoalie12, setPlayerIsGoalie12] = useState()
	const [playerIsTaxi12, setPlayerIsTaxi12] = useState()
	const [playerIsActive12, setPlayerIsActive12] = useState()
	//
	const [name13, setName13] = useState()
	const [number13, setNumber13] = useState()
	const [BY13, setBY13] = useState()
	const [playerIsGoalie13, setPlayerIsGoalie13] = useState()
	const [playerIsTaxi13, setPlayerIsTaxi13] = useState()
	const [playerIsActive13, setPlayerIsActive13] = useState()
	//
	const [name14, setName14] = useState()
	const [number14, setNumber14] = useState()
	const [BY14, setBY14] = useState()
	const [playerIsGoalie14, setPlayerIsGoalie14] = useState()
	const [playerIsTaxi14, setPlayerIsTaxi14] = useState()
	const [playerIsActive14, setPlayerIsActive14] = useState()
	//
	const [name15, setName15] = useState()
	const [number15, setNumber15] = useState()
	const [BY15, setBY15] = useState()
	const [playerIsGoalie15, setPlayerIsGoalie15] = useState()
	const [playerIsTaxi15, setPlayerIsTaxi15] = useState()
	const [playerIsActive15, setPlayerIsActive15] = useState()
	//
	const [name16, setName16] = useState()
	const [number16, setNumber16] = useState()
	const [BY16, setBY16] = useState()
	const [playerIsGoalie16, setPlayerIsGoalie16] = useState()
	const [playerIsTaxi16, setPlayerIsTaxi16] = useState()
	const [playerIsActive16, setPlayerIsActive16] = useState()
	//
	const [name17, setName17] = useState()
	const [number17, setNumber17] = useState()
	const [BY17, setBY17] = useState()
	const [playerIsGoalie17, setPlayerIsGoalie17] = useState()
	const [playerIsTaxi17, setPlayerIsTaxi17] = useState()
	const [playerIsActive17, setPlayerIsActive17] = useState()
	//
	const [name18, setName18] = useState()
	const [number18, setNumber18] = useState()
	const [BY18, setBY18] = useState()
	const [playerIsGoalie18, setPlayerIsGoalie18] = useState()
	const [playerIsTaxi18, setPlayerIsTaxi18] = useState()
	const [playerIsActive18, setPlayerIsActive18] = useState()
	//
	const [name19, setName19] = useState()
	const [number19, setNumber19] = useState()
	const [BY19, setBY19] = useState()
	const [playerIsGoalie19, setPlayerIsGoalie19] = useState()
	const [playerIsTaxi19, setPlayerIsTaxi19] = useState()
	const [playerIsActive19, setPlayerIsActive19] = useState()
	//
	const [name20, setName20] = useState()
	const [number20, setNumber20] = useState()
	const [BY20, setBY20] = useState()
	const [playerIsGoalie20, setPlayerIsGoalie20] = useState()
	const [playerIsTaxi20, setPlayerIsTaxi20] = useState()
	const [playerIsActive20, setPlayerIsActive20] = useState()
	//
	const [name21, setName21] = useState()
	const [number21, setNumber21] = useState()
	const [BY21, setBY21] = useState()
	const [playerIsGoalie21, setPlayerIsGoalie21] = useState()
	const [playerIsTaxi21, setPlayerIsTaxi21] = useState()
	const [playerIsActive21, setPlayerIsActive21] = useState()
	//
	const [name22, setName22] = useState()
	const [number22, setNumber22] = useState()
	const [BY22, setBY22] = useState()
	const [playerIsGoalie22, setPlayerIsGoalie22] = useState()
	const [playerIsTaxi22, setPlayerIsTaxi22] = useState()
	const [playerIsActive22, setPlayerIsActive22] = useState()
	//
	const [name23, setName23] = useState()
	const [number23, setNumber23] = useState()
	const [BY23, setBY23] = useState()
	const [playerIsGoalie23, setPlayerIsGoalie23] = useState()
	const [playerIsTaxi23, setPlayerIsTaxi23] = useState()
	const [playerIsActive23, setPlayerIsActive23] = useState()
	//
	const [name24, setName24] = useState()
	const [number24, setNumber24] = useState()
	const [BY24, setBY24] = useState()
	const [playerIsGoalie24, setPlayerIsGoalie24] = useState()
	const [playerIsTaxi24, setPlayerIsTaxi24] = useState()
	const [playerIsActive24, setPlayerIsActive24] = useState()
	//
	const [name25, setName25] = useState()
	const [number25, setNumber25] = useState()
	const [BY25, setBY25] = useState()
	const [playerIsGoalie25, setPlayerIsGoalie25] = useState()
	const [playerIsTaxi25, setPlayerIsTaxi25] = useState()
	const [playerIsActive25, setPlayerIsActive25] = useState()
	//
	const [name26, setName26] = useState()
	const [number26, setNumber26] = useState()
	const [BY26, setBY26] = useState()
	const [playerIsGoalie26, setPlayerIsGoalie26] = useState()
	const [playerIsTaxi26, setPlayerIsTaxi26] = useState()
	const [playerIsActive26, setPlayerIsActive26] = useState()
	//
	const [name27, setName27] = useState()
	const [number27, setNumber27] = useState()
	const [BY27, setBY27] = useState()
	const [playerIsGoalie27, setPlayerIsGoalie27] = useState()
	const [playerIsTaxi27, setPlayerIsTaxi27] = useState()
	const [playerIsActive27, setPlayerIsActive27] = useState()
	//
	const [name28, setName28] = useState()
	const [number28, setNumber28] = useState()
	const [BY28, setBY28] = useState()
	const [playerIsGoalie28, setPlayerIsGoalie28] = useState()
	const [playerIsTaxi28, setPlayerIsTaxi28] = useState()
	const [playerIsActive28, setPlayerIsActive28] = useState()
	//
	const [name29, setName29] = useState()
	const [number29, setNumber29] = useState()
	const [BY29, setBY29] = useState()
	const [playerIsGoalie29, setPlayerIsGoalie29] = useState()
	const [playerIsTaxi29, setPlayerIsTaxi29] = useState()
	const [playerIsActive29, setPlayerIsActive29] = useState()
	//
	const [name30, setName30] = useState()
	const [number30, setNumber30] = useState()
	const [BY30, setBY30] = useState()
	const [playerIsGoalie30, setPlayerIsGoalie30] = useState()
	const [playerIsTaxi30, setPlayerIsTaxi30] = useState()
	const [playerIsActive30, setPlayerIsActive30] = useState()
	//
	//
	const coachId = useParams().coachId
	//
	//
	//First thing I want to do here, I think, is to see if this
	//coach already has some CURRENT teams
	//getAllTeamsForACoach
	//No idea why this is in admin but whatever......
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/admin/${coachId}/allTeamsForACoach`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setAllTeamsForACoach(responseData.allTeamsForACoach)

				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//
	//
	//Next, lets call the backend to get all the organizations for our dropdown
	//     allOrgs
	useEffect(() => {
		console.log('useEffect')
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/allOrgs'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				//console.log('responseData: ' + JSON.stringify(responseData))
				setLoadedOrgs(responseData.allOrgs)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	//This creates an array from the loadedOrgs.  It will be our 'Organization' dropdown:
	if (loadedOrgs && !isLoading && !orgDropdownCreated) {
		const innerOrgArray = []
		loadedOrgs.forEach((org) => {
			const orgDisplay = org.orgName
			innerOrgArray.push(orgDisplay)
		})
		//NEW  This was added for leagues that have divisions
		let uniqueArray = []
		const duplicateFilter = innerOrgArray.filter((element) => {
			if (!uniqueArray.includes(element)) {
				uniqueArray.push(element)

				return true
			}
			return false
		})
		//end NEW
		setOrgArray(duplicateFilter)
		setOrgDropdownCreated(true)
	}
	//
	//
	//
	let selectedOrg
	//
	const getHeadCoach = (event) => {
		setHeadCoach(event.target.value)
	}
	const getAssistantCoaches = (event) => {
		setAssistantCoaches(event.target.value)
	}
	const getTeam = (event) => {
		setTeam(event.target.value)
	}
	const getOrg = (event) => {
		const selectedValue = event.target.value
		selectedOrg = loadedOrgs[selectedValue].orgName
		setOrg(selectedOrg)
	}
	const getYear = (event) => {
		setYear(event.target.value)
	}
	const getDate = (event) => {
		setDate(event.target.value)
	}
	const playerIsGoalieCheckbox1 = (e) => {
		setPlayerIsGoalie1(true)
	}
	const playerIsTaxiCheckbox1 = (e) => {
		setPlayerIsTaxi1(true)
	}
	const playerIsGoalieCheckbox2 = (e) => {
		setPlayerIsGoalie2(true)
	}
	const playerIsTaxiCheckbox2 = (e) => {
		setPlayerIsTaxi2(true)
	}
	const playerIsGoalieCheckbox3 = (e) => {
		setPlayerIsGoalie3(true)
	}
	const playerIsTaxiCheckbox3 = (e) => {
		setPlayerIsTaxi3(true)
	}
	const playerIsGoalieCheckbox4 = (e) => {
		setPlayerIsGoalie4(true)
	}
	const playerIsTaxiCheckbox4 = (e) => {
		setPlayerIsTaxi4(true)
	}
	const playerIsGoalieCheckbox5 = (e) => {
		setPlayerIsGoalie5(true)
	}
	const playerIsTaxiCheckbox5 = (e) => {
		setPlayerIsTaxi5(true)
	}
	const playerIsGoalieCheckbox6 = (e) => {
		setPlayerIsGoalie6(true)
	}
	const playerIsTaxiCheckbox6 = (e) => {
		setPlayerIsTaxi6(true)
	}
	const playerIsGoalieCheckbox7 = (e) => {
		setPlayerIsGoalie7(true)
	}
	const playerIsTaxiCheckbox7 = (e) => {
		setPlayerIsTaxi7(true)
	}
	const playerIsGoalieCheckbox8 = (e) => {
		setPlayerIsGoalie8(true)
	}
	const playerIsTaxiCheckbox8 = (e) => {
		setPlayerIsTaxi8(true)
	}
	const playerIsGoalieCheckbox9 = (e) => {
		setPlayerIsGoalie9(true)
	}
	const playerIsTaxiCheckbox9 = (e) => {
		setPlayerIsTaxi9(true)
	}
	const playerIsGoalieCheckbox10 = (e) => {
		setPlayerIsGoalie10(true)
	}
	const playerIsTaxiCheckbox10 = (e) => {
		setPlayerIsTaxi10(true)
	}
	const playerIsGoalieCheckbox11 = (e) => {
		setPlayerIsGoalie11(true)
	}
	const playerIsTaxiCheckbox11 = (e) => {
		setPlayerIsTaxi11(true)
	}
	const playerIsGoalieCheckbox12 = (e) => {
		setPlayerIsGoalie12(true)
	}
	const playerIsTaxiCheckbox12 = (e) => {
		setPlayerIsTaxi12(true)
	}
	const playerIsGoalieCheckbox13 = (e) => {
		setPlayerIsGoalie13(true)
	}
	const playerIsTaxiCheckbox13 = (e) => {
		setPlayerIsTaxi13(true)
	}
	const playerIsGoalieCheckbox14 = (e) => {
		setPlayerIsGoalie14(true)
	}
	const playerIsTaxiCheckbox14 = (e) => {
		setPlayerIsTaxi14(true)
	}
	const playerIsGoalieCheckbox15 = (e) => {
		setPlayerIsGoalie15(true)
	}
	const playerIsTaxiCheckbox15 = (e) => {
		setPlayerIsTaxi15(true)
	}
	const playerIsGoalieCheckbox16 = (e) => {
		setPlayerIsGoalie16(true)
	}
	const playerIsTaxiCheckbox16 = (e) => {
		setPlayerIsTaxi16(true)
	}
	const playerIsGoalieCheckbox17 = (e) => {
		setPlayerIsGoalie17(true)
	}
	const playerIsTaxiCheckbox17 = (e) => {
		setPlayerIsTaxi17(true)
	}
	const playerIsGoalieCheckbox18 = (e) => {
		setPlayerIsGoalie18(true)
	}
	const playerIsTaxiCheckbox18 = (e) => {
		setPlayerIsTaxi18(true)
	}
	const playerIsGoalieCheckbox19 = (e) => {
		setPlayerIsGoalie19(true)
	}
	const playerIsTaxiCheckbox19 = (e) => {
		setPlayerIsTaxi19(true)
	}
	const playerIsGoalieCheckbox20 = (e) => {
		setPlayerIsGoalie20(true)
	}
	const playerIsTaxiCheckbox20 = (e) => {
		setPlayerIsTaxi20(true)
	}
	const playerIsGoalieCheckbox21 = (e) => {
		setPlayerIsGoalie21(true)
	}
	const playerIsTaxiCheckbox21 = (e) => {
		setPlayerIsTaxi21(true)
	}
	const playerIsGoalieCheckbox22 = (e) => {
		setPlayerIsGoalie22(true)
	}
	const playerIsTaxiCheckbox22 = (e) => {
		setPlayerIsTaxi22(true)
	}
	const playerIsGoalieCheckbox23 = (e) => {
		setPlayerIsGoalie23(true)
	}
	const playerIsTaxiCheckbox23 = (e) => {
		setPlayerIsTaxi23(true)
	}
	const playerIsGoalieCheckbox24 = (e) => {
		setPlayerIsGoalie24(true)
	}
	const playerIsTaxiCheckbox24 = (e) => {
		setPlayerIsTaxi24(true)
	}
	const playerIsGoalieCheckbox25 = (e) => {
		setPlayerIsGoalie25(true)
	}
	const playerIsTaxiCheckbox25 = (e) => {
		setPlayerIsTaxi25(true)
	}
	const playerIsGoalieCheckbox26 = (e) => {
		setPlayerIsGoalie26(true)
	}
	const playerIsTaxiCheckbox26 = (e) => {
		setPlayerIsTaxi26(true)
	}
	const playerIsGoalieCheckbox27 = (e) => {
		setPlayerIsGoalie27(true)
	}
	const playerIsTaxiCheckbox27 = (e) => {
		setPlayerIsTaxi27(true)
	}
	const playerIsGoalieCheckbox28 = (e) => {
		setPlayerIsGoalie28(true)
	}
	const playerIsTaxiCheckbox28 = (e) => {
		setPlayerIsTaxi28(true)
	}
	const playerIsGoalieCheckbox29 = (e) => {
		setPlayerIsGoalie29(true)
	}
	const playerIsTaxiCheckbox29 = (e) => {
		setPlayerIsTaxi29(true)
	}
	const playerIsGoalieCheckbox30 = (e) => {
		setPlayerIsGoalie30(true)
	}
	const playerIsTaxiCheckbox30 = (e) => {
		setPlayerIsTaxi30(true)
	}
	const playerIsActiveCheckbox1 = (e) => {
		setPlayerIsActive1(true)
	}
	const playerIsActiveCheckbox2 = (e) => {
		setPlayerIsActive2(true)
	}
	const playerIsActiveCheckbox3 = (e) => {
		setPlayerIsActive3(true)
	}
	const playerIsActiveCheckbox4 = (e) => {
		setPlayerIsActive4(true)
	}
	const playerIsActiveCheckbox5 = (e) => {
		setPlayerIsActive5(true)
	}
	const playerIsActiveCheckbox6 = (e) => {
		setPlayerIsActive6(true)
	}
	const playerIsActiveCheckbox7 = (e) => {
		setPlayerIsActive7(true)
	}
	const playerIsActiveCheckbox8 = (e) => {
		setPlayerIsActive8(true)
	}
	const playerIsActiveCheckbox9 = (e) => {
		setPlayerIsActive9(true)
	}
	const playerIsActiveCheckbox10 = (e) => {
		setPlayerIsActive10(true)
	}
	const playerIsActiveCheckbox11 = (e) => {
		setPlayerIsActive11(true)
	}
	const playerIsActiveCheckbox12 = (e) => {
		setPlayerIsActive12(true)
	}
	const playerIsActiveCheckbox13 = (e) => {
		setPlayerIsActive13(true)
	}
	const playerIsActiveCheckbox14 = (e) => {
		setPlayerIsActive14(true)
	}
	const playerIsActiveCheckbox15 = (e) => {
		setPlayerIsActive15(true)
	}
	const playerIsActiveCheckbox16 = (e) => {
		setPlayerIsActive16(true)
	}
	const playerIsActiveCheckbox17 = (e) => {
		setPlayerIsActive17(true)
	}
	const playerIsActiveCheckbox18 = (e) => {
		setPlayerIsActive18(true)
	}
	const playerIsActiveCheckbox19 = (e) => {
		setPlayerIsActive19(true)
	}
	const playerIsActiveCheckbox20 = (e) => {
		setPlayerIsActive20(true)
	}
	const playerIsActiveCheckbox21 = (e) => {
		setPlayerIsActive21(true)
	}
	const playerIsActiveCheckbox22 = (e) => {
		setPlayerIsActive22(true)
	}
	const playerIsActiveCheckbox23 = (e) => {
		setPlayerIsActive23(true)
	}
	const playerIsActiveCheckbox24 = (e) => {
		setPlayerIsActive24(true)
	}
	const playerIsActiveCheckbox25 = (e) => {
		setPlayerIsActive25(true)
	}
	const playerIsActiveCheckbox26 = (e) => {
		setPlayerIsActive26(true)
	}
	const playerIsActiveCheckbox27 = (e) => {
		setPlayerIsActive27(true)
	}
	const playerIsActiveCheckbox28 = (e) => {
		setPlayerIsActive28(true)
	}
	const playerIsActiveCheckbox29 = (e) => {
		setPlayerIsActive29(true)
	}
	const playerIsActiveCheckbox30 = (e) => {
		setPlayerIsActive30(true)
	}
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	//registration-controller -> createRegisteredTeam
	//
	const registerSubmitHandler = async (event) => {
		event.preventDefault()
		if (org) {
			try {
				setIsLoading(true)
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/registration/registeredTeams/${coachId}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							headCoach,
							assistantCoaches,
							org,
							team,
							year,
							date,
							name1,
							number1,
							BY1,
							playerIsGoalie1,
							playerIsTaxi1,
							name2,
							number2,
							BY2,
							playerIsGoalie2,
							playerIsTaxi2,
							name3,
							number3,
							BY3,
							playerIsGoalie3,
							playerIsTaxi3,
							name4,
							number4,
							BY4,
							playerIsGoalie4,
							playerIsTaxi4,
							name5,
							number5,
							BY5,
							playerIsGoalie5,
							playerIsTaxi5,
							name6,
							number6,
							BY6,
							playerIsGoalie6,
							playerIsTaxi6,
							name7,
							number7,
							BY7,
							playerIsGoalie7,
							playerIsTaxi7,
							name8,
							number8,
							BY8,
							playerIsGoalie8,
							playerIsTaxi8,
							name9,
							number9,
							BY9,
							playerIsGoalie9,
							playerIsTaxi9,
							name10,
							number10,
							BY10,
							playerIsGoalie10,
							playerIsTaxi10,
							name11,
							number11,
							BY11,
							playerIsGoalie11,
							playerIsTaxi11,
							name12,
							number12,
							BY12,
							playerIsGoalie12,
							playerIsTaxi12,
							name13,
							number13,
							BY13,
							playerIsGoalie13,
							playerIsTaxi13,
							name14,
							number14,
							BY14,
							playerIsGoalie14,
							playerIsTaxi14,
							name15,
							number15,
							BY15,
							playerIsGoalie15,
							playerIsTaxi15,
							name16,
							number16,
							BY16,
							playerIsGoalie16,
							playerIsTaxi16,
							name17,
							number17,
							BY17,
							playerIsGoalie17,
							playerIsTaxi17,
							name18,
							number18,
							BY18,
							playerIsGoalie18,
							playerIsTaxi18,
							name19,
							number19,
							BY19,
							playerIsGoalie19,
							playerIsTaxi19,
							name20,
							number20,
							BY20,
							playerIsGoalie20,
							playerIsTaxi20,
							name21,
							number21,
							BY21,
							playerIsGoalie21,
							playerIsTaxi21,
							name22,
							number22,
							BY22,
							playerIsGoalie22,
							playerIsTaxi22,
							name23,
							number23,
							BY23,
							playerIsGoalie23,
							playerIsTaxi23,
							name24,
							number24,
							BY24,
							playerIsGoalie24,
							playerIsTaxi24,
							name25,
							number25,
							BY25,
							playerIsGoalie25,
							playerIsTaxi25,
							name26,
							number26,
							BY26,
							playerIsGoalie26,
							playerIsTaxi26,
							name27,
							number27,
							BY27,
							playerIsGoalie27,
							playerIsTaxi27,
							name28,
							number28,
							BY28,
							playerIsGoalie28,
							playerIsTaxi28,
							name29,
							number29,
							BY29,
							playerIsGoalie29,
							playerIsTaxi29,
							name30,
							number30,
							BY30,
							playerIsGoalie30,
							playerIsTaxi30,
							playerIsActive1,
							playerIsActive2,
							playerIsActive3,
							playerIsActive4,
							playerIsActive5,
							playerIsActive6,
							playerIsActive7,
							playerIsActive8,
							playerIsActive9,
							playerIsActive10,
							playerIsActive11,
							playerIsActive12,
							playerIsActive13,
							playerIsActive14,
							playerIsActive15,
							playerIsActive16,
							playerIsActive17,
							playerIsActive18,
							playerIsActive19,
							playerIsActive20,
							playerIsActive21,
							playerIsActive22,
							playerIsActive23,
							playerIsActive24,
							playerIsActive25,
							playerIsActive26,
							playerIsActive27,
							playerIsActive28,
							playerIsActive29,
							playerIsActive30,
						}),
					}
				)
				const responseData = await response.json()
				if (!response.ok) {
					throw new Error('ERROR: ' + responseData.message)
				}

				console.log('you are here, lets show the modal')
				showSuccessfulRegistrationHandler()
				//setIsLoading(false)
			} catch (err) {
				console.log('error: ' + err)
				setIsLoading(false)
				setError(
					err.message + ': Something went wrong with registering a team.'
				)
			}
		} else {
			alert('You must select an Organization!')
		}
	}
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//When user clicks OKAY on the popup modal (popup that says their confirmation was successful)
	//all this does is A: close the popup, and B: go back to the home page
	const okHandler = () => {
		console.log('ok has been clicked')
		setShowSuccessfulRegistrationModal(null)
		// setShowEditTeamModal(null)
		navigate('/')
	}
	//
	//
	//
	const showSuccessfulRegistrationHandler = () => {
		console.log('inside showSuccessfulRegistrationHandler')
		setShowSuccessfulRegistrationModal(true)
	}
	//
	//
	//
	//
	//
	//
	return (
		<React.Fragment>
			<Modal
				show={showSuccessfulRegistrationModal}
				header='Thank You!'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Thanks for Registering your team. Let's get ready to PLAY!!
						</p>
						<br></br>
						<button
							className='okButton buttonButton'
							inverse
							onClick={okHandler}
						>
							OKAY
						</button>
					</React.Fragment>
				}
			></Modal>
			<ErrorModal error={error} onClear={errorHandler} />
			<div>
				{isLoading && <LoadingSpinner asOverlay />}
				<main>
					{/* <h2>Team Registration</h2> */}
					{allTeamsForACoach && allTeamsForACoach.length === 0 && (
						<div className='rcard'>
							<div className='rcard-body rcard-padding'>
								<form
									className='register-form'
									onSubmit={registerSubmitHandler}
								>
									<div className='row'>
										<div className='row' style={{ marginBottom: '2rem' }}>
											<select
												className='my-1 mr-sm-2 selectLeagueDropdown'
												onChange={getOrg}
												style={{
													background: 'white',
													borderWidth: '3px',
													width: '30vh',
													fontSize: 'medium',
													fontFamily: 'Montserrat, Work Sans, sans-serif',
												}}
											>
												<option defaultValue>Organization:</option>
												{orgArray.map((org, i) => (
													<option value={i} key={i}>
														{org.toUpperCase()}
													</option>
												))}
											</select>
										</div>
										<div className='row'>
											<div className='col-sm-4'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtHeadCoach'
														style={{ fontWeight: 'bold' }}
													>
														Head Coach <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtHeadCoach'
														type='text'
														id='txtHeadCoach'
														tabIndex='6'
														className='rform-control'
														placeholder='Head Coach'
														onChange={getHeadCoach}
														required
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-sm-12'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtAssistantCoaches'
														style={{ fontWeight: 'bold' }}
													>
														Assistant Coach(es):{' '}
														<span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtAssistantCoaches'
														type='text'
														id='txtAssistantCoaches'
														tabIndex='7'
														className='rform-control'
														onChange={getAssistantCoaches}
														placeholder='List any and all Assistant Coaches'
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtTeam' style={{ fontWeight: 'bold' }}>
													Team Name: <span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtTeam'
													type='text'
													id='txtTeam'
													tabIndex='8'
													className='rform-control'
													placeholder='Example: Arsenal Beaver B'
													onChange={getTeam}
													required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-12 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='year' style={{ fontWeight: 'bold' }}>
													Tournament Year: <span className='c-red'>*</span>
												</label>
												<select
													id='year'
													type='text'
													tabIndex='9'
													placeholder='Season'
													className='rform-control'
													onChange={getYear}
													required
												>
													<option value='2022'>2022</option>
													<option value='2023'>2023</option>
													<option value='2024'>2024</option>
													<option value='2025'>2025</option>
													<option value='2026'>2026</option>
													<option value='2027'>2027</option>
													<option value='2028'>2028</option>
													<option value='2029'>2029</option>
													<option value='2030'>2030</option>
													<option value='2031'>2031</option>
													<option value='2032'>2032</option>
													<option value='2033'>2033</option>
													<option value='2034'>2034</option>
													<option value='2035'>2035</option>
													<option value='2036'>2036</option>
													<option value='2037'>2037</option>
													<option value='2038'>2038</option>
												</select>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label
													htmlFor='txtPlayers'
													style={{ fontWeight: 'bold' }}
												>
													Rostered Players:
												</label>
												<table className='form-table'>
													<thead className='tableHeader2'>
														<tr>
															<th>Name</th>
															<th>#</th>
															<th>Birth Year</th>
															<th>Goalie?</th>
															<th>Taxi?</th>
															<th>Active?</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	id='playerName1'
																	placeholder='1:'
																	type='text'
																	onChange={(e) => setName1(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber1'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber1(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY1'
																	type='number'
																	onChange={(e) => setBY1(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie1'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox1}
																/>
															</td>
															<td>
																<input
																	id='taxi1'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox1}
																/>
															</td>
															<td>
																<input
																	id='active1'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox1}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName2'
																	placeholder='2:'
																	type='text'
																	onChange={(e) => setName2(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber2'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber2(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY2'
																	type='number'
																	onChange={(e) => setBY2(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie2'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox2}
																/>
															</td>
															<td>
																<input
																	id='taxi2'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox2}
																/>
															</td>
															<td>
																<input
																	id='active2'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox2}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName3'
																	placeholder='3:'
																	type='text'
																	onChange={(e) => setName3(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber3'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber3(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY3'
																	type='number'
																	onChange={(e) => setBY3(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie3'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox3}
																/>
															</td>
															<td>
																<input
																	id='taxi3'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox3}
																/>
															</td>
															<td>
																<input
																	id='active3'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox3}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName4'
																	placeholder='4:'
																	type='text'
																	onChange={(e) => setName4(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber4'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber4(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY4'
																	type='number'
																	onChange={(e) => setBY4(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie4'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox4}
																/>
															</td>
															<td>
																<input
																	id='taxi4'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox4}
																/>
															</td>
															<td>
																<input
																	id='active4'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox4}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName5'
																	placeholder='5:'
																	type='text'
																	onChange={(e) => setName5(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber5'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber5(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY5'
																	type='number'
																	onChange={(e) => setBY5(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie5'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox5}
																/>
															</td>
															<td>
																<input
																	id='taxi5'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox5}
																/>
															</td>
															<td>
																<input
																	id='active5'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox5}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName6'
																	placeholder='6:'
																	type='text'
																	onChange={(e) => setName6(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber6'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber6(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY6'
																	type='number'
																	onChange={(e) => setBY6(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie6'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox6}
																/>
															</td>
															<td>
																<input
																	id='taxi6'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox6}
																/>
															</td>
															<td>
																<input
																	id='active6'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox6}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName7'
																	placeholder='7:'
																	type='text'
																	onChange={(e) => setName7(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber7'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber7(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY7'
																	type='number'
																	onChange={(e) => setBY7(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie7'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox7}
																/>
															</td>
															<td>
																<input
																	id='taxi7'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox7}
																/>
															</td>
															<td>
																<input
																	id='active7'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox7}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName8'
																	placeholder='8:'
																	type='text'
																	onChange={(e) => setName8(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber8'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber8(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY8'
																	type='number'
																	onChange={(e) => setBY8(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie8'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox8}
																/>
															</td>
															<td>
																<input
																	id='taxi8'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox8}
																/>
															</td>
															<td>
																<input
																	id='active8'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox8}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName9'
																	placeholder='9:'
																	type='text'
																	onChange={(e) => setName9(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber9'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber9(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY9'
																	type='number'
																	onChange={(e) => setBY9(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie9'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox9}
																/>
															</td>
															<td>
																<input
																	id='taxi9'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox9}
																/>
															</td>
															<td>
																<input
																	id='active9'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox9}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName10'
																	placeholder='10:'
																	type='text'
																	onChange={(e) => setName10(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber10'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber10(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY10'
																	type='number'
																	onChange={(e) => setBY10(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie10'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox10}
																/>
															</td>
															<td>
																<input
																	id='taxi10'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox10}
																/>
															</td>
															<td>
																<input
																	id='active10'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox10}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName11'
																	placeholder='11:'
																	type='text'
																	onChange={(e) => setName11(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber11'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber11(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY11'
																	type='number'
																	onChange={(e) => setBY11(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie11'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox11}
																/>
															</td>
															<td>
																<input
																	id='taxi11'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox11}
																/>
															</td>
															<td>
																<input
																	id='active11'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox11}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName12'
																	placeholder='12:'
																	type='text'
																	onChange={(e) => setName12(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber12'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber12(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY12'
																	type='number'
																	onChange={(e) => setBY12(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie12'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox12}
																/>
															</td>
															<td>
																<input
																	id='taxi12'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox12}
																/>
															</td>
															<td>
																<input
																	id='active12'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox12}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName13'
																	placeholder='13:'
																	type='text'
																	onChange={(e) => setName13(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber13'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber13(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY13'
																	type='number'
																	onChange={(e) => setBY13(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie13'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox13}
																/>
															</td>
															<td>
																<input
																	id='taxi13'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox13}
																/>
															</td>
															<td>
																<input
																	id='active13'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox13}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName14'
																	placeholder='14:'
																	type='text'
																	onChange={(e) => setName14(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber14'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber14(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY14'
																	type='number'
																	onChange={(e) => setBY14(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie14'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox14}
																/>
															</td>
															<td>
																<input
																	id='taxi14'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox14}
																/>
															</td>
															<td>
																<input
																	id='active14'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox14}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName15'
																	placeholder='15:'
																	type='text'
																	onChange={(e) => setName15(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber15'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber15(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY15'
																	type='number'
																	onChange={(e) => setBY15(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie15'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox15}
																/>
															</td>
															<td>
																<input
																	id='taxi15'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox15}
																/>
															</td>
															<td>
																<input
																	id='active15'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox15}
																/>
															</td>
														</tr>
													</tbody>
												</table>
												<table className='form-table'>
													<thead className='tableHeader2'>
														<tr>
															<th>Name</th>
															<th>#</th>
															<th>Birth Year</th>
															<th>Goalie?</th>
															<th>Taxi?</th>
															<th>Active?</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	id='playerName16'
																	placeholder='16:'
																	type='text'
																	onChange={(e) => setName16(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber16'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber16(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY16'
																	type='number'
																	onChange={(e) => setBY16(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie16'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox16}
																/>
															</td>
															<td>
																<input
																	id='taxi16'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox16}
																/>
															</td>
															<td>
																<input
																	id='active16'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox16}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName17'
																	placeholder='17:'
																	type='text'
																	onChange={(e) => setName17(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber17'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber17(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY17'
																	type='number'
																	onChange={(e) => setBY17(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie17'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox17}
																/>
															</td>
															<td>
																<input
																	id='taxi17'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox17}
																/>
															</td>
															<td>
																<input
																	id='active17'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox17}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName18'
																	placeholder='18:'
																	type='text'
																	onChange={(e) => setName18(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber18'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber18(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY18'
																	type='number'
																	onChange={(e) => setBY18(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie18'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox18}
																/>
															</td>
															<td>
																<input
																	id='taxi18'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox18}
																/>
															</td>
															<td>
																<input
																	id='active18'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox18}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName19'
																	placeholder='19:'
																	type='text'
																	onChange={(e) => setName19(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber19'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber19(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY19'
																	type='number'
																	onChange={(e) => setBY19(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie19'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox19}
																/>
															</td>
															<td>
																<input
																	id='taxi19'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox19}
																/>
															</td>
															<td>
																<input
																	id='active19'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox19}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName20'
																	placeholder='20:'
																	type='text'
																	onChange={(e) => setName20(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber20'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber20(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY20'
																	type='number'
																	onChange={(e) => setBY20(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie20'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox20}
																/>
															</td>
															<td>
																<input
																	id='taxi20'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox20}
																/>
															</td>
															<td>
																<input
																	id='active20'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox20}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName21'
																	placeholder='21:'
																	type='text'
																	onChange={(e) => setName21(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber21'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber21(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY21'
																	type='number'
																	onChange={(e) => setBY21(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie21'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox21}
																/>
															</td>
															<td>
																<input
																	id='taxi21'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox21}
																/>
															</td>
															<td>
																<input
																	id='active21'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox21}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName22'
																	placeholder='22:'
																	type='text'
																	onChange={(e) => setName22(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber22'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber22(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY22'
																	type='number'
																	onChange={(e) => setBY22(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie22'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox22}
																/>
															</td>
															<td>
																<input
																	id='taxi22'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox22}
																/>
															</td>
															<td>
																<input
																	id='active22'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox22}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName23'
																	placeholder='23:'
																	type='text'
																	onChange={(e) => setName23(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber23'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber23(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY23'
																	type='number'
																	onChange={(e) => setBY23(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie23'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox23}
																/>
															</td>
															<td>
																<input
																	id='taxi23'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox23}
																/>
															</td>
															<td>
																<input
																	id='active23'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox23}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName24'
																	placeholder='24:'
																	type='text'
																	onChange={(e) => setName24(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber24'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber24(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY24'
																	type='number'
																	onChange={(e) => setBY24(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie24'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox24}
																/>
															</td>
															<td>
																<input
																	id='taxi24'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox24}
																/>
															</td>
															<td>
																<input
																	id='active24'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox24}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName25'
																	placeholder='25:'
																	type='text'
																	onChange={(e) => setName25(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber25'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber25(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY25'
																	type='number'
																	onChange={(e) => setBY25(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie25'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox25}
																/>
															</td>
															<td>
																<input
																	id='taxi25'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox25}
																/>
															</td>
															<td>
																<input
																	id='active25'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox25}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName26'
																	placeholder='26:'
																	type='text'
																	onChange={(e) => setName26(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber26'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber26(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY26'
																	type='number'
																	onChange={(e) => setBY26(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie26'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox26}
																/>
															</td>
															<td>
																<input
																	id='taxi26'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox26}
																/>
															</td>
															<td>
																<input
																	id='active26'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox26}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName27'
																	placeholder='27:'
																	type='text'
																	onChange={(e) => setName27(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber27'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber27(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY27'
																	type='number'
																	onChange={(e) => setBY27(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie27'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox27}
																/>
															</td>
															<td>
																<input
																	id='taxi27'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox27}
																/>
															</td>
															<td>
																<input
																	id='active27'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox27}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName28'
																	placeholder='28:'
																	type='text'
																	onChange={(e) => setName28(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber28'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber28(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY28'
																	type='number'
																	onChange={(e) => setBY28(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie28'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox28}
																/>
															</td>
															<td>
																<input
																	id='taxi28'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox28}
																/>
															</td>
															<td>
																<input
																	id='active28'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox28}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName29'
																	placeholder='29:'
																	type='text'
																	onChange={(e) => setName29(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber29'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber29(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerB2Y9'
																	type='number'
																	onChange={(e) => setBY29(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie29'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox29}
																/>
															</td>
															<td>
																<input
																	id='taxi29'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox29}
																/>
															</td>
															<td>
																<input
																	id='active29'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox29}
																/>
															</td>
														</tr>
														<tr>
															<td>
																<input
																	id='playerName30'
																	placeholder='30:'
																	type='text'
																	onChange={(e) => setName30(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerNumber30'
																	type='number'
																	min='0'
																	max='99'
																	onChange={(e) => setNumber30(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='playerBY30'
																	type='number'
																	onChange={(e) => setBY30(e.target.value)}
																/>
															</td>
															<td>
																<input
																	id='goalie30'
																	type='checkbox'
																	onChange={playerIsGoalieCheckbox30}
																/>
															</td>
															<td>
																<input
																	id='taxi30'
																	type='checkbox'
																	onChange={playerIsTaxiCheckbox30}
																/>
															</td>
															<td>
																<input
																	id='active30'
																	type='checkbox'
																	onChange={playerIsActiveCheckbox30}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									{/* <div className='row'>
										<div className='col-lg-6 col-lg-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtDate' style={{ fontWeight: 'bold' }}>
													Todays Date: <span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtCity'
													type='date'
													id='txtDate'
													tabIndex='10'
													className='rform-control'
													placeholder='Date'
													onChange={getDate}
													required
												/>
											</div>
										</div>
									</div> */}
									<Button type='submit'>SUBMIT</Button>
								</form>
							</div>
						</div>
					)}
					{allTeamsForACoach && allTeamsForACoach.length > 0 && (
						<div className='RegLeagues2' style={{ marginBottom: '17rem' }}>
							<h1>Welcome back!</h1>
							<div style={{ textAlign: 'center' }}>
								<h4>It looks like you've already registered some teams.</h4>
								<h4>
									Use this page to EDIT an existing team, or to register an
									additional team.
								</h4>
							</div>
							<table id='regLeagues' style={{ marginBottom: '1.5rem' }}>
								<thead>
									<tr>
										<th>Year</th>
										<th>Organization</th>
										<th>Team Name</th>
										<th>Head Coach</th>
										<th>Assistant Coaches</th>
										{/* <th>Players</th> */}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{allTeamsForACoach.map((team, key) => {
										return (
											<tr key={key}>
												<td>{team.year}</td>
												<td>{team.organization}</td>
												<td>{team.team}</td>
												<td>{team.headCoach}</td>
												<td>{team.assistantCoaches}</td>
												{/* <td>{team.rosteredPlayers}</td> */}
												<td>
													<NavLink
														className='navlinks'
														to={`/admin/updateRegisteredTeam/${team.coachId}/${team.team}/${team.year}`}
														exact
													>
														Edit
													</NavLink>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<Button>
								<NavLink
									// type='submit'
									to={`/admin/registerAnotherTeam/${coachId}`}
									style={{
										color: 'white',
										textDecoration: 'none',
										fontWeight: 'bold',
									}}
								>
									REGISTER ANOTHER TEAM
								</NavLink>
							</Button>
						</div>
					)}
				</main>
			</div>
			<Footer />
		</React.Fragment>
	)
}

export default TeamRegistration
