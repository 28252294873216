//Show the scoring leaders of a given League
//This here is a page that OUTPUTS that list.
import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import './Leaders.css'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import HeadsUpModal from '../../shared/components/UIElements/HeadsUpModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'

const Leaders = () => {
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [loadedLeaders, setLoadedLeaders] = useState()
	const [leagueName, setLeagueName] = useState()
	const [session, setSession] = useState()
	const [year, setYear] = useState()
	const [divisionName, setDivisionName] = useState()
	const leagueId = useParams().leagueId

	//Tap into League backend, getScoringLeadersByLeagueId
	useEffect(() => {
		const sendRequest = async () => {
			console.log('leagueId in Leaders: ' + leagueId)
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${leagueId}/scoringLeaders`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				if (responseData.scoringLeaders[0].divisionName) {
					setDivisionName(responseData.scoringLeaders[0].divisionName)
				} else {
					setDivisionName('')
				}

				setLoadedLeaders(responseData.scoringLeaders)
				setLeagueName(responseData.leagueName)
				setSession(responseData.session)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
				setLoadedLeaders('')
				setLeagueName('')
				setSession('')
				setYear('')
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [leagueId])
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}

	if (loadedLeaders) {
		loadedLeaders.forEach((player) => {
			player.points = player.goals + player.assists
		})
		//
		//
		loadedLeaders.sort((a, b) => (a.points > b.points ? 1 : -1))
		//
		//
		loadedLeaders.sort((a, b) =>
			a.points === b.points && a.goals < b.goals ? 1 : -1
		)
	}

	return (
		<React.Fragment>
			<HeadsUpModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedLeaders && (
				<div className='Leaders'>
					<h1>Scoring Leaders</h1>
					<h1>
						{leagueName} {divisionName && ' - ' + divisionName + ' Division'}
					</h1>
					<h2>
						{session} {year}
					</h2>
					<table id='roster'>
						<thead>
							<tr>
								<th>#</th>
								<th>Name</th>
								<th>Team</th>
								<th>G</th>
								<th>A</th>
								<th>Total Points</th>
							</tr>
						</thead>
						<tbody>
							{loadedLeaders.map((player, key) => {
								return (
									<tr key={key}>
										<td>{player.number}</td>
										<td className='tableDataTeamName'>
											<Link
												style={{
													textDecoration: 'none',
													color: 'black',
													fontWeight: 600,
												}}
												to={`/${player.playerId}/history`}
											>
												{player.firstName} {player.lastName}
											</Link>
										</td>
										<td>{player.teamName}</td>
										<td>{player.goals}</td>
										<td>{player.assists}</td>
										<td>{player.goals + player.assists}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
			{!isLoading && !loadedLeaders && (
				<div className='Leaders'>
					<h1>
						{leagueName} {divisionName && divisionName} - Scoring Leaders
					</h1>
					<h2>
						{session} {year}
					</h2>
					<table id='players'>
						<thead>
							<tr>
								<th>Number</th>
								<th>Name</th>
								<th>Team</th>
								<th>Goals</th>
								<th>Assists</th>
								<th>Total Points</th>
							</tr>
						</thead>
						<tbody>
							{/* {loadedLeaders.map((player, key) => {
								return (
									<tr key={key}>
										<td>{player.number}</td>
										<td className='tableDataTeamName'>
											<Link
												style={{
													textDecoration: 'none',
													color: 'black',
													fontWeight: 600,
												}}
												to={`/${player.playerId}/history`}
											>
												{player.firstName} {player.lastName}
											</Link>
										</td>
										<td>{player.teamName}</td>
										<td>{player.goals}</td>
										<td>{player.assists}</td>
										<td>{player.goals + player.assists}</td>
									</tr>
								)
							})} */}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default Leaders
