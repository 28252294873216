import React, { useState, useEffect } from 'react'
import './AdminRegistration.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate, useParams } from 'react-router-dom'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'

const AdminUpdateOrg = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [orgName, setOrgName] = useState()
	const [orgAddress, setOrgAddress] = useState()
	const [error, setError] = useState()
	const orgId = useParams().orgId

	let navigate = useNavigate()

	//This is where we call the backend to get the suspension data to
	//auto-populate the form
	useEffect(() => {
		const fetchOrgData = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/org/${orgId}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setOrgName(responseData.org.orgName)
				setOrgAddress(responseData.org.orgAddress)

				setIsLoading(false)
			} catch (err) {}
		}
		fetchOrgData()
	}, [orgId])
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	let orgname
	const handleOrgName = (e) => {
		orgname = e.target.value
		setOrgName(orgname)
	}
	let orgaddress
	const handleOrgAddress = (e) => {
		orgaddress = e.target.value
		setOrgAddress(orgaddress)
	}
	//
	//
	//
	//
	//
	//
	//this goes to admin-controller, updateOrg
	const orgSubmitHandler = async (event) => {
		event.preventDefault()
		try {
			setIsLoading(true)

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${orgId}/updateOrg`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						orgName,
						orgAddress,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			navigate('/admin/orgs')
		} catch (err) {
			setIsLoading(false)
			setError(
				err.message || 'Something went wrong with editing this organization'
			)
		}
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && <LoadingSpinner asOverlay />}
			{!isLoading && (
				<div className='AddGames' style={{ backgroundColor: '#e0e0e0' }}>
					<AdminNavigation />
					<h1>Update Organization</h1>
					<form className='suspension-form' onSubmit={orgSubmitHandler}>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Organization Name:
							</td>
							<td>
								<input
									id='orgName'
									type='text'
									label='Organization Name'
									defaultValue={orgName && orgName}
									onChange={handleOrgName}
								/>
							</td>
						</tr>
						<tr>
							<td style={{ textAlign: 'left', fontWeight: 'bolder' }}>
								Address:
							</td>
							<td>
								<input
									id='address'
									type='text'
									label='Address'
									defaultValue={orgAddress && orgAddress}
									onChange={handleOrgAddress}
								/>
							</td>
						</tr>
						<AdminButton type='button' onClick={orgSubmitHandler}>
							UPDATE
						</AdminButton>
					</form>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminUpdateOrg
