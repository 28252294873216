import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
import { NavLink } from 'react-router-dom'
import Modal from '../../shared/components/UIElements/Modal'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminNewsList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedNews, setLoadedNews] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [deletedNewsId, setDeletedNewsId] = useState('')
	const [newsDeleted, setNewsDeleted] = useState()

	//First, lets tap into the following route on the backend to get all news:
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setNewsDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/news`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedNews(responseData.allNews)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [newsDeleted])

	const errorHandler = () => {
		setError(null)
	}

	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e) => {
		const value = e.target.id
		setDeletedNewsId(loadedNews[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('league right here: ' + leagueId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${deletedNewsId}/removeNews`,
				'DELETE'
			)
		} catch (err) {}
		setNewsDeleted(true)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this news article?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE NEWS
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedNews && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>News</h1>
					<table id='teams'>
						<thead>
							<tr>
								<th>Heading</th>
								<th>Subheading</th>
								<th>Date</th>
								<th>Content</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedNews.map((news, key) => {
								return (
									<tr key={key}>
										<td>{news.newsHeading}</td>
										<td>{news.newsSubheading}</td>
										<td>{news.newsDate}</td>
										<td>{news.newsContent}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updateNews/${news._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) => showDeleteWarningHandler(e)}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
						<div style={{ width: '150%', marginTop: '1rem' }}>
							<AdminButton
								to={`/admin/news/new`}
								exact
								style={{
									color: 'white',
									textDecoration: 'none',
								}}
							>
								Add new News
							</AdminButton>
						</div>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminNewsList
