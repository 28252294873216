//This is the white bar that goes the width of the screen and let's you select a current league
import React, { useState, useEffect } from 'react'
import Card from './Card'
import './SelectLeague.css'
import { useNavigate } from 'react-router-dom'
//import LeagueDatabaseTable from '../LeagueDatabaseTable'

const SelectPreviousTeam = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [loadedTeams, setLoadedTeams] = useState()
	const [error, setError] = useState()
	let selectedTeam

	//Call backend to get all the previous teams for our dropdown
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/archive'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTeams(responseData.archivedTeams)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedTeams])

	let teamArray

	if (loadedTeams && !isLoading) {
		teamArray = []
		loadedTeams.forEach((team) => {
			const teamDisplay = team.teamName + ' ' + team.year
			teamArray.push(teamDisplay)
		})
	}

	loadedTeams && console.log('loadedTeams: ' + loadedTeams)

	let navigate = useNavigate()

	const handleChange = (e) => {
		if (e.target.value !== 'View Previous Teams') {
			e.preventDefault()
			const selectedValue = e.target.value
			console.log(selectedValue)
			selectedTeam = loadedTeams[selectedValue]
			const teamId = selectedTeam._id
			navigate(`/history/${teamId}`) //< - - This changes the url
		}
	}

	return (
		<React.Fragment>
			{/* <Card style={{ backgroundColor: 'red' }}> */}
			<div style={{ textAlign: 'center' }}>
				{!isLoading && loadedTeams && (
					<form className='form-control-lg'>
						<select
							className='my-1 mr-sm-2 selectLeagueDropdown'
							onChange={handleChange}
							style={{
								background: 'white',
								borderWidth: '3px',
								width: '30vh',
								fontSize: 'medium',
								fontFamily: 'Montserrat, Work Sans, sans-serif',
							}}
						>
							<option defaultValue>View Previous Teams</option>
							{teamArray.map((team, i) => (
								<option value={i} key={i}>
									{team.toUpperCase()}
								</option>
							))}
							{/* <option style={{ background: '#feaa49' }}>
								VIEW PREVIOUS LEAGUES
							</option> */}
						</select>
					</form>
				)}
			</div>
			{/* </Card> */}
		</React.Fragment>
	)
}

export default SelectPreviousTeam
