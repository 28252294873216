import React, { useEffect, useState } from 'react'
import './AdminLeagueList.css'
import { NavLink } from 'react-router-dom'
import AdminNavigation from './AdminNavigation'
//import RostersDatabaseTable from '../../shared/components/RostersDatabaseTable'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminArchivedLeagueList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [teamId, setTeamId] = useState('')
	//const [numberOfTeams, setNumberOfTeams] = useState()
	const [showConfirmMoveToCurrentModal, setShowConfirmMoveToCurrentModal] =
		useState(false)
	const [teamToCurrent, setTeamToCurrent] = useState()
	const [loadedArchivedTeams, setLoadedArchivedTeams] = useState()

	//No dependencies means this will only render once, which is what we want
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/teams/archive'
				)
				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedArchivedTeams(responseData.archivedTeams)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setTeamToCurrent(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [teamToCurrent])

	const errorHandler = () => {
		setError(null)
	}

	//******************************************************** */
	//
	//     Archive warning
	//
	//******************************************************** */
	const showMoveToCurrentHandler = (e) => {
		const value = e.target.id
		console.log(loadedArchivedTeams[value]._id)
		setTeamId(loadedArchivedTeams[value]._id)
		setShowConfirmMoveToCurrentModal(true)
	}

	const cancelMoveToCurrentHandler = () => {
		setShowConfirmMoveToCurrentModal(false)
	}

	const confirmMoveToCurrentHandler = async () => {
		setShowConfirmMoveToCurrentModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${teamId}/archiveCurrentToggleTeam`,
				'PATCH'
			)
		} catch (err) {}
		setTeamToCurrent(true)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmMoveToCurrentModal}
				onCancel={cancelMoveToCurrentHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to move this team to Current status?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelMoveToCurrentHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmMoveToCurrentHandler}
						>
							MOVE TO CURRENT
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && !loadedArchivedTeams && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>No Archived Teams!</h1>
				</div>
			)}
			{!isLoading && loadedArchivedTeams && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>Archived Teams</h1>
					<table id='leagues'>
						<thead>
							<tr>
								<th>Team Name</th>
								{/* <th>Session</th> */}
								<th>Year</th>
								<th></th>
								{/* <th>Teams</th>
								<th></th>
								<th></th> */}
							</tr>
						</thead>
						<tbody>
							{loadedArchivedTeams.map((team, key) => {
								return (
									<tr key={key}>
										<td>
											{team.teamName}
											{/* {' '}
											{league.divisionName &&
												' - ' + league.divisionName + ' Division'} */}
										</td>

										{/* <td>{league.session}</td> */}
										<td>{team.year}</td>
										{/* <td>{league.numberOfTeams}</td> */}
										{/* <td>
											<NavLink
												className='navlinks'
												to={`/admin/${league.leagueName}/${league.session}/${league.year}/teams`}
												exact
											>
												Teams
											</NavLink>
										</td> */}
										<td>
											<button
												className='buttonButton'
												id={key}
												onClick={(e) => showMoveToCurrentHandler(e)}
											>
												Move To Current
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminArchivedLeagueList
