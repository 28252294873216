import React from 'react'
import COCHomeNavigation from './COCHomeNavigation'
import CodeOfConduct from '../../schedule/CodeOfConduct'

const SchedulesHome = () => {
	return (
		<div>
			<COCHomeNavigation />
			<CodeOfConduct />
		</div>
	)
}

export default SchedulesHome
