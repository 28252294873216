import React from 'react'
import { Link } from 'react-router-dom'

import './AdminButton.css'

const AdminButton = (props) => {
	if (props.href) {
		return (
			<a
				className={`abutton abutton--${props.size || 'default'} ${
					props.inverse && 'abutton--inverse'
				} ${props.danger && 'abutton--danger'}`}
				href={props.href}
			>
				{props.children}
			</a>
		)
	}
	if (props.to) {
		return (
			<Link
				to={props.to}
				exact={props.exact}
				className={`abutton abutton--${props.size || 'default'} ${
					props.inverse && 'abutton--inverse'
				} ${props.danger && 'abutton--danger'}`}
			>
				{props.children}
			</Link>
		)
	}
	//If not an href button or a 'to' button, we just render a normal button
	return (
		<button
			className={`abutton abutton--${props.size || 'default'} ${
				props.inverse && 'abutton--inverse'
			} ${props.danger && 'abutton--danger'}`}
			type={props.type}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</button>
	)
}

export default AdminButton
