import React, { useReducer, useEffect } from 'react'
import './Input.css'
import { validate } from '../../util/validators'

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators),
			}
		case 'TOUCH': {
			return {
				...state,
				isTouched: true,
			}
		}
		default:
			return state
	}
}
const Input = (props) => {
	//console.log('inside Input')

	//Watch video 59 'Managing State in the Input Component' to see how this is done
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isTouched: false,
		isValid: props.initialValid || false,
	})

	const { id, onInput } = props
	const { value, isValid } = inputState

	useEffect(() => {
		onInput(id, value, isValid)
	}, [id, value, isValid, onInput])

	//changeHandler will be called with every keystroke as this is what onChange does.
	//This function will do two things: store a value, and validate that value
	const changeHandler = (event) => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
			validators: props.validators,
		})
	}

	const touchHandler = () => {
		dispatch({
			type: 'TOUCH',
		})
	}

	//The input element is either going to be an input or a textarea (select, not textarea),
	//depending on the props we get in
	/* const element = props.element === 'textarea' && (
		<textarea
			id={props.id}
			rows={props.rows || 3}
			onChange={changeHandler}
			onBlur={touchHandler}
			value={inputState.value}
		/>
	) */

	//ORIGINAL CODING:
	/* const element =
		props.element === 'input' ? (
			<input
				id={props.id}
				type={props.type}
				placeholder={props.placeholder}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value || inputState.value}
			/>
		) : (
			<select
				id={props.id}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={inputState.option}
			>
				<option defaultValue></option>
			</select>
		) */
	let element
	if (props.element === 'input') {
		element = (
			<input
				id={props.id}
				type={props.type}
				placeholder={props.placeholder}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={value || inputState.value}
			/>
		)
	} else if (props.element === 'select') {
		element = (
			<select
				id={props.id}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={inputState.option}
			>
				<option defaultValue></option>
			</select>
		)
	} else if (props.element === 'textarea') {
		element = (
			<textarea
				id={props.id}
				rows={props.rows || 3}
				onChange={changeHandler}
				onBlur={touchHandler}
				value={inputState.value}
			/>
		)
	}

	return (
		<div
			className={`form-control ${
				!inputState.isValid && inputState.isTouched && 'form-control--invalid'
			}`}
		>
			<label htmlFor={props.id}>{props.label}</label>
			{element}
			{!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
		</div>
	)
}

export default Input
