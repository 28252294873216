import React, { useState } from 'react'
import './GameSummary.css'
import HomeHeader from './HomeHeader'
import HomeNavLinks from './HomeNavLinks'
import SideDrawer from '../../../admin/components/SideDrawer'
import Backdrop from '../UIElements/Backdrop'

const GameSummaryNavigation = (props) => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)

	const openDrawerHandler = () => {
		setDrawerIsOpen(true)
	}

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false)
	}

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='gameSummary-navigation__drawer-nav'>
					<HomeNavLinks />
				</nav>
			</SideDrawer>
			<HomeHeader>
				<button
					className='gameSummary-navigation__menu-btn'
					onClick={openDrawerHandler}
				>
					<span />
					<span />
					<span />
				</button>
				<h1 className='gameSummary-navigation__title'>Game Summary</h1>
				<nav className='gameSummary-navigation__header-nav'>
					<HomeNavLinks />
				</nav>
			</HomeHeader>
		</React.Fragment>
	)
}

export default GameSummaryNavigation
